import React from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

const Histogram = ({ options }) => {
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};
Histogram.propTypes = {
  options: PropTypes.object
};
export default Histogram;
