import axios from "axios";
import { getAuthenticatedToken } from "./token-helper";
import * as Sentry from "@sentry/react";
import sentryEnabled from "../index";
import { accessControlVar } from "../aws-exports";

//import { getToken } from "./token-helper";

export const sendRequest = async (args) => {
  try {
    const { url, headers } = args;
    let defaultParams = {
      Accept: "application/json",
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": accessControlVar,
      Authorization: `Bearer ${await getAuthenticatedToken()}`
    };

    const headerParams = {
      ...defaultParams,
      ...headers
    };

    const response = await axios({
      ...args,
      headers: headerParams,
      url: url
    });
    return response;
  } catch (error) {
    sentryEnabled && Sentry.captureException(error);
    let status = error?.response?.status;
    if (!status) {
      status = 502;
    }
    return { error, status };
  }
};

export const getRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: "get"
  });
  if (status === 200) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data,
    error: error || data,
    status
  };
};

export const postRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: "post"
  });

  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};

export const patchRequest = async (args) => {
  const { data, headers, error, status } = await sendRequest({
    ...args,
    method: "patch"
  });
  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};

export const deleteRequest = async (args) => {
  const { data, error, status, headers } = await sendRequest({
    ...args,
    method: "delete"
  });
  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};

export const putRequest = async (args) => {
  const { data, error, status, headers } = await sendRequest({
    ...args,
    method: "put"
  });
  if ([200, 201, 204].indexOf(status) > -1) {
    return {
      data,
      error: null,
      headers,
      status
    };
  }
  return {
    data: null,
    error: error || data,
    status
  };
};
