import { roundToSigDigits } from "./common-helper";
import { getRequest } from "./http-helper";
import { CustomTooltip } from "./tooltip-helper";
import { attachParams } from "./url-helper";
import _ from "lodash";

export const getCRdateRangeDropdowns = async (id) => {
  const params = { data_wh_id: id };
  const URL = attachParams(`/v2/cost_center/reporting/date_ranges`, params);
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getCRGraphData = async (id, dateRange) => {
  const params = { data_wh_id: id, date_range: dateRange };
  const URL = attachParams(
    `/v2/cost_center/reporting/cost_center_total_cost`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const formatPageData = (totalCostCenter, pageResponse) => {
  const newData = pageResponse?.data?.map((item, index) => {
    const getName = totalCostCenter.map((val) => {
      if (item?.cost_center_id === val?.id) {
        return { ...item, "S No": index + 1, name: val?.["Cost Center Name"] };
      }
    });
    const finalData = getName.filter((item) => item !== undefined);
    return finalData;
  });
  const formattedData = _.concat(...newData);
  return formattedData;
};

const addDollerToValue = (params) => {
  const currency = `$${roundToSigDigits(params?.value, 2, false)}`;
  return currency;
};
export const createTableColumn = (tableColumn) => {
  let column = tableColumn?.columns?.map((item) => {
    return item !== "cost_center_id"
      ? {
          width: 180,
          cellClass: "grid-cell-middle-aligned cell-border-right",
          field: item,
          filter: false,
          headerName: item,
          sortable: true,
          unSortIcon: true,
          cellRenderer: (params) => {
            if (item !== "cost_center_id") {
              return addDollerToValue(params);
            } else {
              return roundToSigDigits(params?.value, 2, false);
            }
          }
        }
      : {
          width: 180,
          cellClass: "grid-cell-left-aligned cell-border-right",
          field: "name",
          filter: false,
          headerName: "Cost Center Name",
          sortable: true,
          unSortIcon: true,
          cellRenderer: (params) => {
            return CustomTooltip(params.value, params.value, 10);
          }
        };
  });
  column.unshift({
    width: 80,
    cellClass: "grid-cell-middle-aligned cell-border-right",
    field: "S No",
    filter: false,
    headerName: "S No",
    sortable: true,
    unSortIcon: true,
    cellRenderer: (params) => {
      return params.value;
    }
  });
  return column;
};

export const stackGrahpcolors = [
  "#3182BD",
  "#6BAED6",
  "#9ECAE1",
  "#C6DBEF",
  "#E6550D",
  "#FD8D3C",
  "#FDAE6B",
  "#FDD0A2",
  "#31A354",
  "#74C476",
  "#A2DA9C",
  "#C7E9C0",
  "#756BB1",
  "#9E9AC8",
  "#BCBDDC",
  "#DADAEB",
  "#636363",
  "#969696",
  "#BDBDBD",
  "#D9D9D9"
];

export const createGraphData = (data) => {
  const graphData = data?.map((item, index) => {
    const values = [];
    Object.keys(item).map((key) => {
      if (
        key !== "cost_center_id" &&
        key !== "name" &&
        key !== "Total" &&
        key !== "S No"
      ) {
        values.push(item[key]);
      }
    });
    return {
      name: item.name,
      data: values,
      color: stackGrahpcolors[index]
    };
  });
  return graphData;
};
