import { createTheme } from "@mui/material";
const partialTheme = createTheme();

export const theme = createTheme({
  palette: {
    primary: {
      main: "#60CA9A"
    },
    error: {
      main: "#E96560"
    }
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        root: {
          // paddingRight: 16,
          fontFamily: "Inter",
          borderRadius: 6,
          height: "52px",
          [partialTheme.breakpoints.down("sm")]: {
            height: "48px"
          }
        },
        input: {
          "&:-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset"
          },
          fontSize: "14px",
          lineHeight: "21px",
          [partialTheme.breakpoints.down("sm")]: {
            fontSize: "14px"
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          // paddingRight: 16,
          color: "#222222",
          fontFamily: "Inter",
          borderRadius: 6,
          height: "52px",
          [partialTheme.breakpoints.down("sm")]: {
            height: "48px"
          },
          "&.Mui-focused": {
            "& .MuiOutlinedInput-notchedOutline": {
              borderWidth: "1px"
            }
          }
        },
        input: {
          padding: 16,
          [partialTheme.breakpoints.down("sm")]: {
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16px"
          },
          "&::placeholder": {
            color: "#BDBDBD",
            opacity: 1,
          }
        },
        notchedOutline: {
          borderColor: "#BDBDBD",
          // Change the MuiFormLabel > &.MuiInputLabel-shrink fontSize
          // when you change this.
          fontSize: 12 / 0.75
        }
      }
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          width: 24,
          height: 24,
          padding: 4,
          marginRight: 0
        }
      }
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px",
          lineHeight: "21px",
          fontFamily: "Inter",
          color: "#BDBDBD",
          translate: "0px -1px",
          "&.MuiInputLabel-shrink": {
            fontSize: 12 / 0.75, // scale(0.75) is applied when shrink
            // Change MuiOutlineInput > notchedOutline size above also.
            translate: "0px 1px"
          },
          [partialTheme.breakpoints.down("sm")]: {
            translate: "0px -3px",
            "&.MuiInputLabel-shrink": {
              translate: "0px 0px"
            }
          }
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: -9,
          marginRight: 0
        },
        label: {
          fontFamily: "Inter",
          color: "inherit",
          fontSize: "16px",
          lineHeight: "normal"
        }
      }
    }
  }
});
