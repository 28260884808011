import { Auth } from "aws-amplify";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "react-toast-wnm";
// import ConfirmMail from "../../components/ConfirmMail";
import SignUpSignInFlow from "../../components/SignUpSignInFlow";
import { getException } from "../../utils/exceptiondictionary";
import { getBasicToastSettings } from "../../utils/toast-helper";
import eyeCon from "../../assets/images/common/eye.svg";
import eyeConSlash from "../../assets/images/common/eye-slash.svg";
import "./resetpwd.scss";
import { validatePassword } from "../../utils/common-helper";
// import back_arrow from "../../assets/images/common/back_arrow2.svg";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SignInHeader from "../../components/SignInHeader";
import warning from "../../assets/images/common/warning.svg";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { theme } from "../../utils/sign-helper";
import OtpInput from "react-otp-input";
import back_arrow from "../../assets/images/common/back_arrow2.svg";

const ResetPassword = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const location = useLocation();
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const navigateTo = (path, attrs = {}) => {
    navigate(path, attrs);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [email] = useState(
    location && location.state && location.state.email !== ""
      ? location.state.email
      : ""
  );
  const [pword, setPword] = useState("");
  const [cpword, setCpword] = useState("");
  const [otp, setOtp] = useState("");
  const [eyeSlash, setEyeSlash] = useState(false);
  const [pwdType, setPwdType] = useState("password");
  const [ceyeSlash, setCEyeSlash] = useState(false);
  const [cpwdType, setCPwdType] = useState("password");
  const [infoError, setInfoError] = useState({
    pword: "",
    cpword: "",
    otp: ""
  });
  const [timezero, setTimezero] = useState(false);

  const changeTimezero = (b) => {
    setTimezero(b);
  };

  const changeIsLoading = (b) => {
    setIsLoading(b);
  };

  const handleReset = async () => {
    try {
      await Auth.forgotPasswordSubmit(
        email?.trim()?.toLowerCase(),
        otp.trim(),
        pword.trim()
      );
      customToast({ type: "success", ...getException("PassworResetSuccess") });
      changeIsLoading(false);
      navigateTo("/signin");
    } catch (error) {
      let errObj = { ...infoError };
      if (error.name === "CodeMismatchException") {
        errObj["otp"] = "This code is invalid";
        setOtp("");
      } else if (error.name == "LimitExceededException") {
        customToast({
          type: "error",
          ...getException("PasswordLimitExceededException")
        });
      } else {
        customToast({ type: "error", ...getException(error.name) });
      }
      setInfoError(errObj);
      changeIsLoading(false);
      // changeIsReset(false);
    }
  };

  const handleClick = (e) => {
    e.preventDefault();
    let errObj = { ...infoError };
    if (pword.trim() === "") {
      errObj["pword"] = "Please enter password";
    }
    if (!validatePassword(pword.trim())) {
      errObj["pword"] =
        "Passoword should be 6 - 15 characters with atleast one digit,one uppercase and one special character";
    }
    if (cpword.trim() === "") {
      errObj["cpword"] = "Please confirm password";
    }
    if (cpword.trim() !== "" && cpword.trim() !== pword.trim()) {
      errObj["cpword"] = "Passwords don't match";
    }
    const length = otp.toString().length;
    if (!otp || length < 6) {
      errObj["otp"] = "Please enter verification code";
    }
    setInfoError(errObj);
    if (
      errObj["pword"] === "" &&
      errObj["cpword"] === "" &&
      errObj["otp"] === ""
    ) {
      setIsLoading(true);
      handleReset();
    }
  };

  const handleChange = (otp) => {
    if (infoError["otp"] !== "") {
      setInfoError({ ...infoError, otp: "" });
    }
    setOtp(otp);
  };

  const resendConfirmationMail = async () => {
    changeIsLoading(true);
    try {
      Auth.forgotPassword(email?.trim()?.toLowerCase()).then(() => {
        changeIsLoading(false);
        toast({
          ...getBasicToastSettings(
            "success",
            "Success",
            "Verification code sent"
          )
        });
        setOtp("");
      });
    } catch (err) {
      customToast({ type: "error", ...getException("FailedToSendCode") });
      changeIsLoading(false);
    }
  };

  const toggleSlash = () => {
    setEyeSlash(!eyeSlash);
    if (pwdType && pwdType === "password") {
      setPwdType("text");
    } else if (pwdType && pwdType === "text") {
      setPwdType("password");
    }
  };
  const toggleCSlash = () => {
    setCEyeSlash(!ceyeSlash);
    if (cpwdType && cpwdType === "password") {
      setCPwdType("text");
    } else if (cpwdType && cpwdType === "text") {
      setCPwdType("password");
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      changeTimezero(true);
    }, 60000);
    return () => clearTimeout(timer);
  }, []);

  // console.log("location", location);

  return (
    <ThemeProvider theme={theme}>
      <div className="parent-container">
        <SignUpSignInFlow welcome />

        <div className="reset-container">
          <SignInHeader />
          <div className="block">
            <div className="reset-parent-block">
              <div className="reset-parent">
                <div className="heading-back">
                  <div
                    className="back-btn"
                    onClick={() => navigateTo("/forgotpassword")}>
                    <img src={back_arrow} />
                    <span>Back</span>
                  </div>
                </div>
                <div className="heading">
                  <span>Verify Email & Reset Password</span>
                </div>
                <div className="subheading">
                  <span>
                    We have sent a 6-digit verification code to{" "}
                    <span className="email-span">{email.trim()}</span>{" "}
                  </span>
                </div>
                <form className="reset-list" onSubmit={handleClick}>
                  {/* To help with autofill */}
                  <div className="otp-list">
                    <div className="ver-code">
                      <span>Verification code</span>
                    </div>
                    <OtpInput
                      value={otp}
                      onChange={handleChange}
                      numInputs={6}
                      isInputNum={true}
                      containerStyle={
                        "name-container " +
                        (infoError.otp !== "" ? "error__otp" : "")
                      }
                      inputStyle={{
                        width: "junk" // Junk to ignore forced inline style
                        // react-otp-input forces 1em width.
                      }}
                    />
                    {infoError.otp !== "" ? (
                      <div className="connection__fail">
                        <p>{infoError.otp}</p>
                      </div>
                    ) : null}
                    {timezero ? (
                      <div className="subheading">
                        <span>
                          Didn’t receive verification code?{" "}
                          <span
                            className="resend_mail"
                            onClick={resendConfirmationMail}>
                            Resend
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </div>
                  <div className="reset-pass-heading">
                    <span>Reset Password</span>
                  </div>
                  <input type="hidden" autoComplete="username" value={email} />
                  <TextField
                    label="New password"
                    fullWidth
                    FormHelperTextProps={{
                      className: "email-error",
                      sx: { mt: "6px", ml: 0 }
                    }}
                    type={pwdType}
                    value={pword}
                    autoComplete="section-pwd-reset new-password"
                    onChange={(e) => {
                      if (infoError["pword"] !== "") {
                        setInfoError({ ...infoError, pword: "" });
                      }
                      setPword(e.target.value);
                    }}
                    error={infoError.pword !== ""}
                    helperText={
                      <>
                        {infoError.pword !== "" && (
                          <img src={warning} alt="warning" />
                        )}
                        <span>{infoError.pword}</span>
                      </>
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              toggleSlash();
                            }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                            }}>
                            <img
                              className="cg-eye cg-absolute-position"
                              src={eyeSlash ? eyeCon : eyeConSlash}
                              aria-hidden="true"
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <TextField
                    label="Confirm password"
                    fullWidth
                    FormHelperTextProps={{
                      className: "email-error",
                      sx: { mt: "6px", ml: 0 }
                    }}
                    type={cpwdType}
                    value={cpword}
                    autoComplete="section-pwd-reset new-password"
                    onChange={(e) => {
                      if (infoError["cpword"] !== "") {
                        setInfoError({ ...infoError, cpword: "" });
                      }
                      setCpword(e.target.value);
                    }}
                    error={infoError.cpword !== ""}
                    helperText={
                      <>
                        {infoError.cpword !== "" && (
                          <img src={warning} alt="warning" />
                        )}
                        <span>{infoError.cpword}</span>
                      </>
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                              toggleCSlash();
                            }}
                            onMouseDown={(e) => {
                              e.preventDefault();
                            }}>
                            <img
                              className="cg-eye cg-absolute-position"
                              src={ceyeSlash ? eyeCon : eyeConSlash}
                              aria-hidden="true"
                            />
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                  <input
                    type="submit"
                    className="btn btn-green-variant__login w-100 login-btn"
                    value="Submit"
                  />
                </form>
              </div>
            </div>
          </div>
          {/* {!isResetPass && (
            <div className="block">
              <div className="reset-parent-block">
                <ConfirmMail
                  username={email}
                  pword={pword}
                  changeHidden={changeIsReset}
                  changeIsLoading={changeIsLoading}
                  changeOtp={changeOtp}
                  errorObj={infoError}
                />
              </div>
            </div>
          )} */}
          {isLoading && (
            <div className="load">
              <div className="preload"></div>
            </div>
          )}
        </div>
      </div>
    </ThemeProvider>
  );
};
export default ResetPassword;
