import React from "react";
import PropTypes from "prop-types";
import "./chartcomponent.scss";
import { roundToSigDigits } from "../../utils/common-helper";

function ProgressBar({ value=0 }) {
  return (
    <div className="progressbar-container">
      <div className="progress-bar">
        <div className="progress-done" style={{ width: `${value}%` }}></div>
      </div>
      <p>{`${roundToSigDigits(value, 1 ,false)}%`}</p>
    </div>
  );
}
ProgressBar.propTypes = {
  value: PropTypes.number
};
export default ProgressBar;
