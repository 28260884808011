const customProps = {
  index: {
    type: "rightAligned",
    width: 70
  },
  first_name: {
    width: 255,
    cellClass: "grid-cell-left-aligned"
  },
  last_name: {
    width: 272,
    cellClass: "grid-cell-left-aligned"
  },
  email: {
    width: 246,
    cellClass: "grid-cell-left-aligned"
  },
  login_type: {
    width: 250,
    cellClass:
      "grid-cell-left-aligned flex-justify-space-between cell-border-right"
  },
  role: {
    width: 100,
    cellClass: "grid-cell-left-aligned"
  },
  action: {
    width: 100,
    cellClass: "grid-cell-left-aligned "
  }
};

export const getOrgProps = () => {
  return customProps;
};
