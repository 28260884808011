import React from "react";
import PropTypes from "prop-types";

const HTMLRenderrer = ({ value }) => {
  return <div dangerouslySetInnerHTML={{ __html: value }} />;
};
HTMLRenderrer.propTypes = {
  value: PropTypes.any
};

export default HTMLRenderrer;
