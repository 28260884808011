import React from "react";
import IframeHandler from "../../components/IframeHandler";
import "./pipe.scss";
import PropTypes from "prop-types";
const Pipe = ({ url, token, acc }) => {
  return (
    <>
      {token && url && token !== "" && url !== "" && acc ? (
        <IframeHandler token={token} url={url} acc={acc} />
      ) : (
        <div>
          <span>Pipe</span>
        </div>
      )}
    </>
  );
};
Pipe.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object
};
export default Pipe;
