import React from "react";
import { Routes, Route } from "react-router-dom";
import PropTypes from "prop-types";
import DataSource from "../pages/DataSource";
import DataSourceSelectConn from "../pages/DataSourceSelectConn";
import HealthDashboard from "../pages/HealthDashboard";
import OrganizationSummary from "../pages/OrganizationSummary";
const ParentRoutes = ({
  url,
  token,
  acc,
  dwAccOptions,
  activeModule,
  changeActiveModule
}) => {
  return (
    <Routes>
      <Route
        path="/selectconnection"
        element={
          <DataSourceSelectConn
            url={url}
            token={token}
            acc={acc}
            dwAccOptions={dwAccOptions}
            activeModule={activeModule}
          />
        }
      />
      <Route
        path="/home"
        exact
        element={
          <HealthDashboard
            url={url}
            token={token}
            acc={acc}
            changeActiveModule={changeActiveModule}
          />
        }
      />
      <Route
        path="/organization_summary"
        exact
        element={
          <OrganizationSummary
            url={url}
            token={token}
            acc={acc}
            changeActiveModule={changeActiveModule}
          />
        }
      />
      <Route
        path="/datasource"
        exact
        element={
          <DataSource
            url={url}
            token={token}
            acc={acc}
            dwAccOptions={dwAccOptions}
            activeModule={activeModule}
          />
        }
      />
    </Routes>
  );
};
ParentRoutes.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  dwAccOptions: PropTypes.array,
  activeModule: PropTypes.string,
  changeActiveModule: PropTypes?.func,
  changeActivePage: PropTypes?.func
};
export default ParentRoutes;
