import React from 'react';
import PropTypes from "prop-types";
import dataNotAvailabble from "../../assets/images/grid/dataNotFound.png";
import "./notfound.scss";

function DataNotFound({customText}) {
  return (
    <div className="not-available-container">
      <img src={dataNotAvailabble} alt="" />
      <p>{customText ?? "Contract details not available for the account"}</p>
    </div>
  );
}
DataNotFound.propTypes = {
  customText: PropTypes.string
};

export default DataNotFound