import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./whinventory.scss";
import chev_down from "../../assets/images/common/chev_down.svg";
import {
  createColDefs,
  getValueObj,
  roundToSigDigits
} from "../../utils/common-helper";
import GridComponent from "../../components/GridComponent";
import Select, { components } from "react-select";
import { getDateRange } from "../../utils/user-helper";
import {
  getWHOverview,
  getWHOverviewTotals
} from "../../utils/warehouse-helper";
import { getWHOverviewProps } from "./whinventorycoldefs";
import { getException } from "../../utils/exceptiondictionary";

const WarehouseInventory = ({ acc }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const univerSalStyle = {
    cellStyle: { borderRight: "1px solid #eaeaeb" }
  };
  const customProps = getWHOverviewProps();

  const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [gridFallback, setGridFallback] = useState(false);
  const [total, setTotal] = useState(null);
  const [noRowsText, setNoRowsText] = useState(
    "Warehouse overview for the selected period or account are currently unavailable. Coming soon..."
  );

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([]);

  const getTotals = async (dateRange) => {
    try {
      if (
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        Object.keys(acc).length > 0
      ) {
        const totalObj = await getWHOverviewTotals({
          data_wh_id: acc?.id,
          date_range: dateRange?.label
        });
        if (totalObj?.values) {
          setTotal(totalObj?.values);
        }
      }
    } catch (err) {
      setTotal(null);
      console.log(err);
    }
  };

  const getTableData = async (dateRange) => {
    try {
      if (
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        acc &&
        Object.keys(acc).length > 0
      ) {
        const data = await getWHOverview({
          data_wh_id: acc?.id,
          date_range: dateRange?.label
        });
        if (data) {
          const colDefsArr = createColDefs(
            data?.columns,
            customProps,
            univerSalStyle,
            data?.column_display_name_mappping
          );
          if (colDefsArr && data?.data) {
            setColumnDefs(colDefsArr);
            setRowData(data?.data);
          }
        } else {
          clearData();
        }
        setGridLoading(false);
        if (gridFallback) {
          setGridFallback(false);
        }
      }
    } catch (err) {
      console.log(err);
      setRowData([]);
      setGridLoading(false);
      setNoRowsText("No data available for the selected date range.");
      if (!gridFallback) {
        setGridFallback(true);
      }
    }
  };

  const clearData = () => {
    setRowData([]);
    setTotal(null);
  };

  const getData = async () => {
    let dateRangeArr = [];
    try {
      const dateRangeObj = await getDateRange(acc?.id);
      if (dateRangeObj?.values) {
        dateRangeArr = getValueObj(dateRangeObj?.values);
        setDateRangeOptions(dateRangeArr);
        setDateRange(dateRangeArr[0]);
      }
      let tableProm, totalProm;
      if (dateRangeArr && acc) {
        tableProm = getTableData(dateRangeArr[0]);
      } else {
        clearData();
        tableProm = Promise.resolve();
      }
      if (dateRangeArr && acc) {
        totalProm = getTotals(dateRangeArr[0]);
      } else {
        clearData();
        totalProm = Promise.resolve();
      }

      await Promise.all([tableProm, totalProm]);

      setGridLoading(false);
      setIsPageLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      const message = getException(err?.response?.data?.errorKey);
      setNoRowsText(message?.description);
      if (!gridFallback) {
        setGridFallback(true);
      }
      console.log(err);
    }
  };
  const [breadCrumbs] = useState(false);
  useEffect(() => {
    setIsPageLoading(true);
    setGridLoading(true);
    setTotal(null);
    setRowData([]);
    getData();
  }, [acc]);

  // Example using Grid's API
  // const buttonListener = useCallback((e) => {
  //   gridRef.current.api.deselectAll();
  // }, []);

  return (
    <>
      <div className="whoverview-container overflow-auto">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container"></div>
                ) : null}
                <div className="header">
                  <span>Warehouse Inventory</span>
                </div>
                <div className="subheader-container">
                  {total && total?.sum_credits_used ? (
                    <div className="sub-header">
                      <span>
                        Total Number of Credits Consumed {"- "}
                        {
                          //TODO Change the hardcode
                          <span className="subheader-bold">
                            {roundToSigDigits(
                              total?.sum_credits_used,
                              2,
                              false,
                              2
                            ).toLocaleString("en-US")}
                          </span>
                        }
                      </span>
                    </div>
                  ) : null}
                  {total && total?.sum_warehouse_cost ? (
                    <div className="sub-header">
                      <span>
                        Total Cost of Credits Consumed {"- "}
                        <span className="subheader-bold">
                          $
                          {roundToSigDigits(
                            total?.sum_warehouse_cost,
                            2,
                            false,
                            2
                          ).toLocaleString("en-US")}{" "}
                        </span>{" "}
                      </span>
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="top-filter">
                <div className="select-container">
                  <label>Date Range</label>
                  <Select
                    value={dateRange}
                    options={dateRangeOptions}
                    styles={customStyles}
                    classNamePrefix="selectcategory"
                    placeholder="select"
                    isSearchable={false}
                    components={{ DropdownIndicator }}
                    onChange={(e) => {
                      setGridLoading(true);
                      setDateRange(e);
                      getTableData(e);
                      getTotals(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="grid-block">
              {/* <div className="grid-header-container">
                <div className="grid-header-block">
                  <div className="grid-header">
                    <span>Inventory</span>
                  </div>
                </div>
              </div> */}

              <GridComponent
                columnDefs={columnDefs}
                rowData={rowData}
                gridFallback={gridFallback}
                gridStyle={{ height: 500 }}
                gridLoading={gridLoading}
                noRowsText={noRowsText}
              />
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
WarehouseInventory.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object
};
export default WarehouseInventory;
