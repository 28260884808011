//react imports
import React from "react";
import { useEffect, useState } from "react";
import PropTypes from "prop-types";

// component imports
import EmptyState from "../../components/Chargeback/EmptyState";

//CSS imports
import "./chargeback.scss";
import CreateModal from "../../components/Chargeback/CreateModal";
import ChargebackTable from "../../components/Chargeback/ChargebackTable";
import { getcostcentres } from "../../utils/chargeback-helper";

function Chargeback({ acc, dwAccOptions, changeActiveModule }) {
  const params = new URLSearchParams(window.location.search);
  let modal = params.get("modal");

  const [pageData, setPageData] = useState(null); //Whole page data
  const [isPageLoading, setIsPageLoading] = useState(false); //whole page loader
  const [resourceModal, setResourceModal] = useState(false); //popup handle state

  const handleModal = () => {
    if (resourceModal) {
      setIsPageLoading(true);
      getAllcostcenters();
    }
    setResourceModal(!resourceModal);
  };

  const getAllcostcenters = async () => {
    try {
      const response = await getcostcentres();
      if (response) {
        setPageData(response);
        setIsPageLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsPageLoading(false);
    }
  };

  useEffect(() => {
    setIsPageLoading(true);
    getAllcostcenters();
    if (modal === "true") {
      setResourceModal(true);
    } else {
      setResourceModal(false);
    }
  }, [acc, modal]);

  return !isPageLoading ? (
    <div className="cost-center-main-container">
      {pageData?.length > 0 ? (
        <ChargebackTable
          setIsPageLoading={setIsPageLoading}
          getAllcostcenters={getAllcostcenters}
          pageData={pageData}
          handleModal={handleModal}
          changeActiveModule={changeActiveModule}
        />
      ) : (
        <EmptyState handleModal={handleModal} />
      )}
      <CreateModal
        open={resourceModal}
        close={handleModal}
        acc={acc}
        dwAccOptions={dwAccOptions}
      />
    </div>
  ) : (
    <div className="load">
      <div className="preload"></div>
    </div>
  );
}
Chargeback.propTypes = {
  acc: PropTypes.object,
  dwAccOptions: PropTypes.array,
  changeActiveModule: PropTypes.func
};
export default Chargeback;
