import { CustomTooltip, CustomTooltipRev } from "../../utils/tooltip-helper";
import {
  prettyPrintBytes,
  pretty_print_number_with_suffix,
  roundToSigDigits
} from "../../utils/common-helper";

export const getTGProps = (hasTrialFallback) => {
  const customProps = {
    index: {
      type: "rightAligned",
      width: 75
    },
    table_name: {
      width: 250,
      filter: true,
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 10);
      },
      cellClass: "grid-cell-left-aligned"
    },
    table_schema: {
      width: 100,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    table_catalog: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    database: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    table_bytes: {
      width: 180,
      type: "rightAligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return params?.value != "-"
          ? CustomTooltipRev(
              prettyPrintBytes(params?.value, true),
              params.value,
              10
            )
          : "-";
      }
    },
    storage_bytes_billed: {
      width: 180,
      type: "rightAligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltipRev(
          prettyPrintBytes(params?.value, true),
          params.value,
          10
        );
      }
    },
    active_bytes: {
      width: 150,
      type: "rightAligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltipRev(
          prettyPrintBytes(params?.value, true),
          params.value,
          10
        );
      }
    },
    time_travel_bytes: {
      width: 150,
      type: "rightAligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltipRev(
          prettyPrintBytes(params?.value, true),
          params.value,
          10
        );
      }
    },
    failsafe_bytes: {
      width: 150,
      type: "rightAligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltipRev(
          prettyPrintBytes(params?.value, true),
          params.value,
          10
        );
      }
    },
    row_count: {
      width: 180,
      type: "rightAligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltipRev(
          pretty_print_number_with_suffix(params?.value),
          params.value,
          10
        );
      }
    },
    table_created: {
      width: 180,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params?.value, params.value, 10);
      }
    },
    table_dropped: {
      width: 180,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params?.value, params.value, 10);
      }
    },
    auto_clustering_on: {
      width: 188,
      cellClass: "grid-cell-left-aligned"
    },
    is_clone: {
      width: 120,
      cellClass: "grid-cell-left-aligned"
    },
    is_transient: {
      width: 120,
      cellClass: "grid-cell-left-aligned"
    },
    table_owner: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltip(params?.value, params.value, 10);
      }
    },
    avg_queued_overload_time: {
      width: 178,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_query_cost: {
      width: 100,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_query_cost: {
      width: 145,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    cost: {
      width: 145,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_gb_spilled_to_remote_storage: {
      width: 155,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_gb_spilled_to_remote_storage: {
      width: 155,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    retained_for_clone_bytes: {
      width: 150,
      type: "rightAligned",
      cellRenderer: (params) => {
        if (hasTrialFallback && params.rowIndex > 1) {
          return params.value;
        }
        return CustomTooltipRev(
          prettyPrintBytes(params?.value, true),
          params.value,
          10
        );
      }
    }
  };
  return customProps;
};
