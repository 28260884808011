import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./querygroups.scss";
import chev_down from "../../assets/images/common/chev_down.svg";
import {
  createColDefs,
  getSortingValueObj,
  getValueObj,
  handleGridResolution
} from "../../utils/common-helper";
import GridComponent from "../../components/GridComponent";
import Select, { components } from "react-select";
import { getQueryGroupsData, getSortingOptions } from "../../utils/user-helper";
import { getQGProps } from "./querygroupsColDefs";
import { getQGDateRange } from "../../utils/query-helper";
import { getException } from "../../utils/exceptiondictionary";
import Button from "../../components/customElements/Button";
import FilterIcon from "../../assets/images/grid/Filter.svg";
import ExportCSV from "../../assets/images/grid/exportcsv.svg";
import CustomFilter from "../../components/CustomFilter/CustomFilter";
import { getSessionStorage } from "../../utils/storage-helper";
import {
  QGFilterColumns,
  applyFilterToGrid,
  clearAllFilters,
  exportCSVcolumns,
  filterTypes,
  postFilter
} from "../../utils/filter-helper";
// import Button from "../../components/customElements/Button";

const QueryGroups = ({ acc, changeActiveModule }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };
  const [hasTrialFallback] = useState(
    getSessionStorage("plan_name").toLowerCase() === "trial" ? true : false
  );
  const customProps = getQGProps(hasTrialFallback);
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const univerSalStyle = {
    cellStyle: (params) => {
      if (hasTrialFallback && params.rowIndex > 1) {
        return {
          borderRight: "1px solid #eaeaeb",
          userSelect: "none",
          "-webkit-user-select": "none"
        };
      }
      return { borderRight: "1px solid #eaeaeb" };
    }
  };

  const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [gridData, setGridData] = useState(null); // Set gridData to
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [sortingOptions, setSortingOptions] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [sorting, setSorting] = useState(null);
  const [gridFallback, setGridFallback] = useState(false);
  const [noRowsText, setNoRowsText] = useState(
    "Query groups for the selected period or account are currently unavailable. Coming soon..."
  );
  const [requiredCols, setRequiredCols] = useState([]);
  const [gridResolution, setGridResolution] = useState(true);

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [downloadCSV, setDownloadCSV] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [applyFilter, setApplyFilter] = useState([
    {
      column: QGFilterColumns?.[0],
      condition: filterTypes?.[0],
      value: [],
      dropDown: false,
      previuosValue: [],
      previousMenuList: []
    }
  ]);

  const getTableData = async (dateRange, sorting) => {
    try {
      if (
        sorting &&
        Object.keys(sorting).length > 0 &&
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        acc &&
        Object.keys(acc).length > 0
      ) {
        const data = await getQueryGroupsData({
          data_wh_id: acc?.id,
          date_range: dateRange?.label,
          sorted_by: sorting?.value
        });
        if (data) {
          const downloadColName = "Query Ids";
          data?.data?.forEach((item) => {
            item[downloadColName] = {
              data_wh_id: acc?.id,
              date_range: dateRange?.label,
              regex_id: item.regex_id,
              filter_type: "all"
            };
          });
          data?.columns?.push?.(downloadColName);

          const colDefsArr = createColDefs(
            data?.columns,
            customProps,
            univerSalStyle,
            data?.column_display_name_mappping
          );
          if (colDefsArr && data?.data) {
            const newData = colDefsArr.map((colDef) => ({
              ...colDef,
              unSortIcon: true,
              sortable: true,
              filter: false
            }));
            setColumnDefs(newData);
            const requiredColumns = exportCSVcolumns(newData);
            setRequiredCols(requiredColumns);
            setRowData(data?.data);
            setGridData(data?.data);
            let screenInnerWidth = window.innerWidth;
            let gridWidth = Object.values(newData).reduce((sum, prop) => {
              if (typeof prop.width === "number" && !isNaN(prop.width)) {
                return sum + prop.width;
              }
              return sum;
            }, 0);
            setGridResolution(
              handleGridResolution(gridWidth, screenInnerWidth)
            );
          }
        } else {
          clearData();
        }
        setGridLoading(false);
        if (gridFallback) {
          setGridFallback(false);
        }
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      const message = getException(err?.response?.data?.errorKey);

      if (
        !gridFallback &&
        err?.response?.data?.errorKey !== "USER_PLAN_NOT_ALLOWED"
      ) {
        setNoRowsText(
          "Query groups for the selected period or account are currently unavailable. Coming soon..."
        );
        setGridFallback(true);
      } else if (err?.response?.data?.errorKey === "USER_PLAN_NOT_ALLOWED") {
        setGridFallback(true);
        setNoRowsText(message?.description);
      }
      console.log(err);
    }
  };

  const clearData = () => {
    setRowData([]);
    setGridData([]);
  };

  const getData = async () => {
    let sortingArr = [];
    let dateRangeArr = [];
    try {
      const dateRangeObj = await getQGDateRange(acc ? acc?.id : "");
      const sortingOptionsObj = await getSortingOptions();
      if (dateRangeObj?.values) {
        dateRangeArr = getValueObj(dateRangeObj?.values);
        setDateRangeOptions(dateRangeArr);
        setDateRange(dateRangeArr[0]);
      }
      if (sortingOptionsObj?.values) {
        sortingArr = getSortingValueObj(sortingOptionsObj?.values);
        setSortingOptions(sortingArr);
        setSorting(sortingArr[0]);
      }
      if (dateRangeArr && sortingArr && acc) {
        getTableData(dateRangeArr[0], sortingArr[0]);
      } else {
        clearData();
      }
      setGridLoading(false);
      setIsPageLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      console.log(err);
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      const message = getException(err?.response?.data?.errorKey);

      if (
        !gridFallback &&
        err?.response?.data?.errorKey !== "USER_PLAN_NOT_ALLOWED"
      ) {
        setNoRowsText(
          "Query groups for the selected period or account are currently unavailable. Coming soon..."
        );
        setGridFallback(true);
      } else if (err?.response?.data?.errorKey === "USER_PLAN_NOT_ALLOWED") {
        setGridFallback(true);
        setNoRowsText(message?.description);
      }
      console.log(err);
    }
  };
  const handleExport = () => {
    if (!hasTrialFallback) {
      setDownloadCSV(true);
      setTimeout(() => {
        setDownloadCSV(false);
      }, 3000);
    }
  };
  const [breadCrumbs] = useState(false);
  useEffect(() => {
    setIsPageLoading(true);
    setGridLoading(true);
    getData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let dateRangeArr = [];
        setGridLoading(true);
        if (acc) {
          const dateRangeObj = await getQGDateRange(acc ? acc?.id : "");
          if (dateRangeObj?.values) {
            dateRangeArr = getValueObj(dateRangeObj?.values);
            setDateRangeOptions(dateRangeArr);
            setDateRange(dateRangeArr[0]);
          }
          if (dateRangeArr && dateRangeArr?.length && sorting) {
            getTableData(dateRangeArr[0], sorting);
          }
        }
      } catch (err) {
        clearData();
        setGridLoading(false);
        setIsPageLoading(false);
        const message = getException(err?.response?.data?.errorKey);

        if (
          !gridFallback &&
          err?.response?.data?.errorKey !== "USER_PLAN_NOT_ALLOWED"
        ) {
          setNoRowsText(
            "Query groups for the selected period or account are currently unavailable. Coming soon..."
          );
          setGridFallback(true);
        } else if (err?.response?.data?.errorKey === "USER_PLAN_NOT_ALLOWED") {
          setGridFallback(true);
          setNoRowsText(message?.description);
        }
        console.log(err);
      }
    };
    fetchData();
  }, [acc]);

  const handlePopover = (e) => {
    if (!hasTrialFallback) {
      e?.stopPropagation();
      setShowFilters(!showFilters);
    }
  };

  const clearGridFilter = () => {
    setGridData(rowData);
    setFilterApplied(false);
    const filterData = clearAllFilters(applyFilter);
    setApplyFilter(filterData);
  };
  // Filter Function
  const handleGridFilter = (applyifilters) => {
    setGridLoading(true);
    const filteredData = applyFilterToGrid(applyifilters, rowData);
    setGridData(filteredData);
    const handleFilterValue = postFilter(applyifilters);
    setApplyFilter(handleFilterValue);
    setFilterApplied(true);

    setTimeout(() => {
      setGridLoading(false);
    }, 1500);
  };

  return (
    <>
      <div className="qg-container overflow-auto">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container"></div>
                ) : null}
                <div className="header">
                  <span>Top 50 Query Groups</span>
                </div>
              </div>
            </div>
            <div className="grid-block">
              <div className="grid-header-container">
                <div className="grid-filter-container">
                  <div className="grid-filter-wrapper-left">
                    <div className="select-container custom__select">
                      <Select
                        value={sorting}
                        options={sortingOptions}
                        styles={customStyles}
                        classNamePrefix="selectcategory"
                        components={{ DropdownIndicator }}
                        placeholder="select"
                        isSearchable={false}
                        onChange={(e) => {
                          setSorting(e);
                          setGridLoading(true);
                          getTableData(dateRange, e);
                        }}
                      />
                    </div>
                    <div className="top-filter">
                      <div className="select-container">
                        <Button
                          onClick={handlePopover}
                          disabled={hasTrialFallback}
                          className={
                            showFilters
                              ? "qg-filter-button-active "
                              : filterApplied
                              ? "qg-filter-active"
                              : "qg-filter-button"
                          }>
                          <img src={FilterIcon} alt="" />
                          <span>Filter</span>
                        </Button>
                        {showFilters && !hasTrialFallback && (
                          <CustomFilter
                            column={QGFilterColumns}
                            gridData={rowData}
                            applyFilter={applyFilter}
                            setApplyFilter={setApplyFilter}
                            close={handlePopover}
                            handleFilter={handleGridFilter}
                            clearFilter={clearGridFilter}
                            isMenuOpen={showFilters}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="grid-filter-wrapper-right">
                    <div className="top-filter">
                      <div className="select-container filter-container">
                        <Select
                          value={dateRange}
                          options={dateRangeOptions}
                          styles={customStyles}
                          classNamePrefix="selectcategory"
                          placeholder="select"
                          isSearchable={false}
                          components={{ DropdownIndicator }}
                          onChange={(e) => {
                            setDateRange(e);
                            setGridLoading(true);
                            getTableData(e, sorting);
                          }}
                        />
                      </div>
                    </div>
                    <div className="select-container export-container">
                      <Button
                        disabled={hasTrialFallback}
                        onClick={handleExport}
                        className={"qg-filter-button"}>
                        <img src={ExportCSV} alt="" />
                        <span>Export</span>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>

              <GridComponent
                columnDefs={columnDefs}
                rowData={gridData}
                gridFallback={gridFallback}
                gridStyle={{ height: 400 }}
                gridLoading={gridLoading}
                noRowsText={noRowsText}
                downloadCSV={downloadCSV}
                hasTrialFallback={hasTrialFallback}
                changeActiveModule={changeActiveModule}
                tableName={"Top 50 Query Groups"}
                exportColumns={requiredCols}
                suppressColumnsSize={gridResolution}
              />
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
QueryGroups.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  changeActiveModule: PropTypes.func
};
export default QueryGroups;
