import React, { useEffect } from "react";
import PropTypes from "prop-types";
import ChatwootAuthSetter from "./ChatwootAuthSetter";
import { env } from "../../env";

const ChatwootWidget = () => {
  const [isLoaded, setIsLoaded] = React.useState(false);

  useEffect(() => {
    window.chatwootSettings = {
      position: "right",
      type: "expanded_bubble",
      launcherTitle: "Chat with us"
    };

    // Paste the script from inbox settings except the <script> tag
    (function (d, t) {
      var g = d.createElement(t),
        s = d.getElementsByTagName(t)[0];
      g.id = "chatwoot-web-sdk";
      g.src = env?.REACT_APP_CHATWOOT_BASEURL + "/packs/js/sdk.js";
      s.parentNode.insertBefore(g, s);
      g.async = !0;
      g.onload = function () {
        window.chatwootSDK?.run({
          websiteToken: env?.REACT_APP_CHATWOOT_TOKEN,
          baseUrl: env?.REACT_APP_CHATWOOT_BASEURL
        });
        window.addEventListener("chatwoot:ready", function () {
          console.log("Chatwoot is ready to use");
          setIsLoaded(true);
        });
      };
    })(document, "script");

    return () => {
      window.$chatwoot?.reset();
      document.getElementById("chatwoot-web-sdk").remove();
    };
  });

  if (isLoaded) {
    return <ChatwootAuthSetter />;
  }
  return null;
};

ChatwootWidget.propTypes = {
  children: PropTypes.any
};

export default ChatwootWidget;
