import BQ from "./BQ_new.svg";
import Databricks from "./connection_dbrcs.svg";
import Redshift from "./connection_rds.svg";
import Snowflake from "./connection_sf.svg";

export default {
  BQ,
  Databricks,
  Redshift,
  Snowflake
};
