import React from "react";
import PropTypes from "prop-types";
import "./botton.scss";

function Button(props) {
  const { disabled, className, children, ...otherProps } = props;
  return (
    <button
      className={
        disabled
          ? `custom__button ${className} disabled__button`
          : `custom__button ${className}`
      }
      {...otherProps}>
      {children}
    </button>
  );
}
Button.propTypes = {
  children: PropTypes.node, // PropTypes.node allows any kind of children, such as text or components
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled:PropTypes.bool
};
export default Button;
