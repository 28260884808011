import React from "react";
import "./chartcomponent.scss";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import { getTimezone } from "../../utils/date-helper";
import { getChartGlobalConfig, getChartGlobalStyle } from "./chartConstants";
import Spline from "./Spline";
import Histogram from "./Histogram";
import Line from "./Line";
Highcharts.setOptions({
  time: {
    timezone: getTimezone()
  },
  chart: {
    style: {
      fontFamily: "Inter"
    }
  }
});

const ChartComponent = ({ options, type }) => {
  let chartOptions = getChartGlobalConfig();
  chartOptions = {
    chart: { ...chartOptions?.chart, ...options?.chart },
    title: { ...chartOptions?.title, ...options?.title },
    xAxis: { ...chartOptions?.xAxis, ...options?.xAxis },
    yAxis: [...(chartOptions?.yAxis || {}), ...(options?.yAxis || {})] || [],
    tooltip: { ...chartOptions?.tooltip, ...options?.tooltip },
    series: [...(chartOptions.series || {}), ...(options?.series || {})] || [],
    legend: { ...chartOptions?.legend, ...options?.legend },
    credits: { ...chartOptions?.credits },
    time: { ...chartOptions?.time }
  };
  const viewFunc = () => {
    let view = undefined;
    switch (type) {
      case "Spline": {
        view = <Spline options={chartOptions} />;
        break;
      }
      case "Histogram": {
        view = <Histogram options={chartOptions} />;
        break;
      }
      case "Line": {
        view = <Line options={chartOptions} />;
        break;
      }
    }
    return view;
  };
  const chartView = viewFunc();
  const style = getChartGlobalStyle(chartOptions);
  return (
    <div className="grid-container">
      <div className="grid ag-theme-alpine" style={style}>
        {chartView ? chartView : null}
        {/* <HighchartsReact highcharts={Highcharts} options={options} /> */}
      </div>
    </div>
  );
};
ChartComponent.propTypes = {
  options: PropTypes.object,
  type: PropTypes?.string
};
export default ChartComponent;
