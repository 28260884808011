import Connectionimages from "../assets/images/connection/index";
const CONNECTIONS = [
  {
    name: "Snowflake",
    label: "Snowflake",
    id: "SF",
    image: Connectionimages.Snowflake,
    available: true
    //selected: true
  },
  {
    name: "Databricks",
    label: "Databricks",
    image: Connectionimages.Databricks,
    id: "DTB",
    available: false
    //selected: false
  },
  {
    name: "Redshift",
    label: "Redshift",
    image: Connectionimages.Redshift,
    id: "RDSFT",
    available: false
    //selected: false
  },
  {
    name: "Big Query",
    label: "Big Query",
    image: Connectionimages.BQ,
    id: "BQ",
    available: false
    //selected: false
  }
];
const instructionList = [
  { label: "Create a warehouse CHAOSGENIUS_WH", index: "1. " },
  { label: "Create a CHAOSGENIUS_ROLE role", index: "2. " },
  {
    label: "Create a CHAOSGENIUS user with a CHAOSGENIUS_ROLE role",
    index: "3. "
  },
  {
    label:
      "Grant USAGE and OPERATE access control on CHAOSGENIUS_WH warehouse to CHAOSGENIUS_ROLE role",
    index: "4. "
  },
  {
    label:
      "Grant IMPORTED PRIVILEGES on database SNOWFLAKE to role CHAOSGENIUS_ROLE",
    index: "5. "
  }
];
const codeInstructionList = [
  {
    label: "<span class='sp-r'>use role</span> ACCOUNTADMIN;",
    index: "1. "
  },
  {
    label:
      "<span class='sp-r'>create</span> WAREHOUSE CHAOSGENIUS_WH <span class='sp-r'>with</span> WAREHOUSE_SIZE <span class='sp-r'>=</span> <span class='sp-b'>'XSMALL'</span> AUTO_SUSPEND<span class='sp-r'>=</span><span class='sp-b'>60</span> INITIALLY_SUSPENDED<span class='sp-r'>=</span>TRUE;",
    index: "2. "
  },
  {
    label: "<span class='sp-r'>create role</span> CHAOSGENIUS_ROLE;",
    index: "3. "
  },
  {
    label:
      "<span class='sp-r'>create user</span> <span class='sp-v'>CHAOSGENIUS</span> <span class='sp-r'>password</span> = <span class='sp-db'>'<<span>insert-new-password</span>>'</span> default_role = CHAOSGENIUS_ROLE;",
    index: "4. "
  },
  {
    label:
      "<span class='sp-r'>grant</span> USAGE <span class='sp-r'>on</span> WAREHOUSE CHAOSGENIUS_WH <span class='sp-r'>to role</span> CHAOSGENIUS_ROLE;",
    index: "5. "
  },
  {
    label:
      "<span class='sp-r'>grant</span> OPERATE <span class='sp-r'>on</span> WAREHOUSE CHAOSGENIUS_WH <span class='sp-r'>to role</span> CHAOSGENIUS_ROLE;",
    index: "6. "
  },
  {
    label:
      "<span class='sp-r'>grant role</span> CHAOSGENIUS_ROLE <span class='sp-r'>to</span> user CHAOSGENIUS;",
    index: "7. "
  },
  {
    label:
      "<span class='sp-r'>grant</span> imported privileges <span class='sp-r'>on database</span> snowflake <span class='sp-r'>to role</span> CHAOSGENIUS_ROLE;",
    index: "8. "
  },
  {
    label:
      "<span class='sp-r'>grant</span> monitor usage <span class='sp-r'>on</span> account <span class='sp-r'>to role</span> CHAOSGENIUS_ROLE;",
    index: "9. "
  },
  {
    label:
      "<span class='sp-r'>create or</span> replace network <span class='sp-r'>policy</span> CHAOSGENIUS_NETWORK_POLICY ALLOWED_IP_LIST = <span class='sp-r'>(</span><span class='sp-b'>'18.236.195.203','52.89.64.146','44.229.215.209'</span><span class='sp-r'>)</span>;",
    index: "10. "
  },
  {
    label:
      "<span class='sp-r'>alter user</span> CHAOSGENIUS <span class='sp-r'>set</span> network_policy = CHAOSGENIUS_NETWORK_POLICY;",
    index: "11. "
  }
];

const step4CodeInstructionList = [
  {
    label:
      "<span class='sp-r'>create database if not exists</span> chaosgenius;",
    index: "1. "
  },
  {
    label:
      "<span class='sp-r'>create</span> or <span class='sp-r'>replace procedure</span> chaosgenius.<span class='sp-r'>public</span>.grant_monitor_on_all_whs()",
    index: "2. "
  },
  {
    label:
      "<span class='margin-left-10'><span class='sp-r'>returns varchar</span> not <span class='sp-v'>null</span></span>",
    index: "3. "
  },
  {
    label:
      "<span class='margin-left-10'><span class='sp-r'>language</span> javascript</span>",
    index: "4. "
  },
  {
    label:
      "<span class='margin-left-10'><span class='sp-r'>execute as</span> owner</span>",
    index: "5. "
  },
  {
    label: "<span class='margin-left-10'><span class='sp-r'>as</span></span>",
    index: "6. "
  },
  {
    label: "<span class='margin-left-10'>$$</span>",
    index: "7. "
  },
  {
    label: `<span class='margin-left-10'>let ret =  <span class='sp-b'>"MONITOR access granted on: "</span>;</span>`,
    index: "8. "
  },
  {
    label: `<span class='margin-left-10'>const res = snowflake.<span class='sp-r'>execute</span>( {sqlText: <span class='sp-b'>"SHOW WAREHOUSES;"</span>} );</span>`,
    index: "9. "
  },
  {
    label:
      "<span class='margin-left-10'><span class='sp-r'>while</span> (res.<span class='sp-r'>next</span>())  {</span>",
    index: "10. "
  },
  {
    label: `<span class='margin-left-20'>const whName = <span class='sp-b'>'"'</span> + res.getColumnValue(<span class='sp-v'>1</span>).replaceAll(<span class='sp-b'>'"'</span>, <span class='sp-b'>'""'</span>) + <span class='sp-b'>'"'</span>;</span>`,
    index: "11. "
  },
  {
    label: `<span class='margin-left-20'>ret += <span class='sp-b'>"&bsol;n- "</span> + whName;</span>`,
    index: "12. "
  },
  {
    label:
      "<span class='margin-left-20'>const grantQuery = 'grant MONITOR on WAREHOUSE IDENTIFIER(:1) to role CHAOSGENIUS_ROLE;';</span>",
    index: "13. "
  },
  {
    label: `<span class='margin-left-20'>ret += <span class='sp-b'>"&bsol;n\\tGrant Query: "</span> + grantQuery;</span>`,
    index: "14. "
  },
  {
    label: "<span class='margin-left-20'>try {</span>",
    index: "15. "
  },
  {
    label:
      "<span class='margin-left-30'>snowflake.<span class='sp-r'>execute</span>( {sqlText: grantQuery, binds: [whName]} );</span>",
    index: "16. "
  },
  {
    label: "<span class='margin-left-20'>} catch (err) {</span>",
    index: "17. "
  },
  {
    label: `<span class='margin-left-25'>ret += <span class='sp-b'>"&bsol;n\\tQuery Failed for "</span> + whName;</span>`,
    index: "18. "
  },
  {
    label: `<span class='margin-left-25'>ret += <span class='sp-b'>"&bsol;n\\tCode: "</span> + err.code;</span>`,
    index: "19. "
  },
  {
    label: `<span class='margin-left-25'>ret += <span class='sp-b'>"&bsol;n\\tState: "</span> + err.state;</span>`,
    index: "20. "
  },
  {
    label: `<span class='margin-left-25'>ret += <span class='sp-b'>"&bsol;n\\tMessage: "</span> + err.message;</span>`,
    index: "21. "
  },
  {
    label: `<span class='margin-left-25'>ret += <span class='sp-b'>"&bsol;n\\tStack Trace:&bsol;n"</span> + err.stackTraceTxt;</span>`,
    index: "22. "
  },
  {
    label: `<span class='margin-left-20'>}</span>`,
    index: "23. "
  },
  {
    label: `<span class='margin-left-10'>}</span>`,
    index: "24. "
  },
  {
    label:
      "<span class='margin-left-10'><span class='sp-r'>return</span> ret;</span>",
    index: "25. "
  },
  {
    label: "<span class='margin-left-10'>$$</span>",
    index: "26. "
  },
  {
    label: "<span class='margin-left-10'>;</span>",
    index: "27. "
  },
  {
    label:
      "<span class='sp-r'>call</span> chaosgenius.<span class='sp-r'>public</span>.grant_monitor_on_all_whs();",
    index: "28. "
  },
  {
    label:
      "<span class='sp-r'>create</span> or <span class='sp-r'>replace</span> task chaosgenius.<span class='sp-r'>public</span>.chaosgenius_grant_monitor_on_all_whs",
    index: "29. "
  },
  {
    label:
      "<span class='margin-left-10'>USER_TASK_MANAGED_INITIAL_WAREHOUSE_SIZE = <span class='sp-b'>'XSMALL'</span></span>",
    index: "30. "
  },
  {
    label:
      "<span class='margin-left-10'>SCHEDULE = <span class='sp-b'>'1440 minute'</span></span>",
    index: "31. "
  },
  {
    label: "<span class='sp-r'>as</span>",
    index: "32. "
  },
  {
    label:
      "<span class='margin-left-10'><span class='sp-r'>call</span> chaosgenius.<span class='sp-r'>public</span>.grant_monitor_on_all_whs();</span>",
    index: "33. "
  },
  {
    label:
      "<span class='sp-r'>alter</span> task chaosgenius.<span class='sp-r'>public</span>.chaosgenius_grant_monitor_on_all_whs resume;",
    index: "34. "
  }
];

const fullCodeInstructionText = `use role ACCOUNTADMIN;
CREATE WAREHOUSE CHAOSGENIUS_WH with WAREHOUSE_SIZE = 'XSMALL' AUTO_SUSPEND=60 INITIALLY_SUSPENDED=TRUE;
create role CHAOSGENIUS_ROLE;
create user CHAOSGENIUS password = '<insert-new-password>' default_role = CHAOSGENIUS_ROLE;
grant USAGE on WAREHOUSE CHAOSGENIUS_WH to role CHAOSGENIUS_ROLE;
grant OPERATE on WAREHOUSE CHAOSGENIUS_WH to role CHAOSGENIUS_ROLE;
grant role CHAOSGENIUS_ROLE to user CHAOSGENIUS;
grant imported privileges on database snowflake to role CHAOSGENIUS_ROLE;
grant monitor usage on account to role CHAOSGENIUS_ROLE;
create or replace network policy CHAOSGENIUS_NETWORK_POLICY ALLOWED_IP_LIST = ('18.236.195.203','52.89.64.146','44.229.215.209'); 
alter user CHAOSGENIUS set network_policy = CHAOSGENIUS_NETWORK_POLICY;`;

const Step4FullCodeInstructionText = `create database if not exists chaosgenius;
create or replace procedure chaosgenius.public.grant_monitor_on_all_whs()
    returns varchar not null
    language javascript
    execute as owner
    as
    $$
    let ret = "MONITOR access granted on: ";

    const res = snowflake.execute( {sqlText: "SHOW WAREHOUSES;"} );

    while (res.next()) {
        const whName = '"' + res.getColumnValue(1).replaceAll('"', '""') + '"';

        ret += "\\n- " + whName;

        const grantQuery = 'grant MONITOR on WAREHOUSE IDENTIFIER(:1) to role CHAOSGENIUS_ROLE;';
        ret += "\\n\\tGrant Query: " + grantQuery;
        try {
            snowflake.execute( {sqlText: grantQuery, binds: [whName]} );
        } catch (err) {
          ret += "\\n\\tQuery Failed for " + whName;
          ret += "\\n\\tCode: " + err.code;
          ret += "\\n\\tState: " + err.state;
          ret += "\\n\\tMessage: " + err.message;
          ret += "\\n\\tStack Trace:\\n" + err.stackTraceTxt;
        }
    }
    return ret;
    $$
    ;

call chaosgenius.public.grant_monitor_on_all_whs();
create or replace task chaosgenius.public.chaosgenius_grant_monitor_on_all_whs
    USER_TASK_MANAGED_INITIAL_WAREHOUSE_SIZE = 'XSMALL'
    SCHEDULE = '1440 minute'
as
    call chaosgenius.public.grant_monitor_on_all_whs();
alter task chaosgenius.public.chaosgenius_grant_monitor_on_all_whs resume;`;

export const getCards = () => {
  return CONNECTIONS;
};

export const getCardItem = (id, searchbyValue = false) => {
  let index = -1;
  if (searchbyValue) {
    index = CONNECTIONS.findIndex((connection) => {
      return connection?.label?.toLowerCase() === id?.toLowerCase();
    });
  } else {
    index = CONNECTIONS.findIndex((connection) => {
      return connection?.id?.toLowerCase() === id?.toLowerCase();
    });
  }
  if (index >= 0) {
    return CONNECTIONS[index];
  } else {
    return null;
  }
};
export const getInstructionList = () => {
  return {
    instructionList,
    codeInstructionList,
    fullCodeInstructionText,
    step4CodeInstructionList,
    Step4FullCodeInstructionText
  };
};

export const getCardFilter = (data, filterValue) => {
  if (!data) {
    return null;
  }
  const filteredData = data.filter((value) => {
    return value?.type === filterValue;
  });
  return filteredData;
};
