import React, { useEffect, useState } from "react";
import "./ssosettings.scss";
import active_img from "../../assets/images/common/active_img.svg";
import disabled_img from "../../assets/images/common/disabled_img.svg";
import in_progress from "../../assets/images/common/in_progress.svg";
import green_tick from "../../assets/images/dashboard/green_tick.svg";
import skeletn_img from "../../assets/images/common/skeletn_img.svg";
//import ellipse_img from "../../assets/images/common/ellipse_img.png";
import info__dark from "../../assets/images/common/info__dark.svg";
import edit__img from "../../assets/images/common/edit__img.svg";
import warning from "../../assets/images/common/warning.svg";
import { IDPProviders, getIDPListAPI } from "../../utils/settings-helper";
import { getException } from "../../utils/exceptiondictionary";
import billing__fallback from "../../assets/images/common/billing__fallback.svg";
//import { prettyPrintBytes } from "../../utils/common-helper";
import {
  // disableSSOAPI,
  //enableSSOAPI,
  getSSODetailsAPI,
  ssoPatchAPI,
  ssoPatchIDPAPI,
  ssoPostIDPAPI
} from "../../utils/settings-helper";
import { searchByRegex } from "../../utils/common-helper";
import { CustomTooltip } from "../../utils/tooltip-helper";
import { useAuth } from "../../utils/auth-helper";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { useToast } from "react-toast-wnm";

const SSOSettings = () => {
  const { userInfo } = useAuth();
  const toast = useToast();
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const [stepsVisible, setStepsVisible] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [idpsSetup, setIdpsSetup] = useState(null);
  const [idps, setIdps] = useState(null);
  const [SSOSetup, setSSOSetup] = useState(false);
  const [metaFile, setMetaFile] = useState(null);
  const [certFile, setCertFile] = useState(null);
  const [metaFileName, setMetaFileName] = useState("");
  const [certFileName, setCertFileName] = useState("");
  const [metaUploaded, setMetaUploaded] = useState(false);
  const [certUploaded, setCertUploaded] = useState(false);
  const [disablePwdLogin, setDisablePwdLogin] = useState(false);
  const [editModeOn, setEditModeOn] = useState(false);
  const [ssoEnabled, setSSOEnabled] = useState(null);
  const [setupSelected, setSetupSelected] = useState("");
  const [iDPID, setIDPID] = useState(null);
  const [displayName, setDisplayName] = useState("");
  const [isModified, setIsModified] = useState(false);
  const [infoError, setInfoError] = useState({
    meta: "",
    cert: ""
  });

  const getData = async () => {
    try {
      const ssoConfig = await getSSODetailsAPI();
      if (ssoConfig) {
        const {
          //cognito_app_client_id,
          idps,
          password_login_enabled
          //sso_enabled
        } = ssoConfig;
        if (!idps || !idps?.length) {
          setSSOSetup(false);
          const idpListData = await getIDPListAPI();
          if (idpListData && idpListData?.length) {
            const idpList = idpListData?.map((idp) => {
              return {
                ...IDPProviders()[idp]
              };
            });
            setIdpsSetup(idpList);
            if (password_login_enabled) {
              setDisablePwdLogin(false);
            } else {
              setDisablePwdLogin(true);
            }

            setIsPageLoading(false);
          }
        } else if (idps && idps?.length) {
          setSSOSetup(true);
          const idpList = idps?.map((idp) => {
            return {
              ...idp,
              ...IDPProviders()[idp?.idp_type]
            };
          });
          setIdps(idpList);
          if (password_login_enabled) {
            setDisablePwdLogin(false);
          } else {
            setDisablePwdLogin(true);
          }
          setIsPageLoading(false);
        }
      } else {
        setSSOSetup(false);
        setIsPageLoading(false);
      }
    } catch (er) {
      console.log(er);
      setIsPageLoading(false);
    }
  };
  useEffect(() => {
    if (userInfo?.info?.role?.toString() === "admin") {
      setIsPageLoading(true);
      getData();
    }
  }, []);
  const changeHandler = (e, origin) => {
    let errObj = { ...infoError };
    const file = e.target.files[0];
    if (file) {
      console.log(file?.type);
      if (origin === "meta") {
        if (Number(file?.size) > 2000000) {
          errObj["meta"] = "File size should be less than 2MB";
          setInfoError(errObj);
        } else if (
          searchByRegex(file?.type?.toLowerCase(), [/xml/i, /txt/i, /text/i]) <
          0
        ) {
          errObj["meta"] = "File type should be xml or txt";
          setInfoError(errObj);
        } else {
          setMetaUploaded(true);
          setMetaFile(file);
          setMetaFileName(file?.name);
        }
      } else if (origin === "cert") {
        if (Number(file?.size) > 2000000) {
          errObj["cert"] = "File size should be less than 2MB";
          setInfoError(errObj);
        } else if (
          searchByRegex(file?.type?.toLowerCase(), [
            /crt/i,
            /txt/i,
            /text/i,
            /cert/i
          ]) < 0
        ) {
          errObj["cert"] = "File type should be crt or txt";
          setInfoError(errObj);
        } else {
          setCertUploaded(true);
          setCertFile(file);
          setCertFileName(file?.name);
        }
      }
    }
  };

  const saveSSOConfig = async (meta, cert, action) => {
    try {
      let patchIDP = null;
      if (action === "update") {
        const patchSSO = ssoPatchAPI(disablePwdLogin);
        patchIDP = ssoPatchIDPAPI(iDPID ? iDPID : null, meta, cert, true);
        await Promise.all([patchSSO, patchIDP]);
        setEditModeOn(false);
        getData();
      } else if (action === "setup") {
        const patchSSO = ssoPatchAPI(disablePwdLogin);
        const postIDP = ssoPostIDPAPI(meta, cert, displayName);
        await Promise.all([patchSSO, postIDP]);
        setEditModeOn(false);
        getData();
      }
    } catch (er) {
      console.log(er);
      setEditModeOn(false);
      getData();
    }
  };

  const updateSSOConfig = (action) => {
    let errObj = { ...infoError };
    if (!metaUploaded) {
      errObj["meta"] = "Please upload a relevant sso meta file";
    }
    if (!certUploaded) {
      errObj["cert"] = "Please upload a relevant signed certificate file";
    }
    setInfoError(errObj);
    if (errObj["meta"] === "" && errObj["cert"] === "" && isModified) {
      setIsPageLoading(true);
      saveSSOConfig(metaFile, certFile, action);
      setIsModified(false);
    }
  };
  const handleDisable = () => {
    let errObj = { ...infoError };
    if (!metaUploaded) {
      errObj["meta"] = "Please upload a relevant sso meta file";
    }
    if (!certUploaded) {
      errObj["cert"] = "Please upload a relevant signed certificate file";
    }
    setInfoError(errObj);
    if (errObj["meta"] === "" && errObj["cert"] === "") {
      setIsPageLoading(true);
      disableSSO(metaFile, certFile);
    }
  };
  const IDPSetupView =
    idpsSetup &&
    idpsSetup?.length &&
    idpsSetup?.map((idp, index) => {
      return (
        <div
          className={`sso__img__container ${
            stepsVisible && setupSelected === idp?.idp_type ? "green__bg" : ""
          }`}
          onClick={() => {
            if (userInfo?.info?.plan_name.toLowerCase() === "enterprise") {
              setSetupSelected(idp?.idp_type);
              setStepsVisible(!stepsVisible);
              setDisplayName(idp?.idp_type);
              setMetaFile(null);
              setCertFile(null);
              setMetaUploaded(false);
              setCertUploaded(false);
              setMetaFileName("");
              setCertFileName("");
            } else {
              customToast({
                type: "error",
                ...getException("ENTERPRISE_ONLY")
              });
            }
          }}
          key={index}>
          <img className="ping_img" src={idp?.img} />
          <span>{idp?.label}</span>
          {stepsVisible && setupSelected === idp?.idp_type ? (
            <img
              src={green_tick}
              className={`cg-absolute-position tick__img`}
            />
          ) : null}
        </div>
      );
    });

  const idpEditView =
    idps &&
    idps?.length &&
    idps?.map((idp, index) => {
      return (
        <div
          className={`sso__banner__container`}
          onClick={() => {
            setSSOEnabled(idp);
            setDisplayName(idp?.idp_type);
            setIDPID(idp?.idp_id);
            setMetaFile(null);
            setCertFile(null);
            setMetaUploaded(true);
            setCertUploaded(true);
            setMetaFileName(idp?.config_file_name);
            setCertFileName(idp?.cert_file_name);
            setEditModeOn(true);
          }}
          key={index}>
          <img src={idp?.img} />
          <span>{idp?.label}</span>
          <img
            src={
              !idp?.synced_in_cognito
                ? in_progress
                : idp?.enable_idp
                ? active_img
                : disabled_img
            }
          />
          <img src={edit__img} className="cg-absolute-position edit__img" />
        </div>
      );
    });

  const disableSSO = async (meta, cert) => {
    try {
      const patchSSO = ssoPatchAPI(disablePwdLogin);
      const patchIDP = ssoPatchIDPAPI(iDPID ? iDPID : null, meta, cert, false);
      await Promise.all([patchSSO, patchIDP]);

      setEditModeOn(false);
      setSSOEnabled(null);
      getData();
    } catch (er) {
      console.log(er);
      setEditModeOn(false);
      getData();
    }
  };
  return (
    <>
      {userInfo?.info?.role?.toString() === "admin" ? (
        <>
          {!isPageLoading ? (
            <div className="sso-settings-container">
              <div className="header-container">
                <div className="header-block">
                  <div className="header">
                    <span>Single Sign-On</span>
                  </div>
                </div>
              </div>
              {!SSOSetup ? (
                <div className="sso__steps__block">
                  <div className="block">
                    <div className="steps__header__container">
                      <div className="steps__header">
                        <span>Step 1: Select Service Provider</span>
                      </div>
                      <div className="steps__subheader">
                        <span>{`Select your organization's Identity Provider`}</span>
                      </div>
                    </div>
                    <div className="sso__list__block">{IDPSetupView}</div>
                  </div>
                  <div className={`block ${stepsVisible ? "" : "hidden"}`}>
                    <div className="steps__header__container">
                      <div className="steps__header">
                        <span>Step 2: Upload SSO Configuration Files</span>
                      </div>
                      <div className="steps__subheader">
                        <span>
                          Upload the configuration files provided by your
                          Identity Provider after configuring SSO.
                        </span>
                      </div>
                    </div>
                    <div className="sso__subblock">
                      <div className="header__block">
                        <span>Meta file</span>
                        {/* <img src={info__dark} /> */}
                        {CustomTooltip(
                          "Metadata file with connection details",
                          <img className="info" src={info__dark} />,
                          10,
                          "info-tooltip display-flex",
                          "right"
                        )}
                      </div>
                      {!metaUploaded ? (
                        <div className={`upload__container`}>
                          <span className="upload__subheader">
                            XML file size no more than 2MB
                          </span>
                          <div className="input__container">
                            <input
                              type="file"
                              name="file"
                              className="custom-file-input sso__upload"
                              onChange={(e) => {
                                if (infoError["meta"] !== "") {
                                  setInfoError({ ...infoError, meta: "" });
                                }
                                changeHandler(e, "meta");
                              }}
                            />
                            {infoError.meta !== "" ? (
                              <div className="connection__fail">
                                <p>
                                  <img src={warning} alt="warning" />
                                  {infoError?.meta}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <div className="uploaded__container">
                          <img
                            className="align-self-center"
                            src={skeletn_img}
                          />
                          <span className="sso__filename align-self-center">
                            {metaFileName}
                          </span>
                          {/* <img className="align-self-center" src={ellipse_img} />

                      <span className="sso__filesize align-self-center">
                        {prettyPrintBytes(metaFile?.size)}
                      </span> */}
                          <button
                            className="delete__file align-self-center"
                            onClick={() => {
                              if (infoError["meta"] !== "") {
                                setInfoError({ ...infoError, meta: "" });
                              }
                              setMetaFile(null);
                              setMetaUploaded(false);
                            }}>
                            <span>x</span>
                          </button>
                        </div>
                      )}
                    </div>
                    <div className="sso__subblock">
                      <div className="header__block">
                        <span>Signed certificate file</span>
                        {/* <img src={info__dark} /> */}
                        {CustomTooltip(
                          "Signing Certificate File used by Identity Provider",
                          <img className="info" src={info__dark} />,
                          10,
                          "info-tooltip display-flex",
                          "right"
                        )}
                      </div>
                      {!certUploaded ? (
                        <div className={`upload__container`}>
                          <span className="upload__subheader">
                            CRT file size no more than 2MB
                          </span>
                          <div className="input__container">
                            <input
                              type="file"
                              name="file"
                              className="custom-file-input sso__upload"
                              onChange={(e) => {
                                if (infoError["cert"] !== "") {
                                  setInfoError({ ...infoError, cert: "" });
                                }
                                changeHandler(e, "cert");
                              }}
                            />
                            {infoError.cert !== "" ? (
                              <div className="connection__fail">
                                <p>
                                  <img src={warning} alt="warning" />
                                  {infoError.cert}
                                </p>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      ) : (
                        <div className="uploaded__container">
                          <img
                            src={skeletn_img}
                            className="align-self-center"
                          />
                          <span className="sso__filename">{certFileName}</span>
                          {/* <img src={ellipse_img} className="align-self-center" /> */}

                          {/* <span className="sso__filesize align-self-center">
                        {prettyPrintBytes(certFile?.size)}
                      </span> */}
                          <button
                            className="delete__file align-self-center"
                            onClick={() => {
                              if (infoError["cert"] !== "") {
                                setInfoError({ ...infoError, cert: "" });
                              }
                              setCertFile(null);
                              setCertUploaded(false);
                            }}>
                            <span>x</span>
                          </button>
                        </div>
                      )}
                    </div>
                    {/* <div className="sso__subblock"></div> */}
                  </div>
                  <div className={`block ${stepsVisible ? "" : "hidden"}`}>
                    <div className="steps__header__container">
                      <div className="steps__header">
                        <span>Step 3:</span>
                      </div>
                      <div className="steps__subheader">
                        <span>
                          Select if you wish to disable password login and
                          enable SSO only login
                        </span>
                      </div>
                    </div>
                    <div className="checkbox-container">
                      <input
                        type="checkbox"
                        className="form-check-input pwd__chkbx"
                        id={`disable__pwd__login_1`}
                        onChange={() => {
                          setDisablePwdLogin(!disablePwdLogin);
                        }}
                        checked={disablePwdLogin}
                      />
                      <label className="form-check-label">
                        Disable Password Login
                      </label>
                    </div>
                  </div>
                  <div
                    className={`block no-border ${
                      stepsVisible ? "" : "hidden"
                    } btn__parent flex-row`}>
                    <button
                      className="btn btn-primary save__config__btn"
                      onClick={() => {
                        updateSSOConfig("setup");
                      }}>
                      Enable SSO
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  {!editModeOn ? (
                    <div className="sso__banner__block">{idpEditView}</div>
                  ) : null}
                  {editModeOn ? (
                    <div className="sso__steps__block">
                      <div className={`block`}>
                        <div className="steps__header__container">
                          <div className="steps__header">
                            <span>Upload SSO Configuration Files</span>
                          </div>
                        </div>
                        <div className="sso__subblock">
                          <div className="header__block">
                            <span>Meta file</span>
                            {/* <img src={info__dark} /> */}
                            {CustomTooltip(
                              "Metadata file with connection details",
                              <img className="info" src={info__dark} />,
                              10,
                              "info-tooltip display-flex",
                              "right"
                            )}
                          </div>
                          {!metaUploaded ? (
                            <div className={`upload__container`}>
                              <span className="upload__subheader">
                                XML file size no more than 2MB
                              </span>
                              <div className="input__container">
                                <input
                                  type="file"
                                  name="file"
                                  className="custom-file-input sso__upload"
                                  onChange={(e) => {
                                    if (infoError["meta"] !== "") {
                                      setInfoError({ ...infoError, meta: "" });
                                    }
                                    changeHandler(e, "meta");
                                    setIsModified(true);
                                  }}
                                />
                                {infoError.meta !== "" ? (
                                  <div className="connection__fail">
                                    <p>
                                      <img src={warning} alt="warning" />
                                      {infoError.meta}
                                    </p>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <div className="uploaded__container">
                              <img
                                className="align-self-center"
                                src={skeletn_img}
                              />
                              <span className="sso__filename align-self-center">
                                {metaFileName}
                              </span>
                              {/* <img
                            className="align-self-center"
                            src={ellipse_img}
                          />

                          <span className="sso__filesize align-self-center">
                            {prettyPrintBytes(metaFile?.size)}
                          </span> */}
                              <button
                                className="delete__file align-self-center"
                                onClick={() => {
                                  if (infoError["meta"] !== "") {
                                    setInfoError({ ...infoError, meta: "" });
                                  }
                                  setMetaFile(null);
                                  setMetaUploaded(false);
                                }}>
                                <span>x</span>
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="sso__subblock">
                          <div className="header__block">
                            <span>Signed certificate file</span>
                            {/* <img src={info__dark} /> */}
                            {CustomTooltip(
                              "Signing Certificate File used by Identity Provider",
                              <img className="info" src={info__dark} />,
                              10,
                              "info-tooltip display-flex",
                              "right"
                            )}
                          </div>
                          {!certUploaded ? (
                            <div className={`upload__container`}>
                              <span className="upload__subheader">
                                CRT file size no more than 2MB
                              </span>
                              <div className="input__container">
                                <input
                                  type="file"
                                  name="file"
                                  className="custom-file-input sso__upload"
                                  onChange={(e) => {
                                    if (infoError["cert"] !== "") {
                                      setInfoError({ ...infoError, cert: "" });
                                    }
                                    changeHandler(e, "cert");
                                    setIsModified(true);
                                  }}
                                />
                                {infoError.cert !== "" ? (
                                  <div className="connection__fail">
                                    <p>
                                      <img src={warning} alt="warning" />
                                      {infoError.cert}
                                    </p>
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          ) : (
                            <div className="uploaded__container">
                              <img
                                src={skeletn_img}
                                className="align-self-center"
                              />
                              <span className="sso__filename">
                                {certFileName}
                              </span>
                              {/* <img
                            src={ellipse_img}
                            className="align-self-center"
                          />

                          <span className="sso__filesize align-self-center">
                            {prettyPrintBytes(certFile?.size)}
                          </span> */}
                              <button
                                className="delete__file align-self-center"
                                onClick={() => {
                                  if (infoError["cert"] !== "") {
                                    setInfoError({ ...infoError, cert: "" });
                                  }
                                  setCertFile(null);
                                  setCertUploaded(false);
                                }}>
                                <span>x</span>
                              </button>
                            </div>
                          )}
                        </div>
                        {/* <div className="sso__subblock"></div> */}
                      </div>
                      <div className={`block`}>
                        <div className="steps__header__container">
                          <div className="steps__header">
                            <span>Password</span>
                          </div>
                          <div className="steps__subheader">
                            <span>
                              Select if you wish to disable password login and
                              enable SSO only login
                            </span>
                          </div>
                        </div>
                        <div className="checkbox-container">
                          <input
                            type="checkbox"
                            className="form-check-input pwd__chkbx"
                            id={`disable__pwd__login_2`}
                            onChange={() => {
                              setDisablePwdLogin(!disablePwdLogin);
                              setIsModified(true);
                            }}
                            checked={disablePwdLogin}
                          />
                          <label className="form-check-label">
                            Disable Password Login
                          </label>
                        </div>
                      </div>
                      <div className={`block no-border btn__parent flex-row`}>
                        {ssoEnabled ? (
                          <button
                            className="btn btn-outline-danger disable__sso__btn"
                            onClick={() => {
                              handleDisable();
                            }}>
                            Disable SSO
                          </button>
                        ) : null}
                        <button
                          className={
                            !isModified
                              ? "btn btn-primary disabled__config__btn "
                              : "btn btn-primary save__config__btn"
                          }
                          onClick={() => {
                            updateSSOConfig("update");
                          }}>
                          Save Changes
                        </button>
                      </div>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          ) : null}
          {isPageLoading && (
            <div className="load">
              <div className="preload"></div>
            </div>
          )}
        </>
      ) : (
        <>
          <div className="billing__fallback">
            <div className="fallback__block">
              <div className="img__container">
                <img src={billing__fallback} />
              </div>
              <div className="fallback__heading">
                <div className="header">
                  <span>Access only available for administrators</span>
                </div>
                <div className="sub__header">
                  <span>
                    Please contact your admin to fix this issue or contact{`  `}
                    <a href="mailto:support@chaosgenius.io">
                      support@chaosgenius.io
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
SSOSettings.propTypes = {};
export default SSOSettings;
