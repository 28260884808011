import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./datasource.scss";
import GridComponent from "../../components/GridComponent";
import { createColDefs } from "../../utils/common-helper";
import { formatDateTime } from "../../utils/date-helper";
import { getCardItem } from "../../utils/cards-helper";
import { useNavigate } from "react-router-dom";
import plus_add from "../../assets/images/dashboard/plus_add.svg";
import { getSessionStorage } from "../../utils/storage-helper";

const DataSource = ({ activeModule, dwAccOptions }) => {
  const plan = getSessionStorage("plan_name").toLowerCase();
  const [hasTrialFallback] = useState(
    plan === "trial" || plan === "free" ? true : false
  );
  const univerSalStyle = {
    cellStyle: { borderRight: "1px solid #eaeaeb" }
  };
  const navigate = useNavigate();
  const gotoSelectConn = () => {
    navigate("../selectconnection");
  };
  const [customProps] = useState({
    display_name: {
      //width: 280,
      headerName: "Data Connection Name",
      cellClass: "grid-cell-left-aligned no-border"
    },
    setup_done: {
      width: 120,
      headerName: "Status",
      cellClass: "grid-cell-left-aligned no-border",
      cellRenderer: (params) => {
        return params?.value ? (
          <span style={{ color: "#60ca9a" }}>Live</span>
        ) : (
          <span style={{ color: "#fac06b" }}>Pending</span>
        );
      }
    },
    database: {
      //width: 220,
      cellClass: "grid-cell-left-aligned no-border",
      headerName: "Data Source Type",
      cellRenderer: (params) => {
        const item = getCardItem(params?.value, true);
        return (
          <div className="dt-img-text-container">
            <img src={item?.image} />
            <span>{params?.value}</span>
          </div>
        );
      }
    },
    last_sync_on: {
      //width: 280,
      headerName: "Last Synced",
      cellClass: "grid-cell-left-aligned no-border",
      cellRenderer: (params) => {
        const formattedDate = formatDateTime(params?.value, true, false);
        return <span>{formattedDate === "-" ? "" : formattedDate}</span>;
      }
    },
    created_on: {
      // width: 280,
      headerName: "Created On",
      cellClass: "grid-cell-left-aligned no-border",
      cellRenderer: (params) => {
        return <span>{formatDateTime(params?.value, true, false)}</span>;
      }
    }
  });
  //   const univerSalStyle = {
  //     cellStyle: { borderRight: "1px solid #eaeaeb" }
  //   };

  const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [gridFallback, setGridFallback] = useState(false);

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([]);
  const column_display_name_mappping = {
    display_name: "Data Connection Name",
    setup_done: "Status",
    database: "Data Source Type",
    last_sync_on: "Last Synced",
    created_on: "Created On"
  };

  const getTableData = async (optionsArr) => {
    try {
      if (optionsArr && optionsArr?.length) {
        if (optionsArr) {
          const columns = [
            "display_name",
            "setup_done",
            "database",
            "last_sync_on",
            "created_on"
          ];
          const colDefsArr = createColDefs(
            columns,
            customProps,
            univerSalStyle,
            column_display_name_mappping
          );
          if (colDefsArr && optionsArr) {
            setColumnDefs(colDefsArr);
            setRowData(optionsArr);
          }
        } else {
          clearData();
        }
        setGridLoading(false);
        if (gridFallback) {
          setGridFallback(false);
        }
      }
    } catch (err) {
      console.log(err);
      setGridLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
    }
  };

  const clearData = () => {
    setRowData([]);
  };

  const getData = async () => {
    try {
      if (dwAccOptions && dwAccOptions?.length) {
        getTableData(dwAccOptions);
        setGridLoading(false);
        setIsPageLoading(false);
      } else {
        clearData();
        setGridLoading(false);
        setIsPageLoading(false);
      }
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
      console.log(err);
    }
  };
  useEffect(() => {
    setIsPageLoading(true);
    setGridLoading(true);
    getData();
  }, []);

  return (
    <>
      <div className="view-ctrl-container">
        <div className="viewctrl-header-container">
          <div className="header-block">
            {/* {breadcrumbs ? (
              <div className="breadcrumbs-container">
                <Link to={`../`} style={linkStyle}>
                  Data Source
                </Link>
                <span>{` / ${`Add Data Source`}`}</span>
              </div>
            ) : null} */}
            <span>{activeModule}</span>
          </div>

          <button
            className={`btn btn-green-variant add__dt__src ${
              hasTrialFallback ? "disabled" : ""
            }`}
            disabled={hasTrialFallback}
            onClick={() => {
              gotoSelectConn();
            }}>
            {hasTrialFallback ? null : <img src={plus_add} />}
            <span>Add Data Source</span>
          </button>
        </div>
        <div className="ctrl-main-block">
          <div className="datasource-container">
            {!isPageLoading ? (
              <>
                <div className="grid-block">
                  <GridComponent
                    columnDefs={columnDefs}
                    rowData={rowData}
                    gridLoading={gridLoading}
                    gridFallback={gridFallback}
                    gridStyle={{
                      height: rowData && rowData.length ? 700 : 600,
                      noborder: true,
                      rowHeight: 46,
                      additionalClass: "cell-flex text-ellipse"
                    }}
                    suppressColumnsSize={true}
                    noRowsText={"No Data Sources Found"}
                  />
                </div>
              </>
            ) : null}
            {isPageLoading && (
              <div className="load">
                <div className="preload"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
DataSource.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  dwAccOptions: PropTypes.array,
  activeModule: PropTypes.string
};
export default DataSource;
