import React from "react";
import "./App.css";
import "./assets/styles/common.scss";
import AuthProvider from "./utils/authprovider";
import AppRoutes from "./routes";
import ChatwootWidget from "./components/ChatwootWidget";

function App() {
  const router = (
    <AuthProvider>
      <ChatwootWidget />
      <AppRoutes />
    </AuthProvider>
  );
  return <div className="App">{router}</div>;
}

export default App;
