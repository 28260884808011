//react imports
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Button from "../customElements/Button";
import plus from "../../assets/images/grid/plusRounded.svg";
import searchIcon from "../../assets/images/grid/searchIcon.svg";
import GridComponent from "../GridComponent";
import { InputAdornment, TextField } from "@mui/material";
import { getChargeBackTableProps } from "../../pages/Chargeback/ChargebackTableCols";
import DeleteIcon from "../customElements/DeleteIcon";
import {
  deleteCostCentre,
  getSearchResult
} from "../../utils/chargeback-helper";
import DeleteForm from "../CostReporting/DeleteForm";

function ChargebackTable({
  pageData,
  handleModal,
  getAllcostcenters,
  setIsPageLoading,
  changeActiveModule
}) {

  const [colDefs, setColdefs] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [gridData, setGridData] = useState([...pageData]);
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState(null);

  const actionCentre = [
    {
      width: 150,
      cellClass: "grid-cell-middle-aligned",
      field: "Status",
      filter: false,
      headerName: "Status",
      sortable: true,
      unSortIcon: true,
      cellRenderer: (params) => {
        return (
          <p className="status-chip-container">
            {params.value ? (
              <span className="live-chip">
                <span></span> Live
              </span>
            ) : (
              <span className="inprogress-chip">
                <span></span> Inprogress
              </span>
            )}
          </p>
        );
      }
    },
    {
      width: 80,
      cellClass: "grid-cell-middle-aligned",
      field: "Action",
      filter: false,
      headerName: "Action",
      sortable: true,
      unSortIcon: true,
      pinned: "right",
      cellRenderer: (params) => {
        return (
          <p
            className="delete-table-Icon"
            onClick={() => handleDeleteModal(params)}>
            <DeleteIcon />
          </p>
        );
      }
    }
  ];

  const handleDelete = async (params) => {
    try {
      await deleteCostCentre(params?.data?.id);
      setIsPageLoading(true);
      await getAllcostcenters();
    } catch (error) {
      console.log(error);
      setIsPageLoading(true);
      getAllcostcenters();
    }
  };

  const handleDeleteModal = (params) => {
    setShowModal(!showModal);
    setSelected(params);
  };

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value == "") {
      setGridData(pageData);
    } else {
      const result = getSearchResult(pageData, e.target.value);
      setGridData(result);
    }
  };
  useEffect(() => {
    let tableDefs = getChargeBackTableProps();
    setColdefs([...tableDefs, ...actionCentre]);
  }, []);

  return (
    <div className="cost-table-main-container">
      <div className="title-container">
        <p className="title">List Of Cost Centers</p>
        <div className="search-container">
          <TextField
            size="small"
            value={searchTerm}
            variant="outlined"
            placeholder="Search"
            className="search-bar"
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} alt="" />
                </InputAdornment>
              )
            }}
          />
          <Button onClick={handleModal} className={"create-cost-center"}>
            <img src={plus} alt="" />
            Create Cost Center
          </Button>
        </div>
      </div>
      <div className=" grid-container grid-block">
        <GridComponent
          columnDefs={colDefs}
          gridFallback={false}
          suppressColumnsSize={true}
          gridStyle={{
            height: 400
          }}
          changeActiveModule={changeActiveModule}
          rowData={gridData}
          gridLoading={false}
          noRowsText={"No data for the selected period."}
          customRowHeight={40}
        />
      </div>
      {showModal && (
        <DeleteForm
          isModalOpen={showModal}
          closeModal={handleDeleteModal}
          selected={selected}
          handleDeleteClick={handleDelete}
        />
      )}
    </div>
  );
}

// type declarations
ChargebackTable.propTypes = {
  pageData: PropTypes.array,
  handleModal: PropTypes.func,
  getAllcostcenters: PropTypes.func,
  setIsPageLoading: PropTypes.func,
  changeActiveModule: PropTypes.func
};
export default ChargebackTable;
