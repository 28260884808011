import React, { useState } from "react";
import PropTypes from "prop-types";
import eyeCon from "../../assets/images/common/eye.svg";
import eyeConSlash from "../../assets/images/common/eye-slash.svg";
import user_add_success from "../../assets/images/dashboard/user_add_success.svg";
import warning_delete from "../../assets/images/dashboard/warning_delete.svg";
import Select, { components } from "react-select";
import drpdwn from "../../assets/images/common/drpdwn_indicator.svg";
import "./adduserform.scss";
import { validateCompanyEmail, validateEmail } from "../../utils/validateemail";
import { validatePassword } from "../../utils/common-helper";
import { useAuth } from "../../utils/auth-helper";
const AddUserForm = ({
  closeModal,
  isModalOpen,
  addUserSettings,
  deleteUserSettings,
  editUserSettings,
  isLoading,
  changeIsLoading
}) => {
  const { userInfo } = useAuth();
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={drpdwn} />
      </components.DropdownIndicator>
    );
  };
  const [roleOptions] = useState(
    userInfo?.info?.role === "admin"
      ? [
          { label: "User", value: "User" },
          { label: "Admin", value: "Admin" }
        ]
      : [{ label: "User", value: "User" }]
  );
  const [fields, setFields] = useState({
    fname: "",
    lname: "",
    email: "",
    pword: "",
    role:
      isModalOpen?.action === "edit"
        ? roleOptions?.find((option) => {
            return (
              option?.value?.toLowerCase() === isModalOpen?.role?.toLowerCase()
            );
          })
        : { label: "User", value: "User" },
    auto_generate_pword: true
  });

  const [infoError, setInfoError] = useState({
    fname: "",
    lname: "",
    email: "",
    pword: ""
  });
  //const [addUserSuccessful] = useState(false);
  //const [isLoading, setIsLoading] = useState(false);
  const [eyeSlash, setEyeSlash] = useState(false);
  const [pwdType, setPwdType] = useState("password");
  const clearFields = () => {
    setFields({
      fname: "",
      lname: "",
      email: "",
      pword: "",
      role: { label: "User", value: "User" },
      auto_generate_pword: true
    });
  };
  const toggleSlash = () => {
    setEyeSlash(!eyeSlash);
    if (pwdType && pwdType === "password") {
      setPwdType("text");
    } else if (pwdType && pwdType === "text") {
      setPwdType("password");
    }
  };
  const handleOkayClick = () => {
    clearFields();
    closeModal();
  };
  const handleDeleteClick = async (id) => {
    if (isModalOpen && isModalOpen?.action === "delete") {
      deleteUserSettings(id);
      //changeIsLoading(false);
    }
  };
  const handleEditClick = async (id, role) => {
    if (isModalOpen && isModalOpen?.action === "edit") {
      editUserSettings(id, role);
      //clearFields();
      //changeIsLoading(false);
    }
  };
  const handleClick = () => {
    let errorObj = { ...infoError };
    if (fields.fname.trim() === "") {
      errorObj["fname"] = "Please enter first name";
    }
    if (fields.lname.trim() === "") {
      errorObj["lname"] = "Please enter last name";
    }
    if (fields.email.trim() === "") {
      errorObj["email"] = "Please add email";
    }
    if (fields.email.trim() !== "" && !validateEmail(fields.email.trim())) {
      errorObj["email"] = "Enter Valid Email";
    }
    if (
      fields.email.trim() !== "" &&
      validateCompanyEmail(fields.email.trim())
    ) {
      errorObj["email"] = "Please provide company email id";
    }
    // if (
    //   fields.email.trim() !== "" &&
    //   !validateEmailDomain(fields.email.trim(), userInfo?.info?.email.trim())
    // ) {
    //   errorObj["email"] = "Enter Valid Email with same domain as user";
    // }
    if (fields.pword.trim() === "" && fields?.auto_generate_pword === false) {
      errorObj["pword"] = "Please enter password";
    }
    if (
      !validatePassword(fields.pword.trim()) &&
      fields?.auto_generate_pword === false
    ) {
      errorObj["pword"] =
        "Password should be 6 - 15 characters with atleast one digit,one uppercase and one special character";
    }
    setInfoError(errorObj);
    if (
      errorObj.fname === "" &&
      errorObj.lname === "" &&
      errorObj.email === "" &&
      //errorObj.org === "" &&
      errorObj.pword === ""
    ) {
      changeIsLoading(true);
      addUserSettings(fields);
      //closeModal();
    }
  };
  const fieldList = [
    {
      id: "1",
      name: "fname",
      label: "First Name",
      type: "text",
      placeholder: "Enter First Name",
      value: fields.fname,
      isDisabled: false,
      onChange: (e) => {
        if (infoError["fname"] !== "") {
          setInfoError({ ...infoError, fname: "" });
        }
        setFields({ ...fields, fname: e.target.value });
      }
    },

    {
      id: "2",
      name: "lname",
      label: "Last Name",
      type: "text",
      placeholder: "Enter Last Name",
      isDisabled: false,
      //desc: "The role you created for Chaos Genius to access Snowflake.",
      value: fields.lname,
      onChange: (e) => {
        if (infoError["lname"] !== "") {
          setInfoError({ ...infoError, lname: "" });
        }
        setFields({ ...fields, lname: e.target.value });
      }
    },
    {
      id: "3",
      name: "role",
      label: "Role",
      type: "dropdown",
      placeholder: "Enter Password",
      options: roleOptions,
      value: fields.role,
      isDisabled: false,
      //desc: "Password associated with the username",
      onChange: (e) => {
        setFields({
          ...fields,
          role: e
        });
      }
    },
    {
      id: "4",
      name: "email",
      label: "Email",
      type: "text",
      placeholder: "Enter Email",
      value: fields.email,
      isDisabled: false,
      //desc: "The warehouse you created for Chaos Genius to access data into.",
      onChange: (e) => {
        if (infoError["email"] !== "") {
          setInfoError({ ...infoError, email: "" });
        }
        setFields({ ...fields, email: e.target.value });
      }
    },
    // {
    //   id: "4",
    //   name: "org",
    //   label: "Organization",
    //   type: "text",
    //   placeholder: "Enter Organization",
    //   value: fields.org,
    //   isDisabled: false,
    //   //desc: "Password associated with the username",
    //   onChange: (e) => {
    //     if (infoError["org"] !== "") {
    //       setInfoError({ ...infoError, org: "" });
    //     }
    //     setFields({ ...fields, org: e.target.value });
    //   }
    // },
    {
      id: "5",
      name: "pword",
      label: "Password",
      type: "password",
      placeholder: "Enter Password",
      hidden: true,
      value: fields.pword,
      isDisabled: false,
      //desc: "Password associated with the username",
      onChange: (e) => {
        if (infoError["pword"] !== "") {
          setInfoError({ ...infoError, pword: "" });
        }
        setFields({ ...fields, pword: e.target.value });
      }
    },

    {
      id: "6",
      name: "auto_generate_pword",
      label: "Auto Generate Password",
      type: "checkbox",
      //placeholder: "Enter Password",
      value: fields.auto_generate_pword,
      isDisabled: true,
      //desc: "Password associated with the username",
      onChange: (e) => {
        // if (infoError["pword"] !== "") {
        //   setInfoError({ ...infoError, pword: "" });
        // }
        setFields({
          ...fields,
          auto_generate_pword: e.target.checked
        });
      }
    }
  ];
  const editFields = [
    {
      id: "1",
      name: "name",
      label: "User Name",
      type: "text",
      placeholder: "Enter First Name",
      value: isModalOpen?.name,
      isDisabled: true
    },

    {
      id: "2",
      name: "email",
      label: "Email ID",
      type: "text",
      placeholder: "Enter Email",
      isDisabled: true,
      //desc: "The role you created for Chaos Genius to access Snowflake.",
      value: isModalOpen?.email
    },
    {
      id: "3",
      name: "role",
      label: "Role",
      type: "dropdown",
      options: roleOptions,
      // placeholder: "Enter Email",
      isDisabled: false,
      //desc: "The role you created for Chaos Genius to access Snowflake.",
      value: fields?.role,
      onChange: (e) => {
        setFields({
          ...fields,
          role: e
        });
      }
    }
  ];
  const editFieldView = editFields?.map((field) => {
    return (
      !field?.hidden && (
        <div className="form-group edit__form__group" key={field.id}>
          <div className="label-parent">
            {field?.type !== "checkbox" && !field?.hidden && (
              <div className="label-container edit__label">
                <label htmlFor={field.name}>{field.label}</label>
              </div>
            )}
            {field?.additionalInfo ? field?.additionalInfo : null}
          </div>

          {field.name === "pword" && !field?.hidden ? (
            <div className="cg-relative-position">
              <input
                type={pwdType}
                className="form-control"
                id={field.name}
                onChange={field.onChange}
                value={field.value}
                // aria-describedby="emailHelp"
                placeholder={field.placeholder}
              />
              {eyeSlash ? (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeCon}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              ) : (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeConSlash}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              )}
            </div>
          ) : field?.type === "checkbox" ? (
            <div className="checkbox-container">
              <input
                type={field.type}
                className="form-check-input"
                id={field.name}
                onChange={field.onChange}
                disabled={field?.isDisabled}
                value={field?.value}
                checked={field?.value}
              />
              <label className="form-check-label" htmlFor={field?.name}>
                {field?.label}
              </label>
            </div>
          ) : field?.type === "dropdown" ? (
            <div className="select-container edit__select">
              <Select
                value={fields?.role}
                options={field?.options}
                styles={customStyles}
                classNamePrefix="selectcategory"
                placeholder="select"
                isSearchable={false}
                components={{ DropdownIndicator }}
                onChange={field?.onChange}
              />
            </div>
          ) : (
            <input
              type={field.type}
              className={`form-control edit__field ${
                field?.isDisabled ? "disabled" : ""
              }`}
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              disabled={field?.isDisabled}
              // aria-describedby="emailHelp"
              placeholder={field.placeholder}
            />
          )}

          {infoError[`${field.name}`] !== "" ? (
            <div className="connection__fail">
              <p>{infoError[`${field.name}`]}</p>
            </div>
          ) : null}
        </div>
      )
    );
  });
  const fieldView = fieldList?.map((field) => {
    return (
      !field?.hidden && (
        <div className="form-group" key={field.id}>
          <div className="label-parent">
            {field?.type !== "checkbox" && !field?.hidden && (
              <div className="label-container">
                <label htmlFor={field.name}>{field.label}</label>
              </div>
            )}
            {field?.additionalInfo ? field?.additionalInfo : null}
          </div>

          {field.name === "pword" && !field?.hidden ? (
            <div className="cg-relative-position">
              <input
                type={pwdType}
                className="form-control"
                id={field.name}
                onChange={field.onChange}
                value={field.value}
                // aria-describedby="emailHelp"
                placeholder={field.placeholder}
              />
              {eyeSlash ? (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeCon}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              ) : (
                <img
                  className="cg-eye cg-absolute-position"
                  src={eyeConSlash}
                  aria-hidden="true"
                  onClick={() => {
                    toggleSlash();
                  }}
                />
              )}
            </div>
          ) : field?.type === "checkbox" ? (
            <div className="checkbox-container">
              <input
                type={field.type}
                className="form-check-input"
                id={field.name}
                onChange={field.onChange}
                disabled={field?.isDisabled}
                value={field?.value}
                checked={field?.value}
                // aria-describedby="emailHelp"
                //placeholder={field.placeholder}
              />
              <label className="form-check-label" htmlFor={field?.name}>
                {field?.label}
              </label>
            </div>
          ) : field?.type === "dropdown" ? (
            <div className="select-container">
              {/* <label className="label-container" htmlFor={field?.name}>
              {field?.label}
            </label> */}
              <Select
                value={fields?.role}
                options={field?.options}
                styles={customStyles}
                classNamePrefix="selectcategory"
                placeholder="select"
                isSearchable={false}
                components={{ DropdownIndicator }}
                onChange={field?.onChange}
              />
            </div>
          ) : (
            <input
              type={field.type}
              className="form-control"
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              disabled={field?.isDisabled}
              // aria-describedby="emailHelp"
              placeholder={field.placeholder}
            />
          )}

          {infoError[`${field.name}`] !== "" ? (
            <div className="connection__fail">
              <p>{infoError[`${field.name}`]}</p>
            </div>
          ) : null}
        </div>
      )
    );
  });

  return (
    <>
      <div className="modal-container add-user-modal"></div>
      <div className="form-container add-user-container">
        {isModalOpen &&
        isModalOpen?.status === true &&
        isModalOpen?.action === "delete" ? (
          <div className="form-parent">
            <div className="form-header no-border">
              <div className="header">
                <span></span>
              </div>
              <div
                className="close-img padding-right-16"
                onClick={() => {
                  clearFields();
                  closeModal(true);
                }}>
                <span>✕</span>
              </div>
            </div>
            <div className="delete-field-container">
              <div className="img-container">
                <img src={warning_delete} />
              </div>
              <div className="success-header-container">
                <div className="success-header">
                  <span>
                    {`Do you want to remove ${isModalOpen?.name} permanently?`}{" "}
                  </span>
                </div>
                {/* <div className="success-subheader">
                  <span>
                    An email has been sent to the user with Login details
                  </span>
                </div> */}
              </div>
            </div>
            <div className="button-container center-align button-delete">
              <button
                className="btn btn btn-dark medium-style"
                onClick={() => {
                  changeIsLoading(true);
                  handleDeleteClick(isModalOpen?.id);
                }}>
                Remove
              </button>
              <button
                onClick={() => {
                  clearFields();
                  closeModal(true);
                }}
                className="btn btn-outline-dark">
                Cancel
              </button>
            </div>
          </div>
        ) : isModalOpen && isModalOpen?.action === "edit" ? (
          <div className="form-parent">
            <div className="form-header">
              <div className="header">
                <span>Edit User</span>
              </div>
              <div
                className="close-img"
                onClick={() => {
                  clearFields();
                  closeModal(true);
                }}>
                <span>✕</span>
              </div>
            </div>
            <div className="field-container edit__field__container">
              {editFieldView}
            </div>
            <div className="button-container">
              <button
                className="btn btn btn-dark medium-style"
                onClick={() => {
                  changeIsLoading(true);
                  handleEditClick(isModalOpen?.id, fields?.role);
                }}>
                Save
              </button>
              <button
                onClick={() => {
                  clearFields();
                  closeModal(true);
                }}
                className="btn btn-outline-dark">
                Cancel
              </button>
            </div>
          </div>
        ) : isModalOpen && isModalOpen?.action === "add-user" ? (
          <div className="form-parent">
            <div className="form-header">
              <div className="header">
                <span>Add New User</span>
              </div>
              <div
                className="close-img"
                onClick={() => {
                  clearFields();
                  closeModal(true);
                }}>
                <span>✕</span>
              </div>
            </div>
            <div className="field-container">{fieldView}</div>
            <div className="button-container">
              <button
                className="btn btn btn-dark medium-style"
                onClick={handleClick}>
                Add User
              </button>
              <button
                onClick={() => {
                  clearFields();
                  closeModal(true);
                }}
                className="btn btn-outline-dark">
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div className="form-parent">
            <div className="form-header no-border">
              <div className="header">
                <span></span>
              </div>
              <div
                className="close-img"
                onClick={() => {
                  clearFields();
                  closeModal(true);
                }}>
                <span>✕</span>
              </div>
            </div>
            {/* <div className="field-container">{fieldView}</div> */}
            <div className="success-container">
              <div className="img-container">
                <img src={user_add_success} />
              </div>
              <div className="success-header-container">
                <div className="success-header">
                  <span>User Added Successfully</span>
                </div>
                <div className="success-subheader">
                  <span>
                    An email has been sent to the user with login details
                  </span>
                </div>
              </div>
            </div>
            <div className="button-container center-align">
              <button className="btn btn btn-dark" onClick={handleOkayClick}>
                Okay
              </button>
            </div>
          </div>
        )}
        {isLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
AddUserForm.propTypes = {
  closeModal: PropTypes.func,
  changeIsLoading: PropTypes.func,
  addUserSettings: PropTypes.func,
  deleteUserSettings: PropTypes.func,
  editUserSettings: PropTypes?.func,
  isModalOpen: PropTypes.object,
  isLoading: PropTypes.bool
};
export default AddUserForm;
