import { useContext } from "react";
import { AuthContext } from "./authprovider";
import { Auth } from "aws-amplify";
import { getLocalStorage, setSessinStorage } from "./storage-helper";
import { attachParams } from "./url-helper";
import { getRequest } from "./http-helper";
import * as Sentry from "@sentry/react";
import sentryEnabled from "../index";

export const useAuth = () => {
  return useContext(AuthContext);
};

export const getAccessToken = async () => {
  try {
    const currentSession = await Auth.currentSession();
    // const sessionToken = currentSession.getAccessToken().getJwtToken();
    return currentSession;
  } catch (err) {
    console.error(err);
    sentryEnabled && Sentry.captureException(err);
    return "";
  }
};

export const getWebClientID = (def) => {
  const lsWebCl = getLocalStorage("webclientID");
  if (lsWebCl) {
    return lsWebCl;
  }
  return def;
};

export const check_valid_sentry_url = (url) => {
  const _url = new URL(url);
  return (
    _url?.protocol === "https:" && _url?.host?.includes("ingest.sentry.io")
  );
};
export const getCurrentAppVersion = async () => {
  const URL = attachParams(`/version`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    setSessinStorage("version", JSON.stringify(data));
  }
};
