import React, { useState } from "react";
import PropTypes from "prop-types";
import "./teamalertsettings.scss";
import alert_settings_team from "../../assets/images/dashboard/alert_settings_team.svg";
import setup_succss_tick from "../../assets/images/dashboard/setup_succss_tick.png";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { useToast } from "react-toast-wnm";
import {
  //deleteSlackAlertAPI,
  setTeamsAlertAPI,
  testTeamsAlertAPI
} from "../../utils/settings-helper";
import back_arrow from "../../assets/images/common/back_arrow.svg";
import { useNavigate } from "react-router-dom";
import { check_valid_msteams_webhook_url } from "../../utils/alert-helper";

const TeamsAlertSettings = ({ changeTeamsAlertSetup, TeamsAlert }) => {
  const navigate = useNavigate();
  const [breadCrumbs] = useState(false);
  const [webhookURL, setWebhookURL] = useState(
    TeamsAlert && Object.keys(TeamsAlert).length && TeamsAlert?.url
      ? TeamsAlert?.url
      : ""
  );
  const [channelName, setChannelName] = useState(
    TeamsAlert && Object.keys(TeamsAlert).length && TeamsAlert?.channel_name
      ? TeamsAlert?.channel_name
      : ""
  );
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [setupSuccessful, setSetupSuccessful] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
    navigate("../settings/alerts");
  };
  const toast = useToast();
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };

  const [infoError, setInfoError] = useState({
    webhookURL: "",
    channelName: ""
  });

  const onSaveClick = () => {
    let errObj = { ...infoError };
    if (channelName?.trim() === "" || !channelName) {
      errObj["channelName"] = "Please add a channel name";
    }
    if (webhookURL?.trim() === "" || !webhookURL) {
      errObj["webhookURL"] = "Please add a proper url";
    }
    if (!check_valid_msteams_webhook_url(webhookURL?.trim())) {
      errObj["webhookURL"] = "Invalid Microsoft Teams Webhook URL provided";
    }
    setInfoError(errObj);
    if (errObj["webhookURL"] === "" && errObj["channelName"] === "") {
      setIsPageLoading(true);
      setUpTeamsAlert({
        url: webhookURL?.trim(),
        channel_name: channelName?.trim(),
        mode: TeamsAlert && Object.keys(TeamsAlert).length ? "edit" : "add"
      });
    }
  };

  const onTestClick = () => {
    let errObj = { ...infoError };
    if (webhookURL?.trim() === "" || !webhookURL) {
      errObj["webhookURL"] = "Please add a proper url";
    }
    if (!check_valid_msteams_webhook_url(webhookURL?.trim())) {
      errObj["webhookURL"] = "Invalid Microsoft Teams Webhook URL provided";
    }
    setInfoError(errObj);
    if (errObj["webhookURL"] === "") {
      setIsPageLoading(true);
      testTeamsAlert({
        url: webhookURL?.trim()
      });
    }
  };

  const testTeamsAlert = async (params) => {
    try {
      const status = await testTeamsAlertAPI(params);
      if (status) {
        customToast({
          type: "success",
          header: "",
          description: "Test message sent successfully"
        });
        setIsPageLoading(false);
      }
    } catch (err) {
      customToast({
        type: "error",
        header: "Error",
        description: err?.response?.data?.message
          ? err.response?.data?.message
          : "Test message sending failed"
      });
      setIsPageLoading(false);
    }
  };

  const handleBackClick = () => {
    navigate("../settings/alerts");
  };

  // const onDeleteClick = () => {
  //   setIsPageLoading(true);
  //   deleteSlackAlert();
  // };

  // const deleteSlackAlert = async () => {
  //   try {
  //     const status = await deleteSlackAlertAPI();
  //     if (status) {
  //       customToast({
  //         type: "success",
  //         header: "",
  //         description: "connection deleted"
  //       });
  //       setIsPageLoading(false);
  //     } else {
  //       //changeSlackAlertSetup(false)
  //       customToast({
  //         type: "error",
  //         header: "",
  //         description: "deletion failed"
  //       });
  //       setIsPageLoading(false);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     customToast({
  //       type: "error",
  //       header: "",
  //       description: "deletion failed"
  //     });
  //     setIsPageLoading(false);
  //   }
  // };

  const setUpTeamsAlert = async (params) => {
    try {
      const status = await setTeamsAlertAPI(params, customToast);
      if (status) {
        changeTeamsAlertSetup(true);
        setIsPageLoading(false);
        setSetupSuccessful(true);
        setIsModalOpen(true);
      } else {
        //changeSlackAlertSetup(false)
        setIsPageLoading(false);
        setSetupSuccessful(false);
      }
    } catch (err) {
      console.log(err);
      setIsPageLoading(false);
      setSetupSuccessful(false);
    }
  };

  return (
    <>
      {!isPageLoading && setupSuccessful && isModalOpen && (
        <div className="load settings-load">
          <div className="form-container alert-success-container">
            <div className="form-parent">
              <div className="form-header no-border">
                <div className="header">
                  <span></span>
                </div>
                <div
                  className="close-img padding-right-16"
                  onClick={() => {
                    // clearFields();
                    closeModal();
                  }}>
                  <span>✕</span>
                </div>
              </div>
              <div className="delete-field-container">
                <div className="img-container">
                  <img src={setup_succss_tick} />
                </div>
                <div className="success-header-container">
                  <div className="success-header">
                    <span>
                      You have successfully conected to Microsoft Teams
                    </span>
                  </div>
                  <div className="success-subheader">
                    <span>You will start receiving Alerts soon</span>
                  </div>
                </div>
              </div>
              {/* <div className="button-container center-align button-delete">
                <button
                  className="btn btn btn-dark medium-style"
                  onClick={() => {
                    // changeIsLoading(true);
                    // handleDeleteClick(isModalOpen?.id);
                  }}>
                  Add Alert
                </button>
              </div> */}
            </div>
          </div>
        </div>
      )}
      {isPageLoading && (
        <div className="load settings-load">
          <div className="preload"></div>
        </div>
      )}
      <div className="slack-settings-container">
        <div className="header-container">
          <div className="header-block">
            {breadCrumbs ? <div className="breadcrumbs-container"></div> : null}
            <div className="header">
              <div className="back_btn" onClick={handleBackClick}>
                <img
                  src={back_arrow}
                  alt="SF Optimization Onboarding back button"
                />
              </div>
              <span>Teams Setup</span>
            </div>
          </div>
        </div>
        <div className="settings-form-parent">
          <div className="settings-form-container">
            <div className="img-container">
              <img src={alert_settings_team} />
            </div>
            <div className="form-group">
              <label htmlFor={webhookURL}>Channel Name *</label>
              <input
                type="text"
                className="form-control"
                id="channelName"
                value={channelName}
                onChange={(e) => {
                  if (infoError["channelName"] !== "") {
                    setInfoError({ ...infoError, channelName: "" });
                  }
                  setChannelName(e.target.value);
                }}
                // aria-describedby="emailHelp"
                placeholder="Enter Channel Name"
              />
              {infoError.channelName !== "" ? (
                <div className="connection__fail">
                  <p>{infoError.channelName}</p>
                </div>
              ) : null}
            </div>
            <div className="form-group">
              <label htmlFor={webhookURL}>Webhook URL *</label>
              <input
                type="text"
                className="form-control"
                id="webhookURL"
                value={webhookURL}
                onChange={(e) => {
                  if (infoError["webhookURL"] !== "") {
                    setInfoError({ ...infoError, webhookURL: "" });
                  }
                  setWebhookURL(e.target.value);
                }}
                // aria-describedby="emailHelp"
                placeholder="Enter Webhook URL"
              />
              {infoError.webhookURL !== "" ? (
                <div className="connection__fail">
                  <p>{infoError.webhookURL}</p>
                </div>
              ) : null}
            </div>
            <div className="btn-container">
              <button
                className={
                  webhookURL === "" || !webhookURL
                    ? "btn btn-outline-dark disabled test-connection"
                    : "btn btn-outline-dark test-connection"
                }
                onClick={onTestClick}>
                ▶ Test Alert Message
              </button>
              <button
                className={
                  webhookURL === "" ||
                  !webhookURL ||
                  channelName === "" ||
                  !channelName
                    ? "btn btn-dark disabled"
                    : "btn btn-dark"
                }
                onClick={onSaveClick}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
TeamsAlertSettings.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  changeSlackAlertSetup: PropTypes.func,
  slackAlert: PropTypes.object,
  changeTeamsAlertSetup: PropTypes.func,
  TeamsAlert: PropTypes.object
};
export default TeamsAlertSettings;
