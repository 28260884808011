import React from "react";
import ToastSuccess from "../assets/images/toast/toast-success.svg";
import ToastFailed from "../assets/images/toast/toast-error.svg";
import Close from "../assets/images/close.svg";
import PropTypes from "prop-types";

const CustomContent = ({ header, description, failed }) => (
  <div className="toaster-popup">
    {failed === true ? (
      <div className="toaster-title">
        {header && (
          <>
            <img src={ToastFailed} alt="Failed" />
            <h3>{header}</h3>
          </>
        )}
      </div>
    ) : (
      <div className="toaster-title">
        {header && (
          <>
            <img src={ToastSuccess} alt="Success" />
            <h3>{header}</h3>
          </>
        )}
      </div>
    )}
    {description && <div className="toaster-description">{description}</div>}
  </div>
);

const CustomActions = ({ closeToast }) => (
  <img
    src={Close}
    alt="Close"
    onClick={closeToast}
    className="toaster-popup-close"
  />
);

export const getBasicToastSettings = (type, header, description) => {
  return {
    autoDismiss: true,
    enableAnimation: true,
    delay: type === "success" ? "8000" : "8000",
    backgroundColor: type === "success" ? "#effaf5" : "#FEF6F5",
    borderRadius: "6px",
    color: "#222222",
    position: "bottom-right",
    minWidth: "240px",
    width: "auto",
    boxShadow: "4px 6px 32px -2px rgba(226, 226, 234, 0.24)",
    padding: "17px 14px",
    height: "auto",
    border: type === "success" ? "1px solid #60ca9a" : "1px solid #FEF6F5",
    type: type,
    actions: <CustomActions />,
    content: (
      <CustomContent
        header={header}
        description={description}
        failed={type === "success" ? false : true}
      />
    )
  };
};

CustomContent.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
  failed: PropTypes.bool
};

CustomActions.propTypes = {
  closeToast: PropTypes.func
};
