import React, { useEffect } from "react";
import "./billingsuccess.scss";
// import { useAuth } from "../../utils/auth-helper";
// import { roundToSigDigits } from "../../utils/common-helper";

const BillingSuccess = () => {
  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "/dashboard/settings/billing";
    }, 5 * 1000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <div className="billing-success-container">
        <span className="green-header">
          Successfully updated your billing information.
        </span>
        <span>
          You will be redirected back to the dashboard in 5 seconds. If you are
          not redirected, please click{" "}
          <a href="/dashboard/settings/billing">here</a>.
        </span>
      </div>
    </>
  );
};
BillingSuccess.propTypes = {};
export default BillingSuccess;
