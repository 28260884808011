import { attachParams } from "./url-helper";
import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest
} from "./http-helper";
export const getPlansList = async () => {
  const URL = attachParams(`/v1/account/plans`);
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getUsageDetails = async () => {
  const URL = attachParams(`/v1/account/usage-limits`);
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getManageBillingInfo = async (fields = {}) => {
  const URL = attachParams(`/v1/account/manage-billing-stripe`, fields);
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const subscribeToNewPlan = async ({ plan_id, succ_page, fail_page }) => {
  const params = {
    plan_id: plan_id,
    success_page_redirect_link: succ_page,
    failure_page_redirect_link: fail_page
  };
  const URL = attachParams(`/v1/account/plans`, params);
  const { data, error } = await postRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const redeemVoucherCode = async ({ coupon_code }) => {
  const params = {
    coupon_code
  };
  const URL = attachParams(`/v1/account/redeem-coupon`, params);
  const { data, error } = await postRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const switchToNewPlan = async ({ plan_id, succ_page, fail_page }) => {
  const params = {
    plan_id: plan_id,
    success_page_redirect_link: succ_page,
    failure_page_redirect_link: fail_page
  };
  const URL = attachParams(`/v1/account/plans`, params);
  console.log(plan_id, succ_page, fail_page);
  const { data, error } = await patchRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const unsubscribePlan = async () => {
  const URL = attachParams(`/v1/account/plans`);
  const { data, error } = await deleteRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};
