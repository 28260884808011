import React from "react";
import Tooltip from "react-tooltip-lite";

export const CustomTooltip = (
  content,
  text,
  length = 10,
  _className,
  _direction,
  _component
) => {
  if (text && text.length > length) {
    return (
      <>
        <Tooltip
          className={_className ? _className : "tooltip-name"}
          direction={_direction ? _direction : "left"}
          content={<span> {content}</span>}
          distance={0}>
          {text}
        </Tooltip>
        {_component && _component}
      </>
    );
  } else if (text && text.length > 30) {
    return (
      <>
        <Tooltip
          className={_className ? _className : "tooltip-name"}
          direction={_direction ? _direction : "left"}
          content={<span> {content}</span>}
          distance={0}>
          {text}
        </Tooltip>
        {_component && _component}
      </>
    );
  } else {
    return (
      <>
        <Tooltip
          className={_className ? _className : "tooltip-name"}
          direction={_direction ? _direction : "left"}
          content={<span> {content}</span>}
          distance={0}>
          {text}
        </Tooltip>
        {_component && _component}
      </>
    );
  }
};

export const CustomTooltipRev = (
  content,
  text,
  length = 10,
  _className,
  _direction,
  _component
) => {
  if (text && text.length > length) {
    return (
      <>
        <Tooltip
          className={_className ? _className : "tooltip-name"}
          direction={_direction ? _direction : "left"}
          content={<span> {text}</span>}>
          {content}
        </Tooltip>
        {_component && _component}
      </>
    );
  } else if (text && text.length > 30) {
    return (
      <>
        {" "}
        <Tooltip
          className={_className ? _className : "tooltip-name"}
          direction={_direction ? _direction : "left"}
          content={<span> {text}</span>}>
          {content}
        </Tooltip>
        {_component && _component}
      </>
    );
  } else {
    return (
      <>
        <Tooltip
          className={_className ? _className : "tooltip-name"}
          direction={_direction ? _direction : "left"}
          content={<span> {text}</span>}>
          {content}
        </Tooltip>
        {_component && _component}
      </>
    );
  }
};
