import { CustomTooltip } from "../../utils/tooltip-helper";
import {
  CapitalizeText,
  CustomListForKeyValue,
  CustomListForKeyValuePlusHeader
} from "../../utils/changelog-helper";

const customPropsWh = {
  Timestamp: {
    width: 170,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CustomTooltip(params.value, params.value, 10);
    }
  },
  "Event Type": {
    width: 160,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CapitalizeText(params?.value);
    }
  },
  "Warehouse Name": {
    width: 220,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CustomTooltip(params.value, params.value, 10);
    }
  },
  "Event Name": {
    width: 280,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CapitalizeText(params?.value);
    }
  },
  "Object Details": {
    width: 400,
    cellClass: "grid-cell-left-aligned",
    autoHeight: true,
    wrapText: true,
    cellRenderer: (params) => {
      // return CustomTooltip(params.value, params.value, 10);
      return CustomListForKeyValuePlusHeader(params?.value);
    }
  },
  Name: {
    width: 280,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CustomTooltip(params.value, params.value, 10);
    }
  },
  "Owner (Role)": {
    width: 180,
    // flex: 1,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CustomTooltip(params.value, params.value, 10);
    }
  },
  "User Name": {
    width: 200,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CustomTooltip(params.value, params.value, 10);
    }
  },
  Details: {
    autoHeight: true,
    cellClass: "grid-cell-left-aligned",
    sortable: false,
    //width: 300,
    wrapText: true,
    flex: 1,
    cellRenderer: (params) => {
      return CustomListForKeyValue(params?.value);
    }
  }
};

export const getChangeLogWhProps = () => {
  return customPropsWh;
};

const customPropsUser = {
  Timestamp: {
    flex: 1,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CustomTooltip(params.value, params.value, 10);
    }
  },
  "Event Type": {
    flex: 1,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CapitalizeText(params?.value);
    }
  },
  "Warehouse Name": {
    width: 170,
    cellClass: "grid-cell-left-aligned"
  },
  "Event Name": {
    width: 280,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CapitalizeText(params?.value);
    }
  },
  "Object Details": {
    flex: 1,
    cellClass: "grid-cell-left-aligned",
    autoHeight: true,
    cellRenderer: (params) => {
      return CustomListForKeyValuePlusHeader(params?.value);
    }
  },
  Name: {
    width: 280,
    cellClass: "grid-cell-left-aligned"
  },
  "Owner (Role)": {
    flex: 1,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CustomTooltip(params.value, params.value, 10);
    }
  },
  "User Name": {
    width: 150,
    cellClass: "grid-cell-left-aligned"
  },
  Details: {
    autoHeight: true,
    cellClass: "grid-cell-left-aligned",
    sortable: false,
    flex: 1,
    cellRenderer: (params) => {
      return CustomListForKeyValue(params?.value);
    }
  }
};

export const getChangeLogUserProps = () => {
  return customPropsUser;
};
