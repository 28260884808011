import { getTimezone } from "../../utils/date-helper";

export const color__pallete = {
  neutral: [
    "#3182BD",
    "#6BAED6",
    "#9ECAE1",
    "#C6DBEF",
    "#E6550D",
    "#FD8D3C",
    "#FDAE6B",
    "#FDD0A2",
    "#31A354",
    "#74C476",
    "#A2DA9C",
    "#C7E9C0",
    "#756BB1",
    "9E9ACB",
    "#BCBDDC",
    "#DADAEB",
    "#DADAEB",
    "#969696",
    "#BDBDBD",
    "#D9D9D9"
  ]
};

const globalHighChartOptions = {
  chart: { height: 600, zoomType: "x,y", margin: [60, 50, 90, 50] },
  title: {
    text: "Chart Title",
    style: {
      color: "#222222",
      fontSize: "16px",
      lineHeight: "26px",
      letterSpacing: "0.2px",
      fontFamily: "Inter-Medium"
    },
    margin: 32
  },

  time: {
    timezone: getTimezone()
  },
  xAxis: {},
  yAxis: [],
  tooltip: {
    // backgroundColor: "#222222",
    // borderColor: "#222222",
    // style: {
    //   color: "#ffffff"
    // }
    crosshairs: true,
    shared: true,
    valueSuffix: null,
    animation: true
  },
  legend: {
    layout: "horizontal",
    align: "center",
    verticalAlign: "bottom",
    borderColor: "#E1E1E1",
    borderWidth: 0,
    floating: true,
    //borderRadius: 6,
    //x: 5,
    y: 20,
    itemStyle: {
      fontSize: "12px",
      color: "#222222",
      //fontWeight: 00,
      fontFamily: "Inter-Semibold",
      lineHeight: "16.94px"
    },
    itemHoverStyle: {
      color: "#72737a"
    }
    //itemMarginTop: 4,
    //itemMarginBottom: 4
  },
  credits: {
    enabled: false
  },
  series: []
};
export const getChartGlobalConfig = () => {
  return globalHighChartOptions;
};

export const getChartGlobalStyle = (options) => {
  return {
    width: options?.chart?.width ? options?.chart?.width : "100%",
    height: options?.chart?.height ? options?.chart?.height : 500
  };
};
