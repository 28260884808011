import { deleteRequest, getRequest, postRequest } from "./http-helper";
import { attachParams } from "./url-helper";
import _ from "lodash";

export const getOptions = (arr) => {
  const options = arr?.map((opt) => {
    return {
      value: opt,
      label: opt
    };
  });
  return options;
};

export const validateButton = (data) => {
  let flag = false;

  for (let i = 0; i < data?.length; i++) {
    if (data?.[i]?.user.length <= 0 || data?.[i]?.warehouse?.length <= 0) {
      flag = true;
      break;
    }
  }
  return flag;
};

export const checkResources = (resources) => {
  let finalData = [];
  resources.map((item) => {
    if (item.user.length === 0 && item.wearehouse.length === 0) {
      finalData.push({
        data_wh_id: item?.account?.data_warehouse_info_id,
        resource_type: "sf_account",
        resource_list: []
      });
    } else {
      if (checkAllSelected(item?.user) || checkAllSelected(item?.wearehouse)) {
        finalData.push({
          data_wh_id: item?.account?.data_warehouse_info_id,
          resource_type: "sf_account",
          resource_list: []
        });
      } else {
        if (item.user.length > 0) {
          const wh_list = item?.user?.map((element) => element.value);
          finalData.push({
            data_wh_id: item?.account?.data_warehouse_info_id,
            resource_type: "user",
            resource_list: wh_list?.filter(
              (element) => element !== "Select All"
            )
          });
        }
        if (item.wearehouse.length > 0) {
          const user_list = item?.wearehouse?.map((element) => element.value);
          finalData.push({
            data_wh_id: item?.account?.data_warehouse_info_id,
            resource_type: "warehouse",
            resource_list: user_list?.filter(
              (element) => element !== "Select All"
            )
          });
        }
      }
    }
  });
  const uniqueObjects = _.uniqWith(finalData, _.isEqual);
  return uniqueObjects;
};

export const removeDropdown = (dwAccOptions, resources) => {
  const displayNamesSet = new Set(
    resources.map((item) => item?.account?.display_name)
  );
  const filteredArray2 = dwAccOptions?.filter(
    (item) => !displayNamesSet.has(item?.display_name)
  );
  return filteredArray2;
};

const convertToTableFormat = (data) => {
  const tableFormat = data?.map((item, index) => {
    let whNames;
    if (item?.data_wh_names.length > 0) {
      whNames = _.map(item?.data_wh_names, (name) => name).join(", ");
    } else {
      whNames = "-";
    }
    let usersList = item?.resources.filter(
      (val) => val?.resource_type === "user"
    );
    const concatenatedResourceList = _.chain(usersList)
      .map("resource_list")
      .flatten()
      .uniq()
      .join(", ")
      .value();
    let whList = item?.resources.filter(
      (val) => val?.resource_type === "warehouse"
    );
    const concatenatedWHList = _.chain(whList)
      .map("resource_list")
      .flatten()
      .uniq()
      .join(", ")
      .value();
    return {
      id: item?.cost_center_id,
      S_No: index + 1,
      "Cost Center Name": item?.name,
      Account: whNames,
      User: concatenatedResourceList ?? "-",
      Warehouse: concatenatedWHList ?? "-",
      Owner: item.owner,
      Status: item.cost_center_ready
    };
  });
  return tableFormat;
};

export const getAccountDropdowns = async () => {
  const URL = attachParams(`/v2/cost_center/config/data_wh_info`);
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    const options = getAccOptions(data);
    return options;
  }
};

export const getcostcentres = async () => {
  const URL = attachParams(`/v2/cost_center/config`);
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    const convertedData = convertToTableFormat(data?.cost_centers);
    return convertedData;
  }
};

export const getUserDropdownsAPI = async (accID) => {
  const params = { data_wh_id: accID, date_range: "September 2023" };
  const URL = attachParams(
    `/v2/cost_center/config/org_resources/users`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    const options = getOptions(data?.resource_names);
    return options;
  }
};

export const checkAllSelected = (data) => {
  let flag = false;

  for (let i = 0; i < data?.length; i++) {
    if (data?.[i].value === "Select All") {
      flag = true;
      break;
    }
  }
  return flag;
};
export const getWHDropdownsAPI = async (accID) => {
  const params = { data_wh_id: accID, date_range: "September 2023" };
  const URL = attachParams(
    `/v2/cost_center/config/org_resources/warehouses`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    const options = getOptions(data?.resource_names);
    return options;
  }
};

export const postNewCostCentre = async (payload) => {
  const URL = attachParams(`/v2/cost_center/config`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(payload),
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const deleteCostCentre = async (id) => {
  const URL = attachParams(`/v2/cost_center/config/${id}`);
  const { data, error } = await deleteRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getSearchResult = (data, searchTerm) => {
  const searchResult = data.filter(
    (item) =>
      item?.Owner?.toUpperCase().includes(searchTerm.toUpperCase()) ||
      item?.["Cost Center Name"]
        ?.toUpperCase()
        .includes(searchTerm.toUpperCase())
  );

  return searchResult;
};

const getAccOptions = (arr) => {
  const options = arr?.map((opt) => {
    return {
      id: opt?.data_warehouse_info_id,
      value: opt?.display_name,
      label: opt?.display_name,
      ...opt
    };
  });
  return options;
};
