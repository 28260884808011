import React, { useState } from "react";
import PropTypes from "prop-types";
import eyeCon from "../../assets/images/common/eye.svg";
import eyeConSlash from "../../assets/images/common/eye-slash.svg";
import info_img from "../../assets/images/common/info.svg";
import external_link from "../../assets/images/common/external_link.svg";
import "./connectionform.scss";
import { CustomTooltip } from "../../utils/tooltip-helper";
const ConnectionForm = ({
  connection,
  closeModal,
  setupConnection,
  changeIsLoading
}) => {
  const [fields, setFields] = useState({
    accname: "",
    conname: "",
    role: "CHAOSGENIUS_ROLE",
    warehouse: "CHAOSGENIUS_WH",
    database: "SNOWFLAKE",
    username: "CHAOSGENIUS",
    pword: ""
  });
  const [infoError, setInfoError] = useState({
    accname: "",
    conname: "",
    role: "",
    warehouse: "",
    database: "",
    username: "",
    pword: ""
  });
  const [eyeSlash, setEyeSlash] = useState(false);
  const [pwdType, setPwdType] = useState("password");
  const clearFields = () => {
    setFields({
      accname: "",
      conname: "",
      role: "CHAOSGENIUS_ROLE",
      warehouse: "CHAOSGENIUS_WH",
      database: "SNOWFLAKE",
      username: "CHAOSGENIUS",
      pword: ""
    });
  };
  const handleClick = () => {
    let errorObj = { ...infoError };
    if (fields.accname === "") {
      errorObj["accname"] = "Please enter account identifier";
    }
    if (fields.conname === "") {
      errorObj["conname"] = "Please enter connection name";
    }
    if (fields.role === "") {
      errorObj["role"] = "Please enter role";
    }
    if (fields.warehouse === "") {
      errorObj["warehouse"] = "Please enter warehouse";
    }
    if (fields.database === "") {
      errorObj["database"] = "Please enter database";
    }
    if (fields.username === "") {
      errorObj["username"] = "Please enter username";
    }
    if (fields.pword === "") {
      errorObj["pword"] = "Please enter password";
    }
    setInfoError(errorObj);
    if (
      errorObj.accname === "" &&
      errorObj.conname === "" &&
      errorObj.role === "" &&
      errorObj.warehouse === "" &&
      errorObj.username === "" &&
      errorObj.database === "" &&
      errorObj.pword === ""
    ) {
      changeIsLoading(true);
      setupConnection(fields);
      closeModal();
    }
  };
  const fieldList = [
    {
      id: "1",
      name: "conname",
      label: "Connection Name",
      type: "text",
      placeholder: "Enter Connection Name",
      value: fields.conname,
      isDisabled: false,
      onChange: (e) => {
        if (infoError["conname"] !== "") {
          setInfoError({ ...infoError, conname: "" });
        }
        setFields({ ...fields, conname: e.target.value });
      }
    },
    {
      id: "2",
      name: "accname",
      label: "Account Identifier",
      type: "text",
      placeholder: "Enter Account Identifier",
      value: fields.accname,
      isDisabled: false,
      desc: "Account Identifier of the snowflake instance (might include account, region, cloud environment).",
      additionalInfo: (
        <a
          target="_blank"
          rel="noreferrer"
          href="https://docs.snowflake.com/en/user-guide/admin-account-identifier.html">
          Read More <img src={external_link} />
        </a>
      ),
      onChange: (e) => {
        if (infoError["accname"] !== "") {
          setInfoError({ ...infoError, accname: "" });
        }
        setFields({ ...fields, accname: e.target.value });
      }
    },
    {
      id: "3",
      name: "role",
      label: "Role",
      type: "text",
      placeholder: "Enter Role",
      isDisabled: false,
      desc: "The role you created for Chaos Genius to access Snowflake.",
      value: fields.role,
      onChange: (e) => {
        if (infoError["role"] !== "") {
          setInfoError({ ...infoError, role: "" });
        }
        setFields({ ...fields, role: e.target.value });
      }
    },
    {
      id: "4",
      name: "warehouse",
      label: "Warehouse",
      type: "text",
      placeholder: "Enter Warehouse",
      value: fields.warehouse,
      isDisabled: false,
      desc: "The warehouse you created for Chaos Genius to access data into.",
      onChange: (e) => {
        if (infoError["warehouse"] !== "") {
          setInfoError({ ...infoError, warehouse: "" });
        }
        setFields({ ...fields, warehouse: e.target.value });
      }
    },
    {
      id: "6",
      name: "username",
      label: "User name",
      type: "text",
      placeholder: "Enter User name",
      value: fields.username,
      isDisabled: false,
      desc: "The username you created to allow Chaos Genius to access the database.",
      onChange: (e) => {
        if (infoError["username"] !== "") {
          setInfoError({ ...infoError, username: "" });
        }
        setFields({ ...fields, username: e.target.value });
      }
    },
    {
      id: "7",
      name: "pword",
      label: "Password",
      type: "password",
      placeholder: "Enter Password",
      value: fields.pword,
      isDisabled: false,
      desc: "Password associated with the username",
      onChange: (e) => {
        if (infoError["pword"] !== "") {
          setInfoError({ ...infoError, pword: "" });
        }
        setFields({ ...fields, pword: e.target.value });
      }
    },
    {
      id: "5",
      name: "database",
      label: "Database",
      type: "text",
      placeholder: "Enter Database",
      isDisabled: true,
      value: fields.database,
      desc: "Snowflake Meta Information database having the account usage details.Keep this unchanged unless you are creating replicated database from snowflake shared database.",
      onChange: (e) => {
        if (infoError["database"] !== "") {
          setInfoError({ ...infoError, database: "" });
        }
        setFields({ ...fields, database: e.target.value });
      }
    }
  ];
  const fieldView = fieldList?.map((field) => {
    return (
      <div className="form-group" key={field.id}>
        <div className="label-parent">
          <div className="label-container">
            <label htmlFor={field.name}>{field.label}</label>
            {field?.desc ? (
              <div className="img-container">
                {CustomTooltip(
                  field?.desc,
                  <img className="info" src={info_img} />,
                  10,
                  "info-tooltip",
                  "right"
                )}
              </div>
            ) : null}
          </div>
          {field?.additionalInfo ? field?.additionalInfo : null}
        </div>
        {field.id === "7" ? (
          <div className="cg-relative-position">
            <input
              type={pwdType}
              className="form-control"
              id={field.name}
              onChange={field.onChange}
              value={field.value}
              // aria-describedby="emailHelp"
              placeholder={field.placeholder}
            />
            {eyeSlash ? (
              <img
                className="cg-eye cg-absolute-position"
                src={eyeCon}
                aria-hidden="true"
                onClick={() => {
                  toggleSlash();
                }}
              />
            ) : (
              <img
                className="cg-eye cg-absolute-position"
                src={eyeConSlash}
                aria-hidden="true"
                onClick={() => {
                  toggleSlash();
                }}
              />
            )}
          </div>
        ) : (
          <input
            type={field.type}
            className="form-control"
            id={field.name}
            onChange={field.onChange}
            value={field.value}
            disabled={field?.isDisabled}
            // aria-describedby="emailHelp"
            placeholder={field.placeholder}
          />
        )}
        {infoError[`${field.name}`] !== "" ? (
          <div className="connection__fail">
            <p>{infoError[`${field.name}`]}</p>
          </div>
        ) : null}
      </div>
    );
  });

  const toggleSlash = () => {
    setEyeSlash(!eyeSlash);
    if (pwdType && pwdType === "password") {
      setPwdType("text");
    } else if (pwdType && pwdType === "text") {
      setPwdType("password");
    }
  };

  return (
    <>
      <div className="modal-container"></div>
      <div className="form-container">
        {/* <div className="form-parent">
          <div className="form-header">
            <div className="header">
              <span>New {connection.name} Integration</span>
            </div>
            <div
              className="close-img"
              onClick={() => {
                clearFields();
                closeModal(true);
              }}>
              <span>✕</span>
            </div>
          </div>
          <div className="field-container">{fieldView}</div>
          <div className="button-container">
            <button className="btn btn btn-dark" onClick={handleClick}>
              Connect Account
            </button>
            <button
              onClick={() => {
                clearFields();
                closeModal(true);
              }}
              className="btn btn-outline-dark">
              Cancel
            </button>
          </div>
        </div> */}
        <div className="form-parent">
          <div className="form-header">
            <div className="header">
              <span>New {connection.name} Integration</span>
            </div>
            <div
              className="close-img"
              onClick={() => {
                clearFields();
                closeModal(true);
              }}>
              <span>✕</span>
            </div>
          </div>
          <div className="field-container">{fieldView}</div>
          <div className="button-container">
            <button className="btn btn btn-dark" onClick={handleClick}>
              Connect Account
            </button>
            <button
              onClick={() => {
                clearFields();
                closeModal(true);
              }}
              className="btn btn-outline-dark">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};
ConnectionForm.propTypes = {
  connection: PropTypes.object,
  closeModal: PropTypes.func,
  setupConnection: PropTypes.func,
  changeIsLoading: PropTypes.func
};
export default ConnectionForm;
