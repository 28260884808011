import React, { useState } from "react";
import PropTypes from "prop-types";
import "./zoomcomponent.scss";
import SVG from "react-inlinesvg";
import panzoom from "panzoom";
import zoom_in from "../../assets/images/dashboard/Minus.svg";
import zoom_out from "../../assets/images/dashboard/Plus.svg";

const ZoomComponent = ({ pan_zoom_ref, src, title, zoomOptions }) => {
  const [panzoomInst, setPanZoominst] = useState(null);
  function zoom(controller, scale, isSmooth, ref) {
    if (scale) {
      const computedWidth = getComputedStyle(ref)
        .getPropertyValue("width")
        .replace("px", "");
      const computedHeight = getComputedStyle(ref)
        .getPropertyValue("height")
        .replace("px", "");
      if (isSmooth) {
        controller.smoothZoom(
          Number(computedWidth) / 2,
          Number(computedHeight) / 2,
          scale
        );
      } else {
        controller.zoomTo(
          Number(computedWidth) / 2,
          Number(computedHeight) / 2,
          scale
        );
      }
    }
  }
  return (
    <>
      <div className="absolute-toolbox">
        <div className="tool-parent">
          <div
            className="tool"
            onClick={() => {
              panzoomInst?.zoomAbs(0, 0, 1);
              panzoomInst?.moveTo(0, 0);
            }}>
            <span>reset</span>
          </div>
          <div
            className="tool"
            onClick={() => {
              zoom(panzoomInst, 2, true, pan_zoom_ref?.current);
            }}>
            <SVG src={zoom_out} />
          </div>
          <div
            className="tool"
            onClick={() => {
              zoom(panzoomInst, 0.5, true, pan_zoom_ref?.current);
            }}>
            <SVG src={zoom_in} />
          </div>
        </div>
        <div className="tooltext-container">
          <span>or Scroll to zoom</span>
        </div>
      </div>
      <div className="raw-div">
        <SVG
          innerRef={pan_zoom_ref ? pan_zoom_ref : undefined}
          src={src ? src : ""}
          title={title ? title : "Container Title"}
          onLoad={() => {
            const inst = panzoom(pan_zoom_ref.current, {
              beforeMouseDown: function (e) {
                // allow mouse-down panning only if altKey is not down. Otherwise - ignore
                const shouldIgnore = e.altKey;
                return shouldIgnore;
              },
              smoothScroll: zoomOptions?.smoothScroll,
              maxZoom: zoomOptions?.max,
              minZoom: zoomOptions?.min
            });
            setPanZoominst(inst);
          }}
        />
      </div>
    </>
  );
};
ZoomComponent.propTypes = {
  zoomOptions: PropTypes.object,
  pan_zoom_ref: PropTypes.object,
  title: PropTypes.string,
  src: PropTypes.string
};
export default ZoomComponent;
