import React from "react";
import PropTypes from "prop-types";
import { Navigate, Outlet, useLocation } from "react-router-dom";
const ProtectedRoute = ({ isAllowed, redirectPath, children }) => {
  const location = useLocation();
  return !isAllowed ? (
    <Navigate to={redirectPath} replace state={{ from: location }} />
  ) : children ? (
    children
  ) : (
    <Outlet />
  );
};
ProtectedRoute.propTypes = {
  isAllowed: PropTypes.bool,
  redirectPath: PropTypes.string,
  children: PropTypes.any
};
export default ProtectedRoute;
