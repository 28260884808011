import React from "react";
import "../topusers.scss";
import PropTypes from "prop-types";
import ProgressBar from "../../ChartComponent/ProgressBar";
import { roundToSigDigits } from "../../../utils/common-helper";
import { CustomTooltip } from "../../../utils/tooltip-helper";

function TopUsers({ tableData, title }) {
  return (
    <div className="top-user-container">
      <div className="title-section">
        <h5>{title ? `Top 10 Users` : `Top 10 Warehouses`}</h5>
      </div>
      <div className="data-section">
        <table>
          <thead>
            <tr>
              <td>
                <span>
                  {tableData?.column_display_name_mappping?.user_name ??
                    tableData?.column_display_name_mappping?.warehouse_name}
                </span>
              </td>
              <td>
                <span>
                  {tableData?.column_display_name_mappping?.account_name}
                </span>
              </td>
              <td>
                <span>
                  {tableData?.column_display_name_mappping?.percent_compute ??
                    tableData?.column_display_name_mappping?.percent_user_cost}
                </span>
              </td>
              <td>
                <span>
                  {tableData?.column_display_name_mappping?.total_cost_user ??
                    tableData?.column_display_name_mappping?.sum_cost}
                </span>
              </td>
            </tr>
          </thead>
          <tbody>
            {tableData.data.map((item, index) => (
              <tr key={index}>
                <td className="name-section">
                  {CustomTooltip(
                    item.user_name ?? item?.warehouse_name,
                    item.user_name ?? item?.warehouse_name,
                    25
                  )}
                </td>
                <td className="name-section">
                  {CustomTooltip(item?.account_name, item?.account_name, 25)}
                </td>
                <td className="graph-section">
                  <ProgressBar
                    value={item.percent_user_cost ?? item?.percent_compute}
                  />
                </td>
                <td className="name-section">
                  $
                  {`${roundToSigDigits(
                    item.total_cost_user ?? item.sum_cost,
                    2,
                    false
                  )}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
TopUsers.propTypes = {
  tableData: PropTypes.object,
  title: PropTypes.bool
};
export default TopUsers;
