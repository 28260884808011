import { useEffect } from "react";
import { useAuth } from "../../../src/utils/auth-helper";

const ChatwootAuthSetter = () => {
  const { userInfo } = useAuth();

  useEffect(() => {
    if (
      userInfo &&
      userInfo?.info &&
      typeof userInfo?.info?.email === "string"
    ) {
      window?.$chatwoot?.setUser(userInfo?.info?.email, {
        email: userInfo?.info?.email,
        name: userInfo?.info?.acc_name
      });
    } else {
      window?.$chatwoot?.reset();
    }
  }, [userInfo]);
  return "";
};

export default ChatwootAuthSetter;
