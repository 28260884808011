import React, { useEffect, useRef, useState } from "react";
import "./queryprofile.scss";
import PropTypes from "prop-types";
import ZoomComponent from "../../components/ZoomComponent";
import chevron_back from "../../assets/images/dashboard/chevron_back.png";
import chevron_front from "../../assets/images/dashboard/chevron_front.png";

const QueryProfile = ({ queryProfileDataInfo, visible }) => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryProfileData, setQueryProfileData] = useState(null);
  const [activeStep, setActiveStep] = useState(null);
  const [stepOptions, setStepOptions] = useState(null);
  const [collapse, setCollapse] = useState(chevron_back);
  const changeCollapse = () => {
    if (collapse === chevron_back) {
      setCollapse(chevron_front);
    } else {
      setCollapse(chevron_back);
    }
  };

  const pan_zoom_ref = useRef(null);
  const createStepOptions = (options) => {
    setStepOptions(options);
    setActiveStep(options[0]);
  };
  const getData = async () => {
    try {
      if (queryProfileDataInfo) {
        setQueryProfileData(queryProfileDataInfo);
        if (
          queryProfileDataInfo?.profiles &&
          queryProfileDataInfo?.profiles?.length
        ) {
          createStepOptions(queryProfileDataInfo?.profiles);
        }
        setIsPageLoading(false);
      } else {
        setQueryProfileData(null);
        setActiveStep(null);
        setIsPageLoading(false);
      }
    } catch (err) {
      setQueryProfileData(null);
      setActiveStep(null);
      setIsPageLoading(false);
      console.log(err);
    }
  };
  const stepOptionsViews =
    stepOptions &&
    stepOptions?.map((option, index) => {
      return (
        <div
          className={`step-container ${
            activeStep?.step_id === option?.step_id ? "active" : ""
          }`}
          key={index}
          onClick={() => {
            setActiveStep(option);
          }}>
          <span>Step {option.step_id}</span>
        </div>
      );
    });
  useEffect(() => {
    setIsPageLoading(true);
    getData();
  }, []);
  return (
    <>
      <div className={`qry-profile-container ${visible ? "" : "hidden"}`}>
        {!isPageLoading && queryProfileData ? (
          <div className="profile-block">
            {/* <div className="text-div-container">
              <div className="raw-header-container">
                <span>Query Text</span>
              </div>
              <div className="raw-div">{queryProfileText}</div>
            </div> */}
            <div className="raw-div-container">
              <div className="raw-header-container">
                <span>Query Profile</span>
              </div>
              <div className="absolute-step-container">
                <div className="step-block">
                  {activeStep && stepOptions && stepOptionsViews}
                </div>
                <div className="info-container">
                  <div
                    className="collapser"
                    onClick={() => {
                      changeCollapse();
                    }}>
                    <img src={collapse} />
                  </div>
                  {collapse === chevron_back && (
                    <div className="desc-container">
                      <div className="desc-header">
                        <span>
                          Query ID:
                          <span className="id-text">
                            {queryProfileData?.query_id}
                          </span>
                        </span>
                      </div>
                      <div className="desc-subheader">
                        <span>
                          Click and drag to pan. Mouse wheel to zoom. Alt+click
                          / Optn+click for text selection
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {activeStep && (
                <>
                  {
                    <ZoomComponent
                      pan_zoom_ref={pan_zoom_ref}
                      src={activeStep?.rendered?.toString()}
                      title={"Raw Query Details"}
                      zoomOptions={{ min: 0.5, max: 8, smoothScroll: false }}
                    />
                  }
                </>
              )}
            </div>
            {/* {queryProfileData?.profiles[0]?.rendered} */}
          </div>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
QueryProfile.propTypes = {
  queryProfileDataInfo: PropTypes.object,
  visible: PropTypes.bool
};
export default QueryProfile;
