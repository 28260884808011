import React, { useEffect, useState } from "react";
import "./querydetails.scss";
import PropTypes from "prop-types";
import { Link, useNavigate, useParams } from "react-router-dom";
import QueryStats from "../QueryStats";
import {
  DownloadLinkButton,
  getQueryDetailsHistogram,
  getQueryDetailsInfo,
  getQueryDetailsStats,
  getQueryProfile,
  getQueryProfileText
} from "../../utils/query-helper";
import HTMLRenderrer from "../../utils/htmlRenderrer";
import QueryProfile from "../QueryProfile";
import {
  PrettyFormat,
  roundToSigDigits,
  secondsConverter
} from "../../utils/common-helper";
import { CustomTooltip } from "../../utils/tooltip-helper";
import back_arrow from "../../assets/images/common/back_arrow.svg";
import copy from "../../assets/images/connection/copy1.svg";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";
const QueryDetails = () => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [breadCrumbs] = useState(true);
  const { tab, date_range, data_wh_id, regex_id } = useParams();
  const [activeTab, setActiveTab] = useState(tab ? tab : "querystats");
  const [qryDetailsInfo, setQryDetailsInfo] = useState(null);
  const [obsrec, setObsrec] = useState(null);
  const [rawText, setRawText] = useState(null);
  const [queryProfileText, setQueryProfileText] = useState(null);
  const [queryProfile, setQueryProfile] = useState(null);
  const [histogramData, setHistogramData] = useState(null);
  const [queryDetailsStats, setQueryDetailsStats] = useState(null);
  const navigate = useNavigate();
  const toast = useToast();

  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };

  const linkStyle = {
    textDecoration: "none",
    color: "#0597F2",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px"
  };

  const changeActiveTab = (tab) => {
    setActiveTab(tab);
  };

  const detailsView =
    qryDetailsInfo &&
    Object.keys(qryDetailsInfo)?.length &&
    Object.keys(qryDetailsInfo)?.map((key, index) => {
      return (
        <div className="detail-block" key={index}>
          <div className="heading">
            <span>{`${qryDetailsInfo[key]?.label}`}</span>
          </div>
          <div className="value">
            <span>{qryDetailsInfo[key]?.value}</span>
          </div>
        </div>
      );
    });

  const handleBackClick = () => {
    setQryDetailsInfo(null);
    navigate(`../workloads/recommendations/${date_range}`);
  };

  const copyInstruction = () => {
    navigator.clipboard.writeText(rawText && rawText);
    customToast({ type: "success", ...getException("QueryCopySuccessful") });
  };

  const createQryDetails = (detailsObj) => {
    const obj = {
      warehouse: {
        label: "Warehouse",
        value: CustomTooltip(
          detailsObj?.warehouses.toString(),
          detailsObj?.warehouses.toString(),
          30
        )
      },
      user: {
        label: "User",
        value: CustomTooltip(
          detailsObj?.users.toString(),
          detailsObj?.users.toString(),
          30
        )
      },
      count: { label: "Count", value: `${detailsObj?.count}` },
      total_cost: {
        label: "Total Cost",
        value: `$${roundToSigDigits(detailsObj?.sum_query_cost, 2, false)}`
      },
      cost_per_query: {
        label: "Avg. Cost per query",
        value: `$${roundToSigDigits(detailsObj?.avg_query_cost, 2, false)}`
      },
      avg_execution_time: {
        label: "Avg. Execution Time",
        value: secondsConverter(
          roundToSigDigits(detailsObj?.avg_execution_time, 2, false)
        )
      },
      sum_execution_time: {
        label: "Total Execution Time",
        value: secondsConverter(
          roundToSigDigits(detailsObj?.sum_execution_time, 2, false)
        )
      },
      remote_spillage: {
        label: "Remote Spillage (GB)",
        value: CustomTooltip(
          roundToSigDigits(
            detailsObj?.sum_gb_spilled_to_remote_storage,
            2,
            false
          ),
          roundToSigDigits(
            detailsObj?.sum_gb_spilled_to_remote_storage,
            2,
            false
          ),
          35
        ),
        suffix: "GB"
      },
      local_spillage: {
        label: "Local Spillage (GB)",
        value: CustomTooltip(
          roundToSigDigits(
            detailsObj?.sum_gb_spilled_to_local_storage,
            2,
            false
          ),
          roundToSigDigits(
            detailsObj?.sum_gb_spilled_to_local_storage,
            2,
            false
          ),
          35
        ),
        suffix: "GB"
      },
      partition_scanned: {
        label: "% Partitions Scanned",
        value:
          roundToSigDigits(
            Number(detailsObj?.ratio_partitions_scanned * 100),
            2,
            false
          ) + "%"
      }
    };
    setQryDetailsInfo(obj);
  };

  const getData = async () => {
    try {
      const [
        queryDetailsInfoData,
        queryProfileTextInfo,
        queryProfileDataInfo,
        queryDetailsStatsInfo,
        histogramDataInfo
      ] = await Promise.all([
        getQueryDetailsInfo(date_range, data_wh_id, regex_id),
        getQueryProfileText(date_range, data_wh_id, regex_id),
        getQueryProfile(date_range, data_wh_id, regex_id),
        getQueryDetailsStats(date_range, data_wh_id, regex_id),
        getQueryDetailsHistogram(date_range, data_wh_id, regex_id)
      ]);
      if (queryProfileDataInfo) {
        setQueryProfile(queryProfileDataInfo);
      }
      if (queryDetailsStatsInfo) {
        setQueryDetailsStats(queryDetailsStatsInfo);
      }
      if (histogramDataInfo) {
        setHistogramData(histogramDataInfo);
      }
      if (queryDetailsInfoData && queryProfileTextInfo) {
        createQryDetails(queryDetailsInfoData);
        setQueryProfileText(
          PrettyFormat(queryProfileTextInfo?.query_text, "sql")
        );
        setRawText(queryProfileTextInfo?.query_text);
        setObsrec({
          obs: {
            label: "Observations",
            value: queryDetailsInfoData?.problems_with_query
          },
          rec: {
            label: "Recommendations",
            value: queryDetailsInfoData?.recommendations
          }
        });
        setIsPageLoading(false);
      } else {
        setQryDetailsInfo(null);
        setQueryProfileText(null);
        setObsrec(null);
        setRawText(null);
        setIsPageLoading(false);
      }
    } catch (err) {
      //clearData();
      setIsPageLoading(false);
      setQryDetailsInfo(null);
      setQueryProfileText(null);
      setRawText(null);
      setObsrec(null);
      console.log(err);
    }
  };
  useEffect(() => {
    setIsPageLoading(true);
    // setGridLoading(true);
    getData();
  }, []);
  return (
    <>
      <div className="qdet-container">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container">
                    <Link
                      to={`../workloads/recommendations/${date_range}`}
                      style={linkStyle}>
                      Recommendations
                    </Link>
                    <span>{` / ${`Query Details`}`}</span>
                  </div>
                ) : null}
                <div className="header">
                  <div className="back_btn" onClick={handleBackClick}>
                    <img src={back_arrow} />
                  </div>
                  <span>Query Details</span>
                </div>
              </div>
              <div className="top-filter">
                {/* {!gridLoading && rowData && !rowData?.length && (
                 
                )} */}
              </div>
            </div>
            {qryDetailsInfo && (
              <div className="qdet_info-container">
                <div className="qdet-parent">
                  {/* <div className="header-container">
                    <span>Query Metrics</span>
                  </div> */}
                  <div className="details-container">{detailsView}</div>
                </div>
                <div className="text-div-container">
                  <div className="header-container">
                    <span>Query Text</span>
                    <div className="actions__div">
                      <DownloadLinkButton
                        regex_id={regex_id}
                        data_wh_id={data_wh_id}
                        date_range={date_range}
                        filter_type={"all"}
                      />
                      <div
                        className="copy-image"
                        onClick={() => {
                          copyInstruction();
                        }}>
                        {<img className="copy" src={copy} />}
                        {/* {textCopied && <img className="copied" src={copied} />} */}
                      </div>
                    </div>
                  </div>
                  <div className="raw-div">{queryProfileText}</div>
                </div>
              </div>
            )}
            <div className="subheader-container">
              <nav className="qdet-nav">
                <Link
                  className={`qdet-link ${
                    activeTab === "queryprofile" ? "active" : ""
                  }`}
                  to={`../workloads/recommendations/${date_range}/querydetails/queryprofile/${data_wh_id}/${regex_id}`}
                  onClick={() => {
                    changeActiveTab("queryprofile");
                  }}>
                  Query Profile
                </Link>
                <Link
                  to={`../workloads/recommendations/${date_range}/querydetails/querystats/${data_wh_id}/${regex_id}`}
                  className={`qdet-link ${
                    activeTab === "querystats" ? "active" : ""
                  }`}
                  onClick={() => {
                    changeActiveTab("querystats");
                  }}>
                  Query Stats
                </Link>
              </nav>
            </div>
            {obsrec && activeTab === "queryprofile" && (
              <div className="rec-block">
                <div className="rec-parent">
                  <div className="heading-container">
                    <span>{obsrec?.obs?.label}</span>
                  </div>
                  <div className="rec-container">
                    <div className="rec">
                      <HTMLRenderrer value={obsrec?.obs?.value} />
                    </div>
                  </div>
                </div>
                <div className="rec-parent">
                  <div className="heading-container">
                    <span>{obsrec?.rec?.label}</span>
                  </div>
                  <div className="rec-container">
                    <div className="rec">
                      <HTMLRenderrer value={obsrec?.rec?.value} />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="details-block">
              <QueryStats
                visible={activeTab === "querystats" ? true : false}
                queryDetailsInfoData={queryDetailsStats}
                histogramDataInfo={histogramData}
              />
              <QueryProfile
                visible={activeTab === "queryprofile" ? true : false}
                queryProfileDataInfo={queryProfile}
              />
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
QueryDetails.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object
};
export default QueryDetails;
