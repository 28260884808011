import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  checkValue,
  filterTypes,
  groupTableData
} from "../../utils/filter-helper";
import Button from "../customElements/Button";
import "./customFilter.scss";
import DeleteIcon from "../customElements/DeleteIcon";
import Select, { components } from "react-select";
import chev_down from "../../assets/images/common/chev_down.svg";
import cancel from "../../assets/images/grid/cancel.svg";
import cancel_black from "../../assets/images/grid/cancel_black.svg";
import { clearAllFilters } from "../../utils/filter-helper";

const filterOperator = [
  { label: "AND", value: "AND" }
  // { label: "OR", value: "OR" }
];
function CustomFilter({
  column,
  gridData,
  applyFilter,
  setApplyFilter,
  handleFilter,
  clearFilter,
  close
}) {
  const [selectInput, setSelectInput] = useState("");
  const [temp, setTemp] = useState([]);

  const applyNewFilter = () => {
    setTemp([
      ...temp,
      {
        column: temp?.[temp.length - 1].column,
        condition: filterTypes?.[0],
        previuosValue: [],
        previousMenuList: [],
        value: [],
        dropDown: false,
        defaultMenuList: groupTableData(
          gridData,
          temp?.[temp.length - 1].column.value
        )
      }
    ]);
  };

  const handleSwitch = (index) => {
    const new_arr = temp.map((item, ind) =>
      index === ind ? { ...item, dropDown: !item.dropDown } : item
    );
    setTemp(new_arr);
  };

  // On column and operator dropdown Change
  const handleInputs = (e, index, type = "") => {
    const new_data = temp.map((item) => ({ ...item, dropDown: false }));
    const gropedData = groupTableData(gridData, e.value);
    let newarr;
    if (type === "col") {
      newarr = new_data.map((item, ind) =>
        ind === index
          ? {
              ...item,
              column: e,
              previuosValue: item.value,
              previousMenuList: item.defaultMenuList,
              value: [],
              dropDown: false,
              defaultMenuList: gropedData
            }
          : item
      );
    } else if (type === "opp") {
      newarr = new_data.map((item, ind) =>
        ind === index ? { ...item, condition: e } : item
      );
    } else if (type === "val") {
      newarr = temp.map((item, ind) =>
        ind === index ? { ...item, value: e } : item
      );
      setSelectInput("");
    }
    setTemp(newarr);
  };

  const removeFilterRow = (index) => {
    const updatedArr = temp.filter((item, ind) => ind !== index);
    setTemp(updatedArr);
  };
  const onInputChange = (inputValue, event) => {
    if (event.action === "input-change") setSelectInput(inputValue);
    else if (event.action === "menu-close" && selectInput !== "")
      setSelectInput("");
  };

  const applyGridFilter = (filterValue, gridData) => {
    setApplyFilter(filterValue);
    handleFilter(filterValue, gridData);
    close();
  };

  const handleClearFilter = () => {
    clearFilter();
    const filterData = clearAllFilters(temp);
    setTemp(filterData);
  };
  const Input = (props) => (
    <>
      {selectInput.length === 0 ? (
        <components.Input
          height={70}
          autoFocus={props.selectProps.menuIsOpen}
          {...props}>
          {props.children}
        </components.Input>
      ) : (
        <div style={{}}>
          <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
            {props.children}
          </components.Input>
        </div>
      )}
    </>
  );
  Input.propTypes = {
    selectProps: PropTypes.any,
    menuIsOpen: PropTypes.bool,
    children: PropTypes.any
  };
  const Option = (props) => (
    <components.Option {...props}>
      <div
        className="dropdown-list-item"
        style={{
          width: "100%",
          height: "36px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
          gap: "5px",
          position: "relative",
          overflow: "hidden"
        }}>
        <input
          key={props.value}
          type="checkbox"
          className="multi-checkbox"
          checked={props.isSelected}
          onChange={() => {}}
          style={{
            cursor: "pointer",
            position: "relative",
            minWidth: "14px",
            height: "14px"
          }}
        />
        <label style={{ cursor: "pointer" }}>{props.label}</label>
      </div>
    </components.Option>
  );
  Option.propTypes = {
    props: PropTypes.any,
    value: PropTypes.string,
    isSelected: PropTypes.bool,
    label: PropTypes.string
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img style={{ pointerEvents: "none" }} src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const customStyles = {
    multiValueLabel: (def) => ({
      ...def,
      backgroundColor: "#ebebeb"
    }),
    multiValueRemove: (def) => ({
      ...def,
      // display: "none",
      backgroundColor: "#ebebeb",
      "&:hover": {
        content: `url(${cancel}) `,
        backgroundColor: "#ebebeb", // Change the hover background color as per your preference
        cursor: "pointer"
      },
      content: `url(${cancel_black}) `
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: "36px",
      overflow: "auto"
    }),
    option: (styles) => {
      return {
        ...styles,
        fontSize: "0.875rem",
        padding: "0.75rem",
        display: "flex",
        alignItems: "center",
        gap: "0.8rem"
      };
    },

    menu: (def) => ({ ...def, zIndex: 9999 })
  };

  useEffect(() => {
    window.addEventListener("click", close);
    return () => {
      window.removeEventListener("click", close);
    };
  }, []);
  useEffect(() => {
    const updateOldState = applyFilter.map((item) => {
      const groupedData = groupTableData(gridData, item.column.value);
      return {
        ...item,
        defaultMenuList:
          item.previousMenuList.length > 0
            ? item.previousMenuList
            : groupedData,
        value: item.previuosValue.length > 0 ? item.previuosValue : item.value
      };
    });
    setApplyFilter(updateOldState);
    setTemp(updateOldState);
  }, []);


  return (
    <div className="filter-main-container" onClick={(e) => e.stopPropagation()}>
      <div
        className={
          temp.length > 3
            ? "filter-row-container overflow-y"
            : "filter-row-container "
        }>
        {temp.map((item, index) => (
          <div key={index} className="filter-row-sub-container">
            <div className="filter__row">
              <div className="filter__section">
                <div className="grid-column-select">
                  <Select
                    value={item?.column}
                    options={column}
                    name="column"
                    classNamePrefix="selectcategory"
                    components={{ DropdownIndicator }}
                    placeholder="select"
                    isSearchable={false}
                    onChange={(e) => handleInputs(e, index, "col")}
                  />
                </div>
                <div className="grid-operator-select">
                  <Select
                    value={item?.condition}
                    options={filterTypes}
                    name="column"
                    classNamePrefix="selectcategory"
                    components={{ DropdownIndicator }}
                    placeholder="select"
                    isSearchable={false}
                    onChange={(e) => handleInputs(e, index, "opp")}
                  />
                </div>
                <div className="grid-value-select">
                  {item?.dropDown ? (
                    <Select
                      value={item?.value}
                      inputValue={selectInput}
                      onInputChange={onInputChange}
                      options={item?.defaultMenuList}
                      styles={customStyles}
                      name="column"
                      classNamePrefix="selectcategory"
                      components={{
                        DropdownIndicator,
                        Option,
                        Input
                      }}
                      placeholder=""
                      isSearchable={true}
                      onChange={(e) => handleInputs(e, index, "val")}
                      onMenuClose={() => handleSwitch(index)}
                      menuIsOpen={true}
                      closeMenuOnSelect={false}
                      isMulti={true}
                      tabSelectsValue={true}
                      hideSelectedOptions={false}
                      blurInputOnSelect={false}
                      isClearable={false}
                      // menuPlacement="auto"
                    />
                  ) : (
                    <Button
                      onClick={() => handleSwitch(index)}
                      className={"filter-button"}>
                      {item.value?.length <= 0 ? (
                        <>
                          <span>Select Value</span>
                          <img src={chev_down} alt="" />
                        </>
                      ) : (
                        <>
                          {item?.value?.length > 1 ? (
                            <div className="chip-container">
                              <>
                                <div className="chip">
                                  {item?.value?.[0]?.value}
                                </div>
                                {item?.value?.length >= 2 && (
                                  <div className="chip">
                                    {item?.value?.[1]?.value}
                                  </div>
                                )}
                                {item?.value?.length >= 3 && (
                                  <div className="chip2">
                                    +{item.value.length - 2}
                                  </div>
                                )}
                              </>
                              <img src={chev_down} alt="" />
                            </div>
                          ) : (
                            <div className="chip-container">
                              <div className="chip">
                                {item?.value?.[0].value}
                              </div>
                              <img src={chev_down} alt="" />
                            </div>
                          )}
                        </>
                      )}
                    </Button>
                  )}
                </div>
              </div>
              {temp.length > 1 && (
                <div
                  onClick={() => removeFilterRow(index)}
                  className="delete-filter">
                  <DeleteIcon />
                </div>
              )}
            </div>
            {temp.length > 1 && index < temp.length - 1 && (
              <div className="filter-type-container">
                <Select
                  value={filterOperator[0]}
                  options={filterOperator}
                  // styles={customStyles}
                  classNamePrefix="selectcategory"
                  components={{ DropdownIndicator }}
                  placeholder="select"
                  isSearchable={false}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div
        className={
          temp.length > 3 ? "filter-footer footer__stroke" : "filter-footer"
        }>
        <div>
          <p onClick={applyNewFilter}>+ Add Filter</p>
        </div>
        <div className="filter-section">
          <Button onClick={handleClearFilter} className={"clear__button"}>
            Clear
          </Button>
          <Button
            disabled={checkValue(temp)}
            onClick={() => !checkValue(temp) && applyGridFilter(temp, gridData)}
            className={
              checkValue(temp)
                ? "button-primary filter-button-disabled"
                : "button-primary"
            }>
            Apply
          </Button>
        </div>
      </div>
    </div>
  );
}
CustomFilter.propTypes = {
  close: PropTypes.func,
  gridData: PropTypes.array,
  column: PropTypes.array,
  handleFilter: PropTypes.func,
  clearFilter: PropTypes.func,
  applyFilter: PropTypes.array,
  setApplyFilter: PropTypes.func,
  isMenuOpen: PropTypes.bool
};
export default CustomFilter;
