export const check_valid_slack_webhook_url = (url) => {
  return url.match(
    new RegExp(
      "^https://hooks.slack.com/services/T[A-Za-z0-9]+/B[A-Za-z0-9]+/[A-Za-z0-9]+$"
    )
  );
};

export const check_valid_msteams_webhook_url = (url) => {
  return url.match(
    new RegExp(
      "^https://[a-zA-Z0-9-]+.webhook.office.com/webhookb2/[^/]+/IncomingWebhook/[^/]+/[^/]+$"
    )
  );
};
