import React from "react";
import PropTypes from "prop-types";
import StackGraph from "../ChartComponent/StackGraph";
import { roundToSigDigits } from "../../utils/common-helper";

function StackGraphContainer({ activeTab, rawData, graphData }) {
  const StackOptions = {
    chart: {
      type: "column",
      title: {
        text: ""
      }
    },
    xAxis: {
      categories: rawData?.columns?.slice(1)
    },
    yAxis: {
      min: 0,
      title: {
        text: "Cost ($)"
      },
      stackLabels: {
        enabled: true,
        align: "center",
        style: {
          color: "#222"
        },
        x: 0,
        verticalAlign: "top",
        formatter: function () {
          return "$" + roundToSigDigits(this.total);
        }
      }
    },
    tooltip: {
      backgroundColor: "black",
      borderRadius: 5, // Add rounded corners to the tooltip
      formatter: function () {
        return `<div style="color:white; background-color: ${
          this.series.color
        }; border: 1px solid ${this.series.color}; padding: 10px;">
        <span> Name - <b>${
          this.series.name
        }</b><br/><span>Value - <b>$${roundToSigDigits(
          this.y,
          2,
          false
        )}</b></span></span>
       
      </div>`;
      }
    },
    legend: {
      reversed: true,
      symbolRadius: 2,
      symbolHeight: 10
    },
    plotOptions: {
      column: {
        borderWidth: 0,
        stacking: "normal",
        dataLabels: {
          enabled: false // Disable data labels
        },
        states: {
          inactive: {
            opacity: 1
          },
          hover: {
            enabled: false // Enable hover state for all series
          }
        },
        groupPadding: 0.1 // Adjust the groupPadding as needed to add a gap between stacks
      }
    },
    series: graphData.reverse()
  };

  //
  //
  // Line Chart Data
  const SPlineOptions = {
    chart: {
      type: "line"
    },

    title: {
      text: " "
    },

    xAxis: {
      type: "datetime",
      tickPixelInterval: 150,
      maxPadding: 0.1
    },

    yAxis: {
      title: {
        text: "Cost ($)"
      }
    },
    plotOptions: {
      series: {
        marker: {
          symbol: "circle", // Set the marker symbol to 'circle'
          enabled: false // Disable individual markers for all series
        },
        states: {
          inactive: {
            opacity: 1
          }
        }
      },
      stickyTracking: false
    },
    tooltip: {
      backgroundColor: "black",
      borderRadius: 5, // Add rounded corners to the tooltip
      formatter: function () {
        return `<div style="color:white; background-color: ${
          this.series.color
        }; border: 1px solid ${this.series.color}; padding: 10px;">
        <span> Name - <b>${
          this.series.name
        }</b><br/><span>Value - <b>$${roundToSigDigits(
          this.y,
          2,
          false
        )}</b></span></span>
       
      </div>`;
      }
    },

    legend: {
      enabled: true
    },
    series: graphData
  };

  return (
    <StackGraph options={activeTab === "1" ? StackOptions : SPlineOptions} />
  );
}
StackGraphContainer.propTypes = {
  activeTab: PropTypes.string,
  rawData: PropTypes.object,
  graphData: PropTypes.array
};
export default StackGraphContainer;
