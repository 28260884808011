//react imports
import React from "react";
import PropTypes from "prop-types";

//component import
import Button from "../customElements/Button"; 

// Icons Imports
import emptyTable from "../../assets/images/grid/emptyTable.png";
import plus from "../../assets/images/grid/plusRounded.svg";

function EmptyState({ handleModal }) {
  return (
    <div className="empty-state-container">
      <img className="empty-table-icon " src={emptyTable} alt="" />
      <div className="description-container">
        <p className="sub-heading">Cost center is not created yet</p>
        <p className="description">
          Cost center is not created, create cost center to access cost
          reporting.
        </p>
      </div>
      <Button onClick={handleModal} className={"create-cost-center"}>
        <img src={plus} alt="" /> Create Cost Center
      </Button>
    </div>
  );
}
EmptyState.propTypes = {
  handleModal: PropTypes.func
};
export default EmptyState;
