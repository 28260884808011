import React, { useContext, useEffect, useState } from "react";
import "./signuponboard.scss";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import SignInHeader from "../../components/SignInHeader";
import { theme } from "../../utils/sign-helper";
import OnBoard from "./OnBoard";
import InviteMembers from "./InviteMembers";
import { useNavigate, useParams } from "react-router-dom";
import {
  accountSkipAPI,
  doInviteAPI,
  doOnboardAPI,
  getAppUserInfo,
  getMyInfo
} from "../../utils/user-helper";
import {
  getSessionStorage,
  setSessinStorage
} from "../../utils/storage-helper";
import { AuthContext } from "../../utils/authprovider";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";
import { useToast } from "react-toast-wnm";
import { validateCompanyEmail, validateEmail } from "../../utils/validateemail";
// import Survey from "./Survey";

const SignUpOnBoard = () => {
  const toast = useToast();
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const { setUserinfo, userInfo } = useContext(AuthContext);
  const params = useParams();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [step, setStep] = useState(
    params ? (params?.status === "invite" ? 1 : 0) : 0
  );
  useEffect(() => {
    if (params) {
      if (params?.status === "invite") {
        setStep(1);
      } else {
        setStep(0);
      }
    }
  }, [params]);
  const totalSteps = 2;

  const doInvite = async (emails) => {
    try {
      changeIsLoading(true);
      const filteredEmails = emails.filter(
        (email) => email?.email?.trim() !== ""
      );
      if (filteredEmails.length === 0) {
        throw new Error("INVITE_NO_EMAILS");
      }
      const invalidEmails = filteredEmails.filter((email) => {
        return !validateEmail(email?.email?.trim());
      });
      if (invalidEmails.length > 0) {
        throw new Error("INVITE_INVALID_EMAILS");
      }
      const notCompanyEmails = filteredEmails.filter((email) => {
        return validateCompanyEmail(email?.email?.trim());
      });
      if (notCompanyEmails.length > 0) {
        throw new Error("INVITE_NOT_COMPANY_EMAILS");
      }
      await doInviteAPI(filteredEmails);
      const { onboarded, redirect_to_billing, num_data_wh_acc } =
        await getAppUserInfo();
      setSessinStorage(
        "orgonboarded",
        onboarded &&
          onboarded.toString() !== "null" &&
          onboarded.toString() !== "undefined"
          ? onboarded
          : false
      );
      changeIsLoading(false);
      if (redirect_to_billing?.toString() === "true") {
        navigate("/dashboard/settings/billing");
      } else {
        if (Number(num_data_wh_acc) > 0) {
          navigate("/dashboard");
        } else {
          navigate("/selectconnection");
        }
      }
    } catch (err) {
      let key = err?.response?.data?.errorKey;
      if (err?.message === "INVITE_NO_EMAILS") {
        key = "INVITE_NO_EMAILS";
      } else if (err?.message === "INVITE_INVALID_EMAILS") {
        key = "INVITE_INVALID_EMAILS";
      } else if (err?.message === "INVITE_NOT_COMPANY_EMAILS") {
        key = "INVITE_NOT_COMPANY_EMAILS";
      }
      customToast({
        type: "error",
        ...getException(key, "")
      });
      changeIsLoading(false);
    }
  };

  const doOnBoard = async (fields) => {
    try {
      await doOnboardAPI(fields);
      const me = await getMyInfo();
      if (me) {
        setSessinStorage("me", JSON.stringify(me));
        setSessinStorage(
          "given_name",
          `${me?.first_name + " " + me?.last_name}`
        );
        setUserinfo({
          ...userInfo,
          info: {
            ...userInfo.info,
            onboarded: me?.onboarded
          }
        });
      }
      const num_data_wh_acc = getSessionStorage("num_data_wh_acc");
      const orgonboarded = getSessionStorage("orgonboarded");
      const redirect_to_billing = getSessionStorage("redirect_to_billing");
      if (orgonboarded?.toString() === "true") {
        if (redirect_to_billing.toString() === "true") {
          navigate("/dashboard/settings/billing");
        } else {
          if (Number(num_data_wh_acc) > 0) {
            navigate("/dashboard");
          } else {
            navigate("/selectconnection");
          }
        }
      } else {
        navigate("/onboard/invite");
      }
      setStep(Math.min(step + 1, totalSteps - 1));
      changeIsLoading(false);
    } catch (error) {
      console.log(error);
      changeIsLoading(false);
    }
  };

  const onSkip = async () => {
    try {
      await accountSkipAPI();
      const { onboarded, redirect_to_billing } = await getAppUserInfo();
      setSessinStorage(
        "orgonboarded",
        onboarded &&
          onboarded.toString() !== "null" &&
          onboarded.toString() !== "undefined"
          ? onboarded
          : false
      );
      changeIsLoading(false);
      const num_data_wh_acc = getSessionStorage("num_data_wh_acc");
      if (redirect_to_billing.toString() === "true") {
        navigate("/dashboard/settings/billing");
      } else {
        if (Number(num_data_wh_acc) > 0) {
          navigate("/dashboard");
        } else {
          navigate("/selectconnection");
        }
      }
    } catch (err) {
      changeIsLoading(false);
    }
  };

  const changeIsLoading = (bool) => {
    setIsLoading(bool);
  };

  const goBack = (e) => {
    e.preventDefault();
    setStep(Math.max(step - 1, 0));
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="parent-container">
        <div className="sign-board-container">
          <SignInHeader />
          <div className="block">
            <div className="signboard-parent-block">
              <div className="signboard-progress">
                {Array(totalSteps)
                  .fill(0)
                  .map((_, i) => (
                    <div
                      key={i}
                      className={`signboard-progress-item ${
                        i == step ? "signboard-progress-done" : ""
                      }`}></div>
                  ))}
              </div>
              {step === 0 && (
                <OnBoard
                  doOnBoard={doOnBoard}
                  changeIsLoading={changeIsLoading}
                />
              )}
              {step === 1 && (
                <InviteMembers
                  doInvite={doInvite}
                  goBack={goBack}
                  onSkip={onSkip}
                  changeIsLoading={changeIsLoading}
                />
              )}
              {/* {step === 2 && <Survey doSurvey={doOnBoard} goBack={goBack} />} */}
            </div>
          </div>
        </div>
        {isLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};
export default SignUpOnBoard;
