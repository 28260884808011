import { Auth } from "aws-amplify";
import Cookie from "js-cookie";
import { setSessinStorage } from "./storage-helper";
import * as Sentry from "@sentry/react";
import sentryEnabled from "../index";

export const getToken = (key) => {
  const storage = Cookie.get(key);
  return storage || "";
};

export const getJSONToken = (key) => {
  const json = Cookie.getJSON(key);
  return json || {};
};

export const deleteToken = (key) => {
  Cookie.remove(key);
  return getToken(key) === "";
};

export const saveToken = (key, value, options = {}) => {
  Cookie.set(key, value, options);
  return getToken(key) !== "";
};

export const getAuthenticatedToken = async () => {
  let token;
  try {
    await Auth.currentSession().then((session) => {
      const idTokenExpire = session?.getAccessToken().getExpiration();
      const refreshToken = session?.getRefreshToken();
      setSessinStorage("refreshToken", refreshToken?.token);
      const currentTimeSeconds = Math.round(+new Date() / 1000);
      if (idTokenExpire < +(currentTimeSeconds + 600)) {
        console.log("token has expired");
        Auth.currentAuthenticatedUser().then((res) => {
          res?.refreshSession(refreshToken, (err, data) => {
            if (err) {
              token = "";
              console.error(err);
              sentryEnabled && Sentry.captureException(err);
              //Auth.signOut();
            } else {
              token = `${data?.getAccessToken()?.getJwtToken()}`;
              //resolve(config);
            }
          });
        });
      } else {
        token = `${session?.getAccessToken()?.getJwtToken()}`;
        //resolve(config);
      }
    });
    return token;
  } catch (err) {
    token = "";
    console.error(err);
    sentryEnabled && Sentry.captureException(err);
    //Auth.signOut();
    return token;
  }
};
