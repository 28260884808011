import React, { useContext, useEffect, useState } from "react";
import "./billing.scss";
import {
  getManageBillingInfo,
  getPlansList,
  getUsageDetails,
  redeemVoucherCode,
  subscribeToNewPlan,
  switchToNewPlan,
  unsubscribePlan
} from "../../utils/billing-helper";
import { getAppUserInfo } from "../../utils/user-helper";
import { formatDate } from "../../utils/date-helper";
import orange_alert from "../../assets/images/dashboard/orange_alert.svg";
import { setSessinStorage } from "../../utils/storage-helper";
import { AuthContext } from "../../utils/authprovider";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";
import billing__fallback from "../../assets/images/common/billing__fallback.svg";

const Billing = () => {
  const toast = useToast();

  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const { setUserinfo, userInfo } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [breadCrumbs] = useState(false);

  const [inPlan, setInPlan] = useState(false);
  const [currentPlanDetails, setCurrentPlanDetails] = useState(null);
  const [optedPlan, setOptedPlan] = useState(null);
  const [plansList, setPlansList] = useState([]);
  const [usage, setUsage] = useState(0);
  const [bannerVisible, setBannerVisible] = useState(false);
  const [usageLimit, setUsageLimit] = useState(0);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
  const [modalProgress, setModalProgress] = useState(false);
  const [isVoucherModalOpen, setIsVoucherModalOpen] = useState(false);
  const [redeemCode, setRedeemCode] = useState("");
  const [renewalDate, setRenewalDate] = useState("");
  const [infoError, setInfoError] = useState({
    redeemText: ""
  });
  const [bannerText, setBannerText] = useState("");

  const getBannerText = (reason, plan_name, renewalDate) => {
    let text = <></>;
    switch (reason) {
      case "plan_expired": {
        text = (
          <>
            Your current <b>{plan_name}</b> plan has expired on{" "}
            <b>{renewalDate}</b>. Please subscribe to a relevant plan to
            continue using Chaos Genius. You can always contact{" "}
            <b>sales@chaosgenius.io</b> for support or{" "}
            <a
              href="https://documentation.chaosgenius.io/"
              target="_blank"
              rel="noreferrer">
              check out the docs
            </a>{" "}
            for more information.
          </>
        );
        break;
      }
      case "open_invoice_on_stripe": {
        text = (
          <>
            You have unpaid invoices. Please{" "}
            <button className="manage_billing_link" onClick={getManageBilling}>
              click here
            </button>{" "}
            and close them to continue using Chaos Genius.
          </>
        );
        break;
      }
      case "no_plan_selected": {
        text = (
          <>
            Please subscribe to a relevant plan to continue using Chaos Genius.
            You can always contact <b>sales@chaosgenius.io</b> for support or{" "}
            <a
              href="https://documentation.chaosgenius.io/"
              target="_blank"
              rel="noreferrer">
              check out the docs
            </a>{" "}
            for more information.
          </>
        );
        break;
      }
      case "too_many_data_warehouse_accounts": {
        text = (
          <>
            You have exceeded the maximum amount of data warehouse accounts
            allowed in your plan. Please delete them to reach your plan capacity
            or upgrade your plan.
          </>
        );
        break;
      }
      case "plan_usage_exceeded": {
        text = (
          <>
            Your <b>{plan_name}</b> plan usage limit has exceeded. Please
            upgrade to a new plan to continue using Chaos Genius. You can always
            contact <b>sales@chaosgenius.io</b> for support or{" "}
            <a
              href="https://documentation.chaosgenius.io/"
              target="_blank"
              rel="noreferrer">
              check out the docs
            </a>{" "}
            for more information.
          </>
        );
        break;
      }
    }
    return text;
  };

  const getPlanList = async (
    plan_name,
    change_plan_to,
    redirect_to_billing,
    redirect_to_billing_reason,
    renewalDate,
    num_data_wh_acc
  ) => {
    try {
      const data = await getPlansList();
      if (data?.plans) {
        setPlansList(data.plans);
        if (plan_name === "No Plan") {
          setCurrentPlanDetails({
            plan_name: "No Plan",
            change_plan_to: change_plan_to,
            num_data_wh_acc
          });
          setOptedPlan(null);
          setInPlan(false);
          if (redirect_to_billing) {
            setBannerVisible(true);
            const text = getBannerText(
              redirect_to_billing_reason,
              "No Plan",
              renewalDate
            );
            setBannerText(text);
          } else {
            setBannerVisible(false);
            setBannerText("");
          }
        } else {
          setInPlan(true);
          data?.plans?.forEach((plan) => {
            if (plan.plan_name === plan_name) {
              setCurrentPlanDetails({
                ...plan,
                change_plan_to: change_plan_to,
                num_data_wh_acc
              });
              setOptedPlan(plan?.plan_id);
              if (redirect_to_billing) {
                setBannerVisible(true);
                const text = getBannerText(
                  redirect_to_billing_reason,
                  plan?.plan_name,
                  renewalDate
                );
                setBannerText(text);
              } else {
                setBannerVisible(false);
                setBannerText("");
              }
            }
          });
        }
      }
      return data;
    } catch (error) {
      customToast({
        type: "error",
        ...getException(
          "",
          "Something went wrong while fetching Plans. Please reload the page and try again."
        )
      });
    }
  };

  const getUsageLimit = async () => {
    const data = await getUsageDetails();
    if (data) {
      setUsage(data.usage);
      setUsageLimit(data.max_limit);
    }
    return data;
  };
  const getData = async () => {
    try {
      setIsLoading(true);
      let date = "";
      const acc_data = await getAppUserInfo(customToast);
      const num_data_wh_acc = acc_data?.num_data_wh_acc;
      const plan_name = acc_data?.plan_name;
      const redirect_to_billing = acc_data?.redirect_to_billing;
      const redirect_to_billing_reason = acc_data?.redirect_to_billing_reason;

      setUserinfo({
        ...userInfo,
        info: {
          ...userInfo.info,
          redirect_to_billing,
          plan_name
        }
      });
      setSessinStorage("redirect_to_billing", redirect_to_billing);
      setSessinStorage("plan_name", plan_name.toString());
      if (acc_data?.next_billing_date) {
        date = formatDate(acc_data?.next_billing_date);
        setRenewalDate(date);
      }

      const listProm = getPlanList(
        plan_name,
        acc_data?.change_plan_to,
        redirect_to_billing,
        redirect_to_billing_reason,
        date,
        num_data_wh_acc
      );

      const usageProm = getUsageLimit();
      await Promise.all([listProm, usageProm]);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log("Error in getting billing data", err);
    }
  };

  const cancelPlan = async () => {
    try {
      let data = await unsubscribePlan();
      setModalProgress(false);
      setIsCancelModalOpen(false);

      if (data === true) {
        await getData();
      }
    } catch (error) {
      customToast({
        type: "error",
        ...getException(
          "",
          "Something went wrong while cancelling your Plan. Please try again."
        )
      });
    }
  };

  const getPlanFromList = (plan_id) => {
    const plan = plansList?.find((value) => {
      return value?.plan_id === plan_id;
    });
    if (plan) {
      return plan?.plan_name;
    } else {
      return "none";
    }
  };

  const upgradePlan = async () => {
    try {
      let data = await switchToNewPlan({
        plan_id: optedPlan,
        succ_page: window.location.origin + "/billing/success",
        fail_page: window.location.origin + "/billing/fail"
      });
      const { redirect_url } = data;
      if (redirect_url) {
        window.location.href = redirect_url;
      }
    } catch (err) {
      customToast({
        type: "error",
        ...getException(
          "",
          "Something went wrong while changing your Plan. Please try again."
        )
      });
      console.log(err);
    }
    setModalProgress(false);
    setIsUpgradeModalOpen(false);

    await getData();
  };

  const optToPlan = async () => {
    setModalProgress(true);
    try {
      let data = await subscribeToNewPlan({
        plan_id: optedPlan,
        succ_page: window.location.origin + "/billing/success",
        fail_page: window.location.origin + "/billing/fail"
      });
      const { redirect_url } = data;
      const info = await getAppUserInfo();
      const { redirect_to_billing, redirect_to_billing_reason, plan_name } =
        info;
      if (redirect_to_billing) {
        setBannerVisible(true);
        const text = getBannerText(redirect_to_billing_reason);
        setBannerText(text);
      } else {
        setBannerVisible(false);
        setBannerText("");
      }
      setUserinfo({
        ...userInfo,
        info: {
          ...userInfo.info,
          redirect_to_billing,
          plan_name
        }
      });
      setSessinStorage("redirect_to_billing", redirect_to_billing.toString());
      setSessinStorage("plan", plan_name.toString());
      if (redirect_url) {
        window.location.href = redirect_url;
      }
    } catch (err) {
      customToast({
        type: "error",
        ...getException(
          "",
          "Something went wrong while subscribing your Plan. Please try again."
        )
      });
      console.log(err);
    }
    setModalProgress(false);
    setIsUpgradeModalOpen(false);

    await getData();
  };

  const getManageBilling = async () => {
    try {
      const data = await getManageBillingInfo({
        return_url: window.location.origin + "/dashboard/settings/billing"
      });
      if (data) {
        const { redirect_url } = data;
        if (redirect_url) {
          window.open(redirect_url, "_blank");
        }
      }
      await getData();
    } catch (e) {
      console.log(e);
    }
  };

  const redeemVoucher = async (code) => {
    try {
      const data = await redeemVoucherCode({
        coupon_code: code ? code : ""
      });
      console.log(data);
      setRedeemCode("");
      setIsVoucherModalOpen(false);
      await getData();
    } catch (e) {
      console.log(e);
      setInfoError({
        ...infoError,
        redeemText: e?.response?.data?.message
          ? e?.response?.data?.message
          : "Something went wrong. Please try again."
      });
      setRedeemCode("");
    }
  };

  useEffect(() => {
    if (userInfo?.info?.role === "admin") {
      getData();
    }
  }, []);

  return (
    <>
      {userInfo?.info?.role === "admin" ? (
        <>
          {isLoading && (
            <div className="load">
              <div className="preload"></div>
            </div>
          )}
          {bannerVisible && bannerText && (
            <div className="subscription__banner">
              <div className="subscription__text">
                <span>{bannerText}</span>
              </div>
            </div>
          )}
          <div
            className={`plans-modal-wrapper ${
              isVoucherModalOpen ? "show" : ""
            }`}></div>
          <div
            className={`modal-container voucher-modal ${
              isVoucherModalOpen ? "show" : ""
            }`}>
            <div
              className={`voucher-modal-dialog ${
                isVoucherModalOpen ? "show" : ""
              }`}>
              <div className="modal-content">
                <div className="modal-header">
                  <span className="modal-title small-font">
                    Enter Coupon Code
                  </span>
                  <button
                    className="close"
                    onClick={() => {
                      setRedeemCode("");
                      setInfoError({ ...infoError, redeemText: "" });
                      setIsVoucherModalOpen(false);
                    }}>
                    <span>&#xD7;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form
                    className="voucher-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                      redeemVoucher(redeemCode);
                    }}>
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control redeem_code_input"
                        id="redeem_code"
                        placeholder=""
                        value={redeemCode}
                        onChange={(e) => {
                          if (infoError["redeemText"] !== "") {
                            setInfoError({ ...infoError, redeemText: "" });
                          }
                          setRedeemCode(e.target.value);
                        }}
                      />
                      {infoError.redeemText !== "" ? (
                        <div className="connection__fail">
                          <p>{infoError.redeemText}</p>
                        </div>
                      ) : null}
                    </div>
                    <input
                      type="submit"
                      disabled={redeemCode ? false : true}
                      className="btn btn-green-variant change-plan-btn change-plan-btn"
                      value="Redeem"
                    />
                  </form>
                </div>
                {/* <div className="modal-footer">
              <button
                className="btn btn-green-variant change-plan-btn"

                onClick={() => {
                  redeemVoucher(redeemCode);
                }}>
                Redeem
              </button>
            </div> */}
              </div>
            </div>
          </div>

          <div
            className={`plans-modal-wrapper ${
              isUpgradeModalOpen ? "show" : ""
            }`}></div>
          <div
            className={`modal-container plans-modal ${
              isUpgradeModalOpen ? "show" : ""
            }`}>
            <div
              className={`plans-modal-dialog ${
                isUpgradeModalOpen ? "show" : ""
              }`}>
              <div className="modal-content">
                <div className="modal-header">
                  <span className="modal-title">
                    {inPlan ? "Upgrade " : "Opt "} to {optedPlan} Plan
                  </span>
                  <button
                    className="close"
                    onClick={() => setIsUpgradeModalOpen(false)}>
                    <span>&#xD7;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p>Please confirm the change of plan.</p>
                  <div className="det-container">
                    <div className="plan-det box p-3">
                      <span className="det-span">
                        {inPlan ? currentPlanDetails?.plan_name : "No Plan"}
                      </span>
                      <div>
                        {currentPlanDetails?.pricing ||
                          "Currently not on any plan"}
                      </div>
                      {inPlan && (
                        <span className="highlighted-span">Current Plan</span>
                      )}
                    </div>
                    <div className="px-3"> &rarr; </div>
                    <div className="plan-det box p-3 box-selected">
                      <span className="det-span">
                        {plansList &&
                          plansList?.filter((x) => x?.plan_id === optedPlan)[0]
                            ?.plan_name}{" "}
                        (New Plan)
                      </span>
                      <div>
                        {plansList &&
                          plansList?.filter((x) => x?.plan_id == optedPlan)[0]
                            ?.pricing}
                      </div>
                      <span className="highlighted-span">New Plan</span>
                    </div>
                  </div>
                  <div className="plans_alert_container">
                    <span>
                      <img src={orange_alert} />
                    </span>
                    {inPlan ? (
                      optedPlan && optedPlan?.toLowerCase() === "enterprise" ? (
                        <p>
                          Please contact <b>sales@chaosgenius.io</b> to activate
                          your <b> Enterprise Plan</b>
                        </p>
                      ) : currentPlanDetails?.plan_id?.toLowerCase() ===
                          "free" &&
                        (optedPlan?.toLowerCase() === "business" ||
                          optedPlan?.toLowerCase() === "pro") ? (
                        <p>
                          We will immediately charge your credit card. Your
                          subscription on the new plan will begin immediately.
                        </p>
                      ) : currentPlanDetails?.plan_id?.toLowerCase() ===
                          "pro" && optedPlan?.toLowerCase() === "business" ? (
                        <p>
                          We will charge your credit card for the prorated
                          difference between the two plans on {renewalDate}.
                          Your subscription will be upgraded immediately.
                        </p>
                      ) : currentPlanDetails?.plan_id?.toLowerCase() ===
                          "business" &&
                        (optedPlan?.toLowerCase() === "pro" ||
                          optedPlan?.toLowerCase() === "free") ? (
                        <p>
                          Your subscription to the new plan will begin on{" "}
                          {renewalDate}
                        </p>
                      ) : currentPlanDetails?.plan_id?.toLowerCase() ===
                          "pro" && optedPlan?.toLowerCase() === "free" ? (
                        <p>
                          Your subscription to the new plan will begin on{" "}
                          {renewalDate}
                        </p>
                      ) : (
                        <p>
                          Your subscription on the new plan will begin
                          immediately.
                        </p>
                      )
                    ) : optedPlan &&
                      optedPlan?.toLowerCase() === "enterprise" ? (
                      <p>
                        Please contact <b>sales@chaosgenius.io</b> to activate
                        your
                        <b> Enterprise Plan</b>
                      </p>
                    ) : optedPlan && optedPlan?.toLowerCase() === "free" ? (
                      <p>
                        Your subscription on the new plan will begin
                        immediately.
                      </p>
                    ) : (
                      <p>
                        We will immediately charge your credit card. Your
                        subscription on the new plan will begin immediately.
                      </p>
                    )}
                  </div>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-green-variant change-plan-btn"
                    onClick={
                      inPlan
                        ? optedPlan?.toLowerCase() === "enterprise"
                          ? () => {
                              setIsUpgradeModalOpen(false);
                            }
                          : () => {
                              setModalProgress(true);
                              upgradePlan();
                            }
                        : optedPlan?.toLowerCase() === "enterprise"
                        ? () => {
                            setIsUpgradeModalOpen(false);
                          }
                        : optToPlan
                    }
                    disabled={modalProgress}>
                    {inPlan
                      ? optedPlan?.toLowerCase() === "enterprise"
                        ? "Okay"
                        : "Change Plan"
                      : optedPlan?.toLowerCase() === "enterprise"
                      ? "Okay"
                      : "Subscribe"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            className={`plans-modal-wrapper ${
              isCancelModalOpen ? "show" : ""
            }`}></div>
          <div
            className={`modal-container cancel-modal ${
              isCancelModalOpen ? "show" : ""
            }`}>
            <div
              className={`cancel-modal-dialog ${
                isCancelModalOpen ? "show" : ""
              }`}>
              <div className="modal-content">
                <div className="modal-header">
                  <span className="modal-title cancel-title">
                    End Subscription
                  </span>
                  <button
                    className="close"
                    onClick={() => setIsCancelModalOpen(false)}>
                    <span>&#xD7;</span>
                  </button>
                </div>
                <div className="modal-body">
                  {/* {currentPlanDetails?.plan_name === "Trial" ||
              currentPlanDetails?.plan_name?.toLowerCase() === "free" ? (
                <>
                  <p>
                    If you end your subscription, your benefits will end
                    immediately and Chaos Genius will not charge your credit card
                    again.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Are you sure you want to cancel your subscription? You won’t
                    be able to access your dashboard starting next month.
                  </p>
                  <p>
                    Can we change your mind? We are committed to providing a
                    best-in-class experience and are happy to help if you email{" "}
                    <b>support@chaosgenius.io.</b>
                  </p>
                </>
              )} */}
                  <p>Are you sure you want to cancel your subscription?</p>
                  <p>
                    Can we change your mind? We are committed to providing a
                    best-in-class experience and are happy to help if you email{" "}
                    <b>support@chaosgenius.io.</b>
                  </p>
                </div>
                <div className="modal-footer">
                  <button
                    className="btn btn-outline-black keep-plan-btn"
                    onClick={() => {
                      setIsCancelModalOpen(false);
                    }}
                    disabled={modalProgress}>
                    Keep Subscription
                  </button>
                  <button
                    className="btn btn-outline-danger cancel-plan-btn"
                    onClick={() => {
                      setModalProgress(true);
                      cancelPlan();
                    }}
                    disabled={modalProgress}>
                    Cancel Subscription
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="billing-container">
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container"></div>
                ) : null}
                <div className="header">
                  <span>Billing and Plans</span>
                </div>
              </div>
            </div>
            <div className="billing-card-container">
              <div className="billing-card">
                <div className="billing-card-header-container">
                  <div className="billing-card-header">
                    <span>Usage</span>
                  </div>
                </div>
                <div className="billing-card-usage-container">
                  <div className="usage-text">
                    <span>
                      {currentPlanDetails?.plan_name == "No Plan"
                        ? `$${usage?.toFixed(0)}`
                        : `$${usage?.toFixed(0)} ${
                            usageLimit?.toString() !== "-1"
                              ? `of ${usageLimit?.toFixed(0)}`
                              : ""
                          }`}

                      {/* ${usage.toFixed(0)}{" "}
                  {usageLimit !== -1 && <> of ${usageLimit.toFixed(0)}</>}
                  {currentPlanDetails?.plan_name == "No Plan" && " (No Plan)"} */}
                    </span>
                  </div>
                  <div className="usage-desc">
                    <span>
                      {`Your usage is based on your previous month of spend across 
                  ${
                    currentPlanDetails?.num_data_wh_acc > 0
                      ? `${currentPlanDetails?.num_data_wh_acc} Snowflake Accounts.`
                      : "all your integrations."
                  }`}
                    </span>
                  </div>
                </div>
              </div>
              <div className="billing-card">
                <div className="billing-card-header-container">
                  <div className="billing-card-header">
                    <span>Plans</span>
                  </div>
                  <div className="billing-manage">
                    {/* <button
                  className="btn btn-outline btn-manage"
                  onClick={() => {
                    setIsVoucherModalOpen(true);
                  }}>
                  Add Voucher
                </button>
                <button
                  className="btn btn-outline btn-manage"
                  onClick={() => {
                    window.open(
                      "https://www.chaosgenius.io/#sf-pricing",
                      "_blank"
                    );
                  }}>
                  Plans Info
                </button> */}
                    <button
                      className="btn btn-outline btn-manage"
                      onClick={getManageBilling}>
                      Manage Billing
                    </button>
                  </div>
                </div>
                <div className="usage-table-container">
                  <div className="usage-table-header">
                    {currentPlanDetails?.plan_name?.toLowerCase() ===
                    "no plan" ? (
                      <p className="renew-text">
                        You have not opted to any plan yet.
                      </p>
                    ) : (
                      <p className="renew-text">
                        {currentPlanDetails?.plan_name === "Trial" ? (
                          <>
                            Your <b>Trial</b> subscription expires on{" "}
                            <b>{renewalDate}</b>
                          </>
                        ) : currentPlanDetails?.change_plan_to ? (
                          currentPlanDetails?.change_plan_to === "none" ? (
                            <>
                              Your {"   "}
                              <b>{currentPlanDetails?.plan_name} </b>
                              subscription will expire on{" "}
                              <b>{renewalDate ? renewalDate : ""}</b>
                            </>
                          ) : (
                            <>
                              Your {"   "}
                              <b>{currentPlanDetails?.plan_name} </b> plan
                              changes to{" "}
                              <b className="capitalize__text">
                                {getPlanFromList(
                                  currentPlanDetails?.change_plan_to
                                )}
                              </b>{" "}
                              on
                              <b> {renewalDate ? renewalDate : ""}</b>
                            </>
                          )
                        ) : (
                          <>
                            Your {"   "}
                            <b>{currentPlanDetails?.plan_name} </b>
                            subscription will renew on{" "}
                            <b>{renewalDate ? renewalDate : ""}</b>
                          </>
                        )}
                      </p>
                    )}
                    <a
                      className="plans-info-link"
                      rel="noreferrer"
                      href="https://www.chaosgenius.io/pricing.html"
                      target="_blank">
                      <span>View Plans</span> {`->`}
                    </a>
                  </div>
                  <div className="usage-table-wrapper">
                    <table>
                      <thead>
                        <tr>
                          <th>Subscription</th>
                          <th>Price</th>
                          <th>Max Usage</th>
                          <th>Snowflake Accounts</th>
                          <th className="txt__align__cntr"></th>
                        </tr>
                      </thead>
                      <tbody>
                        {plansList.map((plan) => {
                          return plan?.plan_name !== "Trial" ? (
                            <tr
                              key={plan.plan_id}
                              className={`${
                                plan.is_eligible ? "" : "disabled"
                              } ${
                                currentPlanDetails?.plan_name ===
                                plan?.plan_name
                                  ? "selected"
                                  : ""
                              }`}>
                              <td>
                                <label>
                                  <input
                                    type="radio"
                                    name="plan"
                                    value={plan?.plan_id}
                                    className="form-check-input"
                                    disabled={!plan?.is_eligible}
                                    checked={optedPlan === plan?.plan_id}
                                    onChange={(e) => {
                                      setOptedPlan(e.target.value);
                                    }}
                                  />
                                  {plan.plan_name}
                                </label>
                              </td>
                              <td>{plan?.pricing}</td>
                              <td>
                                {plan?.max_usage == -1
                                  ? "Unlimited"
                                  : `$${plan?.max_usage}`}
                              </td>
                              <td>
                                {plan?.max_sf_accounts?.toString() === "-1"
                                  ? "Unlimited"
                                  : plan?.max_sf_accounts}
                              </td>
                              <td className="txt__align__cntr">
                                {currentPlanDetails?.plan_name ===
                                  plan?.plan_name && (
                                  <a
                                    className={`link ${
                                      currentPlanDetails?.change_plan_to ===
                                      "none"
                                        ? "disabled"
                                        : ""
                                    }`}
                                    onClick={() => {
                                      setIsCancelModalOpen(true);
                                    }}>
                                    Cancel
                                  </a>
                                )}
                              </td>
                            </tr>
                          ) : null;
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="update-subs-container">
                    <button
                      className="btn btn-link btn-voucher"
                      onClick={() => {
                        setIsVoucherModalOpen(true);
                      }}>
                      +Add Voucher
                    </button>
                    <div
                      className={`update-subscription ${
                        optedPlan == null ||
                        optedPlan == currentPlanDetails?.plan_id
                          ? "hidden"
                          : ""
                      }`}>
                      <button
                        className={`btn btn-green-variant btn-plans-upgrade`}
                        disabled={
                          currentPlanDetails?.change_plan_to &&
                          currentPlanDetails?.change_plan_to ===
                            optedPlan?.toLowerCase()
                        }
                        onClick={() => {
                          setIsUpgradeModalOpen(true);
                        }}>
                        {inPlan ? "Change to " : "Opt to "}{" "}
                        {optedPlan &&
                          plansList.filter((x) => x.plan_id == optedPlan)[0]
                            .plan_name}{" "}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="billing__fallback">
            <div className="fallback__block">
              <div className="img__container">
                <img src={billing__fallback} />
              </div>
              <div className="fallback__heading">
                <div className="header">
                  <span>Access only available for administrators</span>
                </div>
                <div className="sub__header">
                  <span>
                    Please contact your admin to fix this issue or contact{`  `}
                    <a href="mailto:support@chaosgenius.io">
                      support@chaosgenius.io
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
Billing.propTypes = {};
export default Billing;
