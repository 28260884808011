import { CustomTooltip } from "../../utils/tooltip-helper";
import { roundToSigDigits } from "../../utils/common-helper";
const customProps = {
  index: {
    type: "rightAligned",
    width: 75
  },
  warehouse_name: {
    width: 200,
    filter: true,
    cellRenderer: (params) => {
      return CustomTooltip(params.value, params.value, 10);
    },
    cellClass: "grid-cell-left-aligned"
  },
  sum_warehouse_cost: {
    width: 150,
    type: "rightAligned",
    cellRenderer: (params) => {
      return roundToSigDigits(params?.value, 2, false);
    }
  },
  sum_credits_used: {
    width: 150,
    type: "rightAligned",
    cellRenderer: (params) => {
      return roundToSigDigits(params?.value, 2, false);
    }
  },
  warehouse_size: {
    width: 100,
    cellClass: "grid-cell-left-aligned"
  },
  warehouse_scaling_policy: {
    width: 150,
    cellClass: "grid-cell-left-aligned"
  },
  warehouse_cluster_size_min_max: {
    width: 145,
    type: "rightAligned"
  },
  warehouse_auto_suspend: {
    width: 140,
    type: "rightAligned",
    cellRenderer: (params) => {
      return roundToSigDigits(params?.value, 2, false);
    }
  },
  warehouse_auto_resume: {
    width: 140,
    cellClass: "grid-cell-left-aligned"
  },
  enable_query_acceleration: {
    width: 140,
    cellClass: "grid-cell-left-aligned"
  },
  query_acceleration_max_scale_factor: {
    width: 160,
    cellClass: "grid-cell-left-aligned"
  },
  warehouse_type: {
    width: 150,
    cellClass: "grid-cell-left-aligned"
  },
  warehouse_idle_creds: {
    width: 150,
    type: "rightAligned",
    cellRenderer: (params) => {
      return roundToSigDigits(params?.value, 2, false);
    }
  },
  warehouse_owner: {
    width: 100,
    cellClass: "grid-cell-left-aligned",
    cellRenderer: (params) => {
      return CustomTooltip(params.value, params.value, 10);
    }
  },
  avg_query_cost: {
    width: 145,
    type: "rightAligned",
    cellRenderer: (params) => {
      return roundToSigDigits(params?.value, 2, false);
    }
  },
  sum_gb_spilled_to_remote_storage: {
    width: 155,
    type: "rightAligned",
    cellRenderer: (params) => {
      return roundToSigDigits(params?.value, 2, false);
    }
  },
  avg_gb_spilled_to_remote_storage: {
    width: 155,
    type: "rightAligned",
    cellRenderer: (params) => {
      return roundToSigDigits(params?.value, 2, false);
    }
  }
};

export const getWHOverviewProps = () => {
  return customProps;
};
