import React, { useState, useEffect } from "react";
import "./dashboard.scss";
import AppHeader from "../../components/AppHeader";
//import DashboardRoutes from "../../routes/DashboardRoutes";
import {
  addDashboardIcons,
  //getDashboardHeader,
  getDashboardHeaderNew,
  getDashboardKeys,
  getDashboardLink
} from "../../utils/dashboard-helper";
import { useAuth } from "../../utils/auth-helper";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Sidebar from "../../components/Sidebar";
import { getDWAcc } from "../../utils/user-helper";
import { getSessionStorage } from "../../utils/storage-helper";
import ViewController from "../ViewController";
import ParentRoutes from "../../routes/ParentRoutes";
const Dashboard = () => {
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const [token] = useState(userInfo?.token);
  const [headerMenu, setHeaderMenu] = useState([]);
  const [activeModule, setActiveModule] = useState("");
  const [acc, setAcc] = useState(null);
  const [activePage, setActivePage] = useState("");
  const [dwAccOptions, setDwAccOptions] = useState([]);
  const [isMenuLoading, setIsMenuLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const params = useParams();
  const [rawData, setRawData] = useState(null);
  const [isSlackAlertSetup, setIsSlackAlertSetup] = useState(false);
  const [isTeamAlertSetup, setIsTeamAlertSetup] = useState(false);
  const [slackAlert, setSlackAlert] = useState({});
  const [TeamsAlert, setTeamsAlert] = useState({});
  const { pathname } = useLocation();

  const clearData = () => {
    setActiveModule("");
    setActivePage("");
    const data = [
      {
        is_module: true,
        module_name: "Data Source",
        page_display_name: "Data Source",
        page_url: undefined,
        dontDisplayChildren: true
      },
      {
        is_module: false,
        module_name: "Data Source",
        page_display_name: "Select Connection",
        page_url: undefined
      }
    ];
    setRawData(data);
    const menuList = createMenuList(data);
    setHeaderMenu(menuList);
  };

  const profileMenuData = [
    {
      img: "Organization Settings",
      is_module: false,
      link: "Organization Settings",
      module_name: "Settings",
      page_display_name: "Organization Settings",
      page_url: ""
    },
    {
      img: "Alert Settings",
      is_module: false,
      link: "Alert Settings",
      module_name: "Settings",
      page_display_name: "Alert Settings",
      page_url: ""
    },
    {
      img: "Billing",
      is_module: false,
      link: "Billing",
      isHidden: false,
      module_name: "Settings",
      page_display_name: "Billing",
      page_url: ""
    },
    {
      img: "SSO Configuration",
      is_module: false,
      link: "SSO Configuration",
      module_name: "Settings",
      page_display_name: "SSO Configuration",
      page_url: ""
    },
    {
      img: "Settings",
      is_module: true,
      link: "Settings",
      module_name: "",
      page_display_name: "Settings",
      page_url: ""
    }
  ];
  const chargeBackMenuData = [
    {
      img: "Chargeback",
      is_module: false,
      link: "Cost Reporting",
      module_name: "Chargeback",
      page_display_name: "Cost Reporting",
      page_url: ""
    },
    {
      img: "Chargeback",
      is_module: false,
      link: "Cost Center",
      module_name: "Chargeback",
      page_display_name: "Cost Center",
      page_url: ""
    },
    {
      img: "Chargeback",
      is_module: true,
      link: "Chargeback",
      module_name: "Chargeback",
      page_display_name: "Chargeback",
      page_url: ""
    }
  ];
  const profileMenu = [
    {
      children: [
        {
          img: "Organization Settings",
          is_module: false,
          link: "Organization Settings",
          module_name: "Settings",
          page_display_name: "Organization Settings",
          page_url: ""
        },
        {
          img: "Alert Settings",
          is_module: false,
          link: "Alert Settings",
          module_name: "Settings",
          page_display_name: "Alert Settings",
          page_url: ""
        },
        {
          img: "Billing",
          is_module: false,
          link: "Billing",
          isHidden: false,
          module_name: "Settings",
          page_display_name: "Billing",
          page_url: ""
        },
        {
          img: "SSO Configuration",
          is_module: false,
          link: "SSO Configuration",
          module_name: "Settings",
          page_display_name: "SSO Configuration",
          page_url: ""
        }
      ],
      img: "Settings",
      is_module: true,
      link: "Settings",
      module_name: "",
      page_display_name: "Settings",
      page_url: ""
    }
  ];

  const changeSlackAlertSetup = (bool) => {
    setIsSlackAlertSetup(bool);
  };
  const changeTeamsAlertSetup = (bool) => {
    setIsTeamAlertSetup(bool);
  };

  const createList = (modules, children) => {
    modules?.forEach((module) => {
      module["children"] = children.filter((child) => {
        return child.module_name === module?.page_display_name;
      });
    });
    return modules;
  };
  const createMenuList = (data) => {
    let list = [];
    if (data && data.length) {
      const modules = addDashboardIcons(
        data?.filter((item) => {
          return item?.is_module;
        })
      );

      const children = addDashboardIcons(
        data?.filter((item) => {
          return !item?.is_module;
        })
      );
      list = createList(modules, children);
    }
    return list;
  };

  const changeActiveModule = (
    str,
    url,
    page = undefined,
    param = undefined
  ) => {
    setActiveModule(str);
    if (!page) {
      setActivePage("");
    } else {
      setActivePage(page);
    }
    const link = getDashboardLink(page ? page : str, str);
    setUrl(url);
    if (param && param !== link) {
      navigate(`/dashboard/${param}`);
    } else {
      navigate(`/dashboard/${link}`);
    }
  };

  const changeActivePage = (str, url, module_name) => {
    let module =
      headerMenu &&
      headerMenu?.length &&
      headerMenu?.find((menu) => {
        return (
          str ===
          menu?.children?.find((child) => {
            return (
              child?.page_display_name === str &&
              child?.module_name === module_name
            );
          })?.page_display_name
        );
      });
    if (module) {
      setActivePage(str);
      changeActiveModule(module?.page_display_name, url, str);
    } else {
      module =
        profileMenu &&
        profileMenu.length &&
        profileMenu?.find((menu) => {
          return (
            str ===
            menu?.children?.find((child) => {
              return (
                child?.page_display_name === str &&
                child?.module_name === module_name
              );
            })?.page_display_name
          );
        });
      if (module) {
        setActivePage(str);
        changeActiveModule(module?.page_display_name, url, str);
      }
    }
  };

  const changeAcc = (e) => {
    setAcc(e);
    if (!e?.setup_done) {
      clearData();
      navigate("/dashboard/connectionfallback");
    } else {
      const param = pathname?.replace("/dashboard/", "");
      if (param === "connectionfallback" || "") {
        navigate("");
        navigate(0);
      } else {
        if (!acc?.setup_done) {
          clearData();
          setIsLoading(true);
          setIsMenuLoading(true);
          getMenu();
        }
      }
    }
  };

  const changeSlackAlert = (status) => {
    setSlackAlert(status);
  };
  const changeTeamAlert = (status) => {
    setTeamsAlert(status);
  };
  const getAccOptions = (arr) => {
    const options = arr?.map((opt) => {
      return {
        id: opt?.data_warehouse_info_id,
        value: opt?.display_name,
        label: opt?.display_name,
        ...opt
      };
    });
    return options;
  };
  const getIncompleteDW = (dwArr) => {
    let incompleteArr = [];
    incompleteArr =
      dwArr &&
      dwArr?.filter((acc) => {
        return (
          (acc?.account_id.toString() !== "1" &&
            acc?.num_contracts.toString() === "0") ||
          !acc?.num_contracts
        );
      });
    return incompleteArr;
  };
  const getMenu = async () => {
    let data;
    const orgonboarded = getSessionStorage("orgonboarded");
    try {
      data = await getDashboardHeaderNew();
      if (userInfo?.info?.role === "admin") {
        data.push({
          is_module: true,
          module_name: "Organization",
          page_display_name: "Organization",
          page_url: undefined,
          dontDisplayChildren: true
        });
        data.push({
          is_module: false,
          module_name: "Organization",
          page_display_name: "Organization",
          page_url: undefined
        });
        data.push(...chargeBackMenuData);
      }
      data.push({
        is_module: true,
        module_name: "Data Source",
        page_display_name: "Data Source",
        page_url: undefined,
        dontDisplayChildren: true
      });
      data.push({
        is_module: false,
        module_name: "Data Source",
        page_display_name: "Select Connection",
        page_url: undefined
      });
      data.unshift({
        is_module: false,
        module_name: "Home",
        page_display_name: "Home Dashboard",
        page_url: undefined
      });
      data.unshift({
        is_module: true,
        module_name: "Home",
        page_display_name: "Home",
        page_url: undefined,
        dontDisplayChildren: true
      });
      setRawData(data);
      const dwAcc = await getDWAcc();

      if (!userInfo?.info?.onboarded) {
        navigate("/onboard");
        return;
      }
      if (!orgonboarded) {
        navigate("/onboard/invite");
        return;
      }
      if (
        userInfo?.info?.redirect_to_billing.toString() === "false" &&
        (!dwAcc || dwAcc.length == 0)
      ) {
        navigate("/selectconnection/");
        return;
      }

      const incompleteDW = getIncompleteDW(dwAcc);
      if (
        userInfo?.info?.redirect_to_billing.toString() === "false" &&
        (!incompleteDW || incompleteDW?.length > 0)
      ) {
        navigate(
          `/selectconnection/connectaccounts/SF/step_3/${incompleteDW[0].data_warehouse_info_id}`
        );

        setIsLoading(false);
        setIsMenuLoading(false);
      } else {
        if (data) {
          const menuList = createMenuList(data);
          const activeItemName = getDashboardKeys(params && params["*"]);
          let activeItem = data?.find((item) => {
            return (
              item?.page_display_name === activeItemName?.name &&
              item?.module_name === activeItemName?.module
            );
          });
          if (!activeItem) {
            activeItem = profileMenuData?.find((item) => {
              return (
                item?.page_display_name === activeItemName?.name &&
                item?.module_name === activeItemName?.module
              );
            });
          }
          setHeaderMenu(menuList);
          if (activeItem) {
            if (
              userInfo?.info?.redirect_to_billing.toString() === "false" ||
              (activeItem?.module_name === "Settings" &&
                activeItem?.page_display_name === "Organization Settings")
            ) {
              if (activeItem?.is_module) {
                changeActiveModule(
                  activeItem?.page_display_name,
                  activeItem?.page_url,
                  activeItem?.children
                    ? activeItem?.children[0].page_display_name
                    : undefined
                );
              } else {
                let module =
                  menuList &&
                  menuList.length &&
                  menuList?.find((menu) => {
                    return (
                      activeItem.page_display_name ===
                      menu?.children?.find((child) => {
                        return (
                          child?.page_display_name ===
                            activeItem.page_display_name &&
                          child?.module_name === activeItem?.module_name
                        );
                      })?.page_display_name
                    );
                  });
                if (module) {
                  setActivePage(activeItem.page_display_name);
                  changeActiveModule(
                    module?.page_display_name,
                    activeItem.page_url,
                    activeItem.page_display_name,
                    params && params["*"]
                  );
                } else {
                  module =
                    profileMenu &&
                    profileMenu.length &&
                    profileMenu?.find((menu) => {
                      return (
                        activeItem.page_display_name ===
                        menu?.children?.find((child) => {
                          return (
                            child?.page_display_name ===
                              activeItem.page_display_name &&
                            child?.module_name === activeItem?.module_name
                          );
                        })?.page_display_name
                      );
                    });

                  setActivePage(activeItem.page_display_name);
                  changeActiveModule(
                    module?.page_display_name,
                    activeItem.page_url,
                    activeItem.page_display_name
                  );
                }
              }
            } else {
              setActivePage("Billing");
              changeActiveModule("Settings", "", "Billing");
            }
          } else if (
            params["*"] === "" ||
            !params["*"] === "connectionfallback"
          ) {
            if (userInfo?.info?.redirect_to_billing.toString() === "false") {
              setActivePage("Home");
              changeActiveModule(
                "Home",
                "",
                module?.children && !module?.dontDisplayChildren
                  ? module?.children[0]?.page_display_name
                  : undefined
              );
            } else {
              setActivePage("Billing");
              changeActiveModule("Settings", "", "Billing");
            }
          } else {
            clearData();
            navigate("/dashboard/home");
            navigate(0);
            return;
          }
          // setActiveView(menuList[0]?.page_url);
        }
        if (dwAcc && dwAcc.length) {
          const dw_acc_id = getSessionStorage("dw_acc_id");
          const options = getAccOptions(dwAcc);
          setDwAccOptions(options ? options : []);
          if (dw_acc_id) {
            const accObj = options.find((opt) => {
              return opt?.id.toString() === dw_acc_id;
            });
            if (accObj && Object.keys(accObj)?.length) {
              setAcc(accObj);
              if (!accObj?.setup_done) {
                clearData();
                navigate("/dashboard/connectionfallback");
                setIsLoading(false);
                setIsMenuLoading(false);
                return;
              }
            }
          } else {
            setAcc(options[0]);
            if (!options[0]?.setup_done) {
              clearData();
              const data = [
                {
                  is_module: true,
                  module_name: "Data Source",
                  page_display_name: "Data Source",
                  page_url: undefined,
                  dontDisplayChildren: true
                },
                {
                  is_module: false,
                  module_name: "Data Source",
                  page_display_name: "Select Connection",
                  page_url: undefined
                }
              ];
              setRawData(data);
              const menuList = createMenuList(data);
              setHeaderMenu(menuList);
              if (!params["*"]?.includes("settings")) {
                navigate("/dashboard/connectionfallback");
              }
              setIsLoading(false);
              setIsMenuLoading(false);
              return;
            }
          }
        }
        setIsMenuLoading(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.log(error);
    }
  };
  useEffect(() => {
    setIsMenuLoading(true);
    getMenu();
  }, []);

  useEffect(() => {
    const param = pathname?.replace("/dashboard/", "");
    const activeItemName = getDashboardKeys(param);
    if (rawData) {
      let activeItem = rawData?.find((item) => {
        return (
          item?.page_display_name === activeItemName?.name &&
          item?.module_name === activeItemName?.module
        );
      });
      if (!activeItem) {
        activeItem = profileMenuData?.find((item) => {
          return (
            item?.page_display_name === activeItemName?.name &&
            item?.module_name === activeItemName?.module
          );
        });
      }
      if (activeItem) {
        if (activeItem?.is_module) {
          setActiveModule(activeItem?.page_display_name);
          if (!activeItem?.children) {
            setActivePage("");
          } else {
            setActivePage(activeItem?.children[0]?.page_display_name);
          }
          setUrl(activeItem?.page_url);
        } else {
          let module =
            headerMenu &&
            headerMenu.length &&
            headerMenu?.find((menu) => {
              return (
                activeItem.page_display_name ===
                menu?.children?.find((child) => {
                  return (
                    child?.page_display_name === activeItem.page_display_name &&
                    child?.module_name === activeItem?.module_name
                  );
                })?.page_display_name
              );
            });
          if (module) {
            setActivePage(activeItem.page_display_name);
            setActiveModule(module?.page_display_name);
            setUrl(activeItem?.page_url);
          } else {
            module =
              profileMenu &&
              profileMenu.length &&
              profileMenu?.find((menu) => {
                return (
                  activeItem.page_display_name ===
                  menu?.children?.find((child) => {
                    return (
                      child?.page_display_name ===
                        activeItem.page_display_name &&
                      child?.module_name === activeItem?.module_name
                    );
                  })?.page_display_name
                );
              });

            setActivePage(activeItem.page_display_name);
            changeActiveModule(
              module?.page_display_name,
              activeItem.page_url,
              activeItem.page_display_name,
              params && params["*"]
            );
          }
        }
      }
    } else {
      if (acc && Object.keys(acc)?.length) {
        if (!acc?.setup_done) {
          const data = [
            {
              is_module: true,
              module_name: "Data Source",
              page_display_name: "Data Source",
              page_url: undefined,
              dontDisplayChildren: true
            },
            {
              is_module: false,
              module_name: "Data Source",
              page_display_name: "Select Connection",
              page_url: undefined
            }
          ];
          setRawData(data);
          const menuList = createMenuList(data);
          setHeaderMenu(menuList);
          if (!param?.includes("settings")) {
            navigate("/dashboard/connectionfallback");
          }
        }
      }
    }
  }, [pathname]);

  return (
    <div className="app-container">
      <AppHeader
        {...(headerMenu && headerMenu.length && acc && dwAccOptions
          ? {
              headerMenu: headerMenu,
              activeModule: activeModule,
              activePage: activePage,
              changeActiveModule: changeActiveModule,
              changeActivePage: changeActivePage,
              isMenuLoading,
              acc,
              dwAccOptions,
              changeAcc
            }
          : {
              isMenuLoading,
              acc,
              dwAccOptions,
              activeModule: activeModule,
              activePage: activePage,
              changeActiveModule: changeActiveModule,
              changeActivePage: changeActivePage,
              changeAcc
            })}
      />
      <div className="main-block">
        {!isLoading && (
          <Sidebar
            {...(headerMenu && headerMenu.length
              ? {
                  headerMenu: headerMenu,
                  activeModule: activeModule,
                  activePage: activePage,
                  changeActiveModule: changeActiveModule,
                  changeActivePage: changeActivePage,
                  isMenuLoading,
                  profileMenu: profileMenu,
                  userInfo: userInfo,
                  //visible: acc?.setup_done ? true : false
                  visible: true
                }
              : {
                  isMenuLoading,
                  // visible: acc?.setup_done ? true : false
                  visible: true
                })}
          />
        )}
        {!isLoading &&
          (activeModule === "Data Source" ||
          activeModule === "Home" ||
          activeModule === "Organization" ? (
            <ParentRoutes
              {...(headerMenu && headerMenu.length
                ? {
                    headerMenu: headerMenu,
                    activeModule: activeModule,
                    activePage: activePage,
                    changeActiveModule: changeActiveModule,
                    changeActivePage: changeActivePage,
                    isMenuLoading,
                    dwAccOptions,
                    profileMenu: profileMenu
                  }
                : {
                    isMenuLoading
                  })}
              url={url}
              token={token}
              acc={acc}
            />
          ) : (
            <ViewController
              {...(headerMenu && headerMenu.length
                ? {
                    headerMenu: headerMenu,
                    activeModule: activeModule,
                    activePage: activePage,
                    changeActiveModule: changeActiveModule,
                    changeActivePage: changeActivePage,
                    isMenuLoading,
                    dwAccOptions,
                    profileMenu: profileMenu,
                    userInfo
                  }
                : {
                    isMenuLoading,
                    activeModule: activeModule,
                    activePage: activePage,
                    changeActiveModule: changeActiveModule,
                    changeActivePage: changeActivePage,
                    profileMenu: profileMenu,
                    userInfo,
                    dwAccOptions
                  })}
              url={url}
              token={token}
              acc={acc}
              changeSlackAlertSetup={changeSlackAlertSetup}
              isSlackAlertSetup={isSlackAlertSetup}
              changeSlackAlert={changeSlackAlert}
              changeTeamAlert={changeTeamAlert}
              slackAlert={slackAlert}
              changeTeamsAlertSetup={changeTeamsAlertSetup}
              isTeamAlertSetup={isTeamAlertSetup}
              TeamsAlert={TeamsAlert}
              //hasSidebar={acc?.setup_done ? true : false}
              hasSidebar={true}
            />
          ))}
      </div>
      {isLoading && (
        <div className="load">
          <div className="preload"></div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
