import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Select, { components } from "react-select";

import chev_down from "../../assets/images/common/chev_down.svg";
import cancel from "../../assets/images/grid/cancel.svg";
import cancel_black from "../../assets/images/grid/cancel_black.svg";
import Button from "../customElements/Button";
import DeleteIcon from "../customElements/DeleteIcon";
import {
  checkAllSelected,
  getUserDropdownsAPI,
  getWHDropdownsAPI
} from "../../utils/chargeback-helper";

function CreateResource({
  column,
  handleSwitch,
  index,
  deleteResource,
  handleChange,
  dwAccOptions
}) {
  const [defaultDropdowns, setDefaultDropdowns] = useState({
    account: dwAccOptions,
    wearehouse: [],
    user: [],
    wearehouseSelectAll: false,
    userSelectAll: false
  });
  const getallDropdowns = async (acc) => {
    try {
      const [userDropdown, whDropdown] = await Promise.all([
        getUserDropdownsAPI(acc.id),
        getWHDropdownsAPI(acc.id)
      ]);
      if (userDropdown && whDropdown) {
        userDropdown.unshift({
          label: "Select All",
          value: "Select All"
        });
        whDropdown.unshift({
          label: "Select All",
          value: "Select All"
        });
        setDefaultDropdowns({
          ...defaultDropdowns,
          user: userDropdown,
          wearehouse: whDropdown,
          account: dwAccOptions
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSelectedValue = (e, key, index) => {
    if (checkAllSelected(e) && !defaultDropdowns?.[`${key}SelectAll`]) {
      setDefaultDropdowns({ ...defaultDropdowns, [`${key}SelectAll`]: true });
      handleChange(defaultDropdowns?.[key], key, index);
    } else if (!checkAllSelected(e) && defaultDropdowns?.[`${key}SelectAll`]) {
      setDefaultDropdowns({ ...defaultDropdowns, [`${key}SelectAll`]: false });
      handleChange([], key, index);
    } else {
      if (
        !checkAllSelected(e) &&
        defaultDropdowns?.[key]?.length - 1 === e.length
      ) {
        setDefaultDropdowns({
          ...defaultDropdowns,
          [`${key}SelectAll`]: true
        });
        handleChange(defaultDropdowns?.[key], key, index);
      } else {
        const newValue = e.filter((item) => item.value !== "Select All");
        handleChange(newValue, key, index);
        setDefaultDropdowns({
          ...defaultDropdowns,
          [`${key}SelectAll`]: false
        });
      }
    }
  };
  useEffect(() => {
    getallDropdowns(column?.account);
  }, [column?.account]);
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img style={{ pointerEvents: "none" }} src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const Input = (props) => (
    <components.Input autoFocus={props.selectProps.menuIsOpen} {...props}>
      {props.children}
    </components.Input>
  );
  Input.propTypes = {
    selectProps: PropTypes.any,
    menuIsOpen: PropTypes.bool,
    children: PropTypes.any
  };
  const Option = (props) => (
    <components.Option {...props}>
      <div className="dropdown-list-item">
        <input
          key={props.value}
          type="checkbox"
          checked={props.isSelected}
          onChange={() => {}}
          style={{
            cursor: "pointer",
            position: "relative",
            width: "14px",
            height: "14px",
            maxWidth: "14px"
          }}
        />
        <label style={{ cursor: "pointer" }}>{props.label}</label>
      </div>
    </components.Option>
  );
  Option.propTypes = {
    props: PropTypes.any,
    value: PropTypes.string,
    isSelected: PropTypes.bool,
    label: PropTypes.string
  };
  const customStyle = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
    })
  };
  const customStyles = {
    multiValueLabel: (def) => ({
      ...def,
      backgroundColor: "#ebebeb"
    }),
    multiValueRemove: (def) => ({
      ...def,
      // display: "none",
      backgroundColor: "#ebebeb",
      "&:hover": {
        content: `url(${cancel}) `,
        backgroundColor: "#ebebeb", // Change the hover background color as per your preference
        cursor: "pointer"
      },
      content: `url(${cancel_black}) `
    }),
    valueContainer: (base) => ({
      ...base,
      maxHeight: "38px",
      overflow: "auto"
    }),
    option: (styles) => {
      return {
        ...styles,
        fontSize: "0.875rem",
        padding: "0.75rem",
        display: "flex",
        alignItems: "center",
        gap: "0.8rem"
      };
    },

    menu: (def) => ({ ...def, zIndex: 9999 })
  };
  return (
    <div
      className={index % 2 == 0 ? "main-wrapper even-row-bg" : "main-wrapper"}>
      <div id="accountRow" className="account-row-wrapper">
        <div className="account-input">
          <input disabled={true} defaultValue={"Account"} type="text" />
        </div>
        <div className="operator-input">
          <input disabled={true} defaultValue={"Equals"} type="text" />
        </div>
        <div className="value-input">
          <Select
            value={column?.account}
            options={defaultDropdowns?.account}
            styles={customStyle}
            classNamePrefix="selectcategory"
            components={{
              DropdownIndicator
            }}
            placeholder=""
            onChange={(e) => handleSelectedValue(e, "account", index)}
            onMenuClose={() => handleSwitch(index, "accountDropdown")}
            isSearchable={false}
            isMulti={false}
            menuPlacement="auto"
          />
        </div>
        <div onClick={() => deleteResource(index)} className="delete-filter">
          <DeleteIcon />
        </div>
      </div>
      <div id="whWrapper" className="wearehouse-row-container">
        <div className="wearehouse-row-wrapper">
          <div className="account-input">
            <input disabled={true} defaultValue={"Warehouse"} type="text" />
          </div>
          <div className="operator-input">
            <input disabled={true} defaultValue={"Equals"} type="text" />
          </div>
          <div className="value-input cost-value-container">
            {column?.wearehouseDropdown ? (
              <Select
                value={column?.wearehouse}
                options={defaultDropdowns?.wearehouse}
                styles={customStyles}
                name="column"
                classNamePrefix="selectcategory"
                components={{
                  DropdownIndicator,
                  Option,
                  Input
                }}
                placeholder=""
                isSearchable={true}
                onChange={(e) => handleSelectedValue(e, "wearehouse", index)}
                onMenuClose={() => handleSwitch(index, "wearehouseDropdown")}
                menuIsOpen={true}
                closeMenuOnSelect={false}
                isMulti={true}
                tabSelectsValue={true}
                hideSelectedOptions={false}
                blurInputOnSelect={false}
                isClearable={false}
                menuPlacement="auto"
              />
            ) : (
              <Button
                onClick={() => handleSwitch(index, "wearehouseDropdown")}
                className={"filter-button"}>
                {column?.wearehouse?.length <= 0 ? (
                  <>
                    <span>Select Value</span>
                    <img src={chev_down} alt="" />
                  </>
                ) : (
                  <>
                    {column?.wearehouse?.length > 1 ? (
                      <div className="chip-container">
                        <>
                          <div className="chip">
                            {column?.wearehouse?.[0]?.value}
                          </div>
                          {column?.wearehouse?.length >= 2 && (
                            <div className="chip">
                              {column?.wearehouse?.[1]?.value}
                            </div>
                          )}
                          {column?.wearehouse?.length >= 3 && (
                            <div className="chip2">
                              +{column?.wearehouse?.length - 2}
                            </div>
                          )}
                        </>
                        <img src={chev_down} alt="" />
                      </div>
                    ) : (
                      <div className="chip-container">
                        <div className="chip">
                          {column?.wearehouse?.[0].value}
                        </div>
                        <img src={chev_down} alt="" />
                      </div>
                    )}
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div id="whWrapper" className="wearehouse-row-container">
        <div className="wearehouse-row-wrapper">
          <div className="account-input">
            <input disabled={true} defaultValue={"User"} type="text" />
          </div>
          <div className="operator-input">
            <input disabled={true} defaultValue={"Equals"} type="text" />
          </div>
          <div className="value-input cost-value-container">
            {column?.useDropdown ? (
              <Select
                value={column?.user}
                options={defaultDropdowns?.user}
                styles={customStyles}
                name="column"
                classNamePrefix="selectcategory"
                components={{
                  DropdownIndicator,
                  Option,
                  Input
                }}
                placeholder=""
                isSearchable={true}
                onChange={(e) => handleSelectedValue(e, "user", index)}
                onMenuClose={() => handleSwitch(index, "useDropdown")}
                menuIsOpen={true}
                closeMenuOnSelect={false}
                isMulti={true}
                tabSelectsValue={true}
                hideSelectedOptions={false}
                blurInputOnSelect={false}
                isClearable={false}
                menuPlacement="auto"
              />
            ) : (
              <Button
                onClick={() => handleSwitch(index, "useDropdown")}
                className={"filter-button"}>
                {column.user?.length <= 0 ? (
                  <>
                    <span>Select Value</span>
                    <img src={chev_down} alt="" />
                  </>
                ) : (
                  <>
                    {column?.user?.length > 1 ? (
                      <div className="chip-container">
                        <>
                          <div className="chip">{column?.user?.[0]?.value}</div>
                          {column?.user?.length >= 2 && (
                            <div className="chip">
                              {column?.user?.[1]?.value}
                            </div>
                          )}
                          {column?.user?.length >= 3 && (
                            <div className="chip2">
                              +{column.user.length - 2}
                            </div>
                          )}
                        </>
                        <img src={chev_down} alt="" />
                      </div>
                    ) : (
                      <div className="chip-container">
                        <div className="chip">{column?.user?.[0].value}</div>
                        <img src={chev_down} alt="" />
                      </div>
                    )}
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
CreateResource.propTypes = {
  column: PropTypes.object,
  handleSwitch: PropTypes.func,
  index: PropTypes.number,
  defaultDropdowns: PropTypes.object,
  deleteResource: PropTypes.func,
  handleChange: PropTypes.func,
  dwAccOptions: PropTypes.array,
  acc: PropTypes.object
};
export default CreateResource;
