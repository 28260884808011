import React, { useCallback, useEffect, useMemo, useRef } from "react";
import "./gridcomponent.scss";
import PropTypes from "prop-types";
import { AgGridReact } from "ag-grid-react"; // the AG Grid React Component

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import CustomNoRowsOverlay from "../../utils/noRowsComponent";

const GridComponent = ({
  columnDefs,
  rowData,
  title,
  gridStyle,
  gridLoading,
  noRowsText,
  fallbackHTML,
  suppressColumnsSize,
  downloadCSV,
  hasTrialFallback,
  changeActiveModule,
  tableName,
  exportColumns,
  customRowHeight
}) => {
  const gridRef = useRef(); // Optional - for accessing Grid's API
  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    resizable: true
  }));
  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay.bind(this, changeActiveModule);
  }, []);
  const getRowStyle = (params) => {
    if (hasTrialFallback && rowData?.length > 2 && params.node?.rowIndex >= 2) {
      return {
        filter: "blur(4px)"
      };
    }
  };
  const onGridReady = () => {
    if (suppressColumnsSize) {
      gridRef.current.api.sizeColumnsToFit({
        defaultMinWidth: 100
      });
    }
    if (hasTrialFallback && rowData?.length > 2) {
      gridRef.current.api.showNoRowsOverlay();
    }
  };
  const style = {
    width: "100%",
    height: gridStyle?.height ? gridStyle?.height : 500
  };

  const defaultStyle = { rowHeight: customRowHeight ?? 30, headerHeight: 50 };

  const onBtnExport = () => {
    if (downloadCSV) {
      const params = {
        fileName: tableName,
        columnKeys: exportColumns
      };
      gridRef?.current?.api?.exportDataAsCsv(params);
    }
  };
  useEffect(() => {
    onBtnExport();
  }, [downloadCSV]);

  // Example of consuming Grid Event
  const cellClickedListener = useCallback(() => {}, []);
  return (
    <div
      className={`grid-container ${
        gridStyle?.trialFallbackClass ? gridStyle?.trialFallbackClass : ""
      }`}>
      {title && (
        <div className="title-container">
          <span>{title ? title : "Grid Title"}</span>
        </div>
      )}
      <div
        className={`grid ag-theme-alpine ${
          gridStyle?.noborder ? "noborder" : ""
        } ${gridStyle?.additionalClass ? gridStyle?.additionalClass : ""}`}
        style={style}>
        {!gridLoading && rowData && rowData?.length ? (
          <AgGridReact
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            onCellClicked={cellClickedListener} // Optional - registering for Grid Event
            domLayout={
              rowData?.length < 15 && !gridStyle?.avoidAutoHeightOverflow
                ? "autoHeight"
                : ""
            }
            rowHeight={
              gridStyle?.rowHeight
                ? gridStyle?.rowHeight
                : defaultStyle.rowHeight
            }
            noRowsOverlayComponent={noRowsOverlayComponent}
            headerHeight={defaultStyle?.headerHeight}
            getRowStyle={getRowStyle}
            onGridReady={onGridReady}
            suppressLoadingOverlay={true}
            enableCellTextSelection={true}
          />
        ) : null}
        {!gridLoading && rowData && !rowData?.length ? (
          fallbackHTML ? (
            <div className="no-rows">{fallbackHTML}</div>
          ) : (
            <div className="no-rows">
              <span>{noRowsText ? noRowsText : "Coming soon..."}</span>
            </div>
          )
        ) : null}
        {gridLoading || !rowData ? (
          <div className="load">
            <div className="preload"></div>
          </div>
        ) : null}
      </div>
    </div>
  );
};
GridComponent.propTypes = {
  columnDefs: PropTypes.array,
  rowData: PropTypes.array,
  title: PropTypes.string,
  gridStyle: PropTypes.object,
  gridLoading: PropTypes.bool,
  noRowsText: PropTypes.string,
  fallbackHTML: PropTypes.element,
  suppressColumnsSize: PropTypes.bool,
  downloadCSV: PropTypes.bool,
  hasTrialFallback: PropTypes.bool,
  changeActiveModule: PropTypes.func,
  tableName: PropTypes.string,
  customRowHeight: PropTypes.number,
  exportColumns: PropTypes.array
};
export default GridComponent;
