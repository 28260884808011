import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "./healthdashboard.scss";
import {
  getHealthDashboardDateRangeAPI,
  getHealthDashboardObj,
  getMetricID,
  getMetricSymbol,
  getMetricsDataAPI
} from "../../utils/dashboard-helper";
import front_arrow from "../../assets/images/dashboard/front_arrow.svg";
import Select, { components } from "react-select";
import { getValueObj, roundToSigDigits } from "../../utils/common-helper";
import chev_down from "../../assets/images/common/chev_down.svg";

const HealthDashboard = ({ changeActiveModule, acc }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [metrics, setMetrics] = useState(null);

  const setSequenceMetrics = (arr) => {
    if (arr && arr?.length) {
      arr?.forEach((obj) => {
        const id = getMetricID(obj?.name);
        obj.id = id;
      });
      arr.sort((a, b) => b?.estimated_impact - a?.estimated_impact);
    }
    setMetrics(arr);
  };

  const getData = async (acc, dateRange) => {
    try {
      const metricsObj = await getMetricsDataAPI(acc, dateRange);
      if (metricsObj?.dashboard_cards && metricsObj?.dashboard_cards?.length) {
        setSequenceMetrics(metricsObj?.dashboard_cards);
      }
      setIsPageLoading(false);
    } catch (er) {
      setIsPageLoading(false);
    }
  };
  useEffect(() => {
    const getAPIs = async () => {
      if (acc) {
        setIsPageLoading(true);
        try {
          let dateRangeArr = [];
          const dateRangeObj = await getHealthDashboardDateRangeAPI(acc?.id);
          if (dateRangeObj?.values) {
            dateRangeArr = getValueObj(dateRangeObj?.values);
            setDateRangeOptions(dateRangeArr);
            setDateRange(dateRangeArr[0]);
            getData(acc, dateRangeArr[0]);
          }
        } catch (er) {
          setIsPageLoading(false);
        }
      }
    };
    getAPIs();
  }, [acc]);
  const doRedirection = (obj) => {
    if (obj?.redirectTo?.module) {
      changeActiveModule(
        obj?.redirectTo?.module,
        "",
        obj?.redirectTo?.page,
        undefined
      );
    }
  };
  const metricsView = metrics?.map((metric, index) => {
    const metricObj = getHealthDashboardObj(metric?.name, index);
    const metricSymbol = getMetricSymbol(metric?.metric_type);
    return metricObj?.id && metric?.data_exists ? (
      <div className="metric__card__container" key={metricObj?.id}>
        <div className="card__header__container">
          <img src={metricObj?.image} />
          <span>{metric?.name}</span>
        </div>
        <div className="metric__body__container">
          <div className="metric__text__container">
            <span>
              <span className="value_text_bg_green">{`${
                metricSymbol?.pre
              }${roundToSigDigits(Number(metric?.metric), 2, false, 2)}${
                metricSymbol?.post === "%" ||
                metricSymbol?.post === "GB" ||
                metricSymbol?.post === "TB"
                  ? `${
                      metricSymbol?.post === "%"
                        ? metricSymbol?.post
                        : ` ${metricSymbol?.post}`
                    }`
                  : ""
              }`}</span>
              {metricSymbol?.post === "/day" && (
                <span className="value_text_sm_green">
                  {metricObj?.symbol?.post}
                </span>
              )}
              <span className="value_text_sm">{`  ${metric?.message}`}</span>
            </span>
          </div>
          <div className="metric__value__container">
            <span className="value_text_sm">Estimated Impact</span>
            <div className="value__div">
              <span className="value_text_bg_black">
                $
                {roundToSigDigits(
                  Number(metric?.estimated_impact),
                  2,
                  false,
                  2
                )}
              </span>
              <span className="value_text_sm_black">/month</span>
            </div>
          </div>
        </div>
        <div className="metric__footer__container">
          <div
            className="footer__text__container"
            onClick={() => {
              doRedirection(metricObj);
            }}>
            <span>{metricObj?.redirectionText}</span>
            <img src={front_arrow} />
          </div>
        </div>
      </div>
    ) : null;
  });
  return (
    <>
      <div className="health__dashboard-container">
        <div className="health__dashboard-header-container">
          <div className="header-block">
            {/* {breadcrumbs ? (
              <div className="breadcrumbs-container">
                <Link to={`../`} style={linkStyle}>
                  Data Source
                </Link>
                <span>{` / ${`Add Data Source`}`}</span>
              </div>
            ) : null} */}
            <span>Account Health Dashboard</span>
          </div>

          {/* <button
            className="btn btn-green-variant"
            onClick={() => {
              gotoSelectConn();
            }}>
            <img src={plus_add} />
            <span>Add Data Source</span>
          </button> */}
          <div className="select-container">
            <Select
              value={dateRange}
              options={dateRangeOptions}
              styles={customStyles}
              classNamePrefix="selectcategory"
              placeholder="select"
              isSearchable={false}
              components={{ DropdownIndicator }}
              onChange={(e) => {
                // setGridLoading(true);
                setDateRange(e);
                setIsPageLoading(true);
                getData(acc, e);
              }}
            />
          </div>
        </div>
        <div className="ctrl-main-block">
          <div className="health-container">
            {metricsView}
            {isPageLoading && (
              <div className="load">
                <div className="preload"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
HealthDashboard.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  changeActiveModule: PropTypes?.func
};
export default HealthDashboard;
