import React, { useState } from "react";
import AppHeader from "../../components/AppHeader";
import "./selectconnection.scss";
import { getCards } from "../../utils/cards-helper";
import ConnectionCard from "../../components/ConnectionCard";
import { useNavigate } from "react-router-dom";

const SelectConnection = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("SF");
  const navigateTo = (path) => {
    navigate(path);
  };
  const changeSelected = (s) => {
    if (s.available) {
      setSelected(s.id);
      navigateTo(`/selectconnection/connectaccounts/${s.id}`);
    }
  };
  const cardList = getCards();
  const cards =
    cardList &&
    cardList.length &&
    cardList.map((item) => {
      return (
        <ConnectionCard
          item={item}
          selected={selected}
          key={item.id}
          changeSelected={changeSelected}
        />
      );
    });
  return (
    <div className="app-container">
      <AppHeader />
      <div className="connection-container">
        <div className="connection-header">
          <span className="header">Connect Accounts</span>
          <span className="sub-header">
            Connect accounts that you need to optimize for cost & performance.
          </span>
        </div>
        <div className="cards-parent">{cards}</div>
      </div>
    </div>
  );
};
export default SelectConnection;
