import React from 'react'

function DeleteIcon() {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.66797 6.4165H18.3346"
        stroke="black"
        strokeWidth="0.916667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16797 10.0835V15.5835"
        stroke="black"
        strokeWidth="0.916667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.834 10.083V15.583"
        stroke="black"
        strokeWidth="0.916667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.58398 6.4165L5.50065 17.4165C5.50065 17.9027 5.69381 18.369 6.03762 18.7129C6.38144 19.0567 6.84775 19.2498 7.33398 19.2498H14.6673C15.1535 19.2498 15.6199 19.0567 15.9637 18.7129C16.3075 18.369 16.5007 17.9027 16.5007 17.4165L17.4173 6.4165"
        stroke="black"
        strokeWidth="0.916667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 6.41667V3.66667C8.25 3.42355 8.34658 3.19039 8.51849 3.01849C8.69039 2.84658 8.92355 2.75 9.16667 2.75H12.8333C13.0764 2.75 13.3096 2.84658 13.4815 3.01849C13.6534 3.19039 13.75 3.42355 13.75 3.66667V6.41667"
        stroke="black"
        strokeWidth="0.916667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default DeleteIcon