import React, { useState, useEffect, useContext } from "react";
import "./orgsettings.scss";
import GridComponent from "../../components/GridComponent";
import add_white_outline from "../../assets/images/dashboard/add_white_outline.svg";
import delete_red from "../../assets/images/dashboard/delete_red.svg";
import org_edit from "../../assets/images/common/org_edit.svg";
import {
  addUser,
  deleteUser,
  editUser,
  getOrgSettingsUsersData
} from "../../utils/settings-helper";
import { getOrgProps } from "./orgsettingscoldefs";
import AddUserForm from "../../components/AddUserForm";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { getException } from "../../utils/exceptiondictionary";
import {
  getSessionStorage,
  setSessinStorage
} from "../../utils/storage-helper";
import { AuthContext } from "../../utils/authprovider";

const OrgSettings = () => {
  const customProps = getOrgProps();
  const univerSalStyle = {
    cellStyle: { borderRight: "1px solid #eaeaeb" }
  };
  const toast = useToast();
  const { userInfo, setUserinfo } = useContext(AuthContext);

  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [gridFallback, setGridFallback] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(undefined);
  const [isModalLoading, setIsModalLoading] = useState(false);

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([]);

  const column_display_name_mappping = {
    index: "No",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email",
    role: "Role",
    Action: "Action"
  };

  const changeIsModalLoading = (bool) => {
    setIsModalLoading(bool);
  };

  const openDeleteModal = (data) => {
    changeModalOpen(true, {
      action: "delete",
      id: data?.user_id,
      name: `${data?.first_name} ${data?.last_name}`
    });
  };

  const openEditModal = (data) => {
    changeModalOpen(true, {
      action: "edit",
      id: data?.user_id,
      email: data?.email,
      name: `${data?.first_name} ${data?.last_name}`,
      role: data?.role
    });
  };

  const changeModalOpen = (bool, actionObj) => {
    setIsModalOpen({
      status: bool,
      action: actionObj?.action,
      id: actionObj?.id,
      name: actionObj?.name,
      role: actionObj?.role,
      email: actionObj?.email
    });
  };
  const closeModal = () => {
    setIsModalOpen(undefined);
  };
  const addUserSettings = async (fields) => {
    try {
      const data = await addUser(fields, customToast);
      if (data) {
        changeIsModalLoading(false);
        //closeModal();
        // setIsPageLoading(true);
        changeModalOpen(true, { action: "add-success" });
        setGridLoading(true);
        getTableData();
      } else {
        changeIsModalLoading(false);
        closeModal();
      }
    } catch (err) {
      //console.log(err);
      changeIsModalLoading(false);
      closeModal();
    }
  };
  const editUserSettings = async (id, role) => {
    try {
      const data = await editUser(id, role);
      console.log(data);
      changeIsModalLoading(false);
      const me = JSON.parse(getSessionStorage("me"));
      if (id.toString() === me?.user_id?.toString()) {
        setUserinfo({
          ...userInfo,
          info: {
            ...userInfo?.info,
            me: role?.value?.toLowerCase()
          }
        });
        setSessinStorage("me", { ...me, role: role?.value?.toLowerCase() });
      }
      closeModal();
      if (customToast) {
        customToast({
          type: "success",
          ...getException("USEREDITSUCCESS")
        });
      }
      // setIsPageLoading(true);
      setGridLoading(true);
      getTableData();
    } catch (err) {
      console.log(err);
      customToast({
        type: "error",
        ...getException(
          err?.response?.data?.errorKey,
          err?.response?.data?.message
        )
      });
      changeIsModalLoading(false);
      closeModal();
    }
  };
  const deleteUserSettings = async (id) => {
    try {
      const data = await deleteUser(id, customToast);
      if (data) {
        changeIsModalLoading(false);
        closeModal();
        // setIsPageLoading(true);
        setGridLoading(true);
        getTableData();
      } else {
        changeIsModalLoading(false);
        closeModal();
      }
    } catch (err) {
      changeIsModalLoading(false);
      closeModal();
    }
  };

  const createSettingsColDefs = (
    data,
    customProps,
    univerSalStyle,
    column_display_name_mappping
  ) => {
    let res = [];
    if (data) {
      res = data?.map((item) => {
        let obj = {
          field: String(item)
        };
        if (item)
          if (customProps[item]) {
            obj = {
              ...obj,
              headerName: column_display_name_mappping
                ? column_display_name_mappping[`${item ? item : ""}`]
                : "",
              ...customProps[item]
            };
          }
        if (univerSalStyle) {
          obj = { ...obj, ...univerSalStyle };
        }
        return obj;
      });
      res = res.map((item) =>
        item.field === "role"
          ? {
              ...item,
              cellRenderer: (params) => {
                return (
                  params?.value?.[0].toUpperCase() + params?.value.slice(1)
                );
              }
            }
          : item
      );
      res.push({
        headerName: "Login Type",
        ...customProps["login_type"],
        field: "login_type",
        cellRenderer: (params) => {
          return (
            <div className="login_creds">
              {params?.data?.user_password_created ? (
                <div className="login__setup">
                  <span>Password</span>
                </div>
              ) : null}
              {params?.data?.sso_user_created ? (
                <div className="login__setup">
                  <span>SSO</span>
                </div>
              ) : null}
            </div>
          );
        }
      });
      const me = JSON.parse(getSessionStorage("me"));
      if (me?.role?.toLowerCase() === "admin") {
        res.push({
          headerName: "Action",
          ...customProps["action"],
          field: "action",
          cellRenderer: (params) => {
            return (
              <div className="update__field">
                {userInfo?.info?.role === "admin" ? (
                  <button
                    data-id="2"
                    onClick={() => {
                      openEditModal(params.data);
                    }}
                    className="delete_user">
                    <img src={org_edit} />
                  </button>
                ) : null}
                <button
                  data-id="2"
                  onClick={() => {
                    openDeleteModal(params.data);
                  }}
                  className="delete_user">
                  <img src={delete_red} />
                </button>
              </div>
            );
          }
        });
      }
    }
    return res;
  };

  const getTableData = async () => {
    try {
      const data = await getOrgSettingsUsersData();
      if (data) {
        const field_names = [
          "index",
          "email",
          "first_name",
          "last_name",
          "role"
        ];
        data?.forEach((element, index) => {
          element.index = index + 1;
        });
        const colDefsArr = createSettingsColDefs(
          field_names,
          customProps,
          univerSalStyle,
          column_display_name_mappping
        );
        if (colDefsArr && data) {
          const newData = colDefsArr.map((colDef) => {
            if (colDef.headerName === "Action") {
              return colDef;
            } else {
              return {
                ...colDef,
                unSortIcon: true,
                sortable: true,
                filter: false
              };
            }
          });
          setColumnDefs(newData);
          setRowData(data);
        }
      } else {
        clearData();
      }
      setGridLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      console.log(err);
      setGridLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
    }
  };

  const clearData = () => {
    setRowData([]);
  };

  const getData = async () => {
    try {
      getTableData();
      setGridLoading(false);
      setIsPageLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
      console.log(err);
    }
  };
  const [breadCrumbs] = useState(false);
  useEffect(() => {
    setIsPageLoading(true);
    setGridLoading(true);
    getData();
  }, []);

  // Example using Grid's API
  // const buttonListener = useCallback((e) => {
  //   gridRef.current.api.deselectAll();
  // }, []);

  return (
    <>
      {isModalOpen && isModalOpen?.status === true && (
        <AddUserForm
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          changeIsLoading={changeIsModalLoading}
          isLoading={isModalLoading}
          addUserSettings={addUserSettings}
          deleteUserSettings={deleteUserSettings}
          editUserSettings={editUserSettings}
        />
      )}
      <div className="org-container overflow-auto">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container"></div>
                ) : null}
                <div className="header">
                  <span>Organization Settings</span>
                </div>
                <div className="subheader-container">
                  <div className="label-container">
                    <label>Organization Name</label>
                  </div>
                  <input
                    disabled={true}
                    type="text"
                    value={userInfo?.info?.acc_name}></input>
                </div>
              </div>
            </div>
            <div className="grid-block">
              <div className="grid-header-container">
                <div className="grid-header-block">
                  <div className="grid-header">
                    <span>Manage Users</span>
                  </div>
                </div>
                <button
                  onClick={() => {
                    changeModalOpen(true, { action: "add-user" });
                  }}
                  className="btn btn-green-variant login-btn">
                  <img src={add_white_outline} /> Add User
                </button>
              </div>
              <GridComponent
                columnDefs={columnDefs}
                rowData={rowData}
                gridFallback={gridFallback}
                gridStyle={{ height: 400, avoidAutoHeightOverflow: true }}
                gridLoading={gridLoading}
                noRowsText={"No users available"}
                suppressColumnsSize={true}
                customRowHeight={41}
              />
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
//OrgSettings.propTypes = {};
export default OrgSettings;
