import React from "react";
import "./connectionfallback.scss";
import CG_logo_img from "../../assets/images/common/CG_logo_img.svg";
import sf_logo from "../../assets/images/common/sf_logo.svg";
const ConnectionFallback = () => {
  return (
    <div className="fallback-container">
      <div className="text-container" style={{ marginBottom: 20 }}>
        <span style={{ fontWeight: 900, fontSize: 28 }}>
          Account setup in progress ⌛
        </span>
      </div>
      <div className="image-container">
        <div className="image">
          <img src={sf_logo} />
        </div>
        <div className="line-container">
          <div className="line"></div>
        </div>
        <div className="image">
          <img src={CG_logo_img} />
        </div>
      </div>
      <div className="text-container">
        <span style={{ fontSize: 20 }}>
          Depending on scale of your operations, the one-time analytics setup &
          insight generation might take 24-48 hours.
        </span>
      </div>
      <div className="text-container" style={{ marginTop: 21 }}>
        <span style={{ fontSize: 16 }}>
          In case of any support, please contact us on our{" "}
          <a
            href="https://join.slack.com/t/chaosgenius/shared_invite/zt-140042uac-rrm~xbx9o_aydi6PTmp_Mg"
            target={"_blank"}
            rel="noreferrer">
            <span style={{ fontWeight: 900 }}>Slack Community.</span>
          </a>
        </span>
      </div>
    </div>
  );
};
export default ConnectionFallback;
