import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import SignIn from "../pages/SignIn";
import SignUp from "../pages/SignUp";
import SignUpOnBoard from "../pages/SignUpOnboard";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import ProtectedRoute from "./ProtectedRoute";
import SelectConnection from "../pages/SelectConnection";
// import ConfirmMail from "../pages/ConfirmMail";
import { useContext } from "react";
import { AuthContext } from "../utils/authprovider";
import ConnectAccounts from "../pages/ConnectAccounts";
import Dashboard from "../pages/Dashboard";
import BillingSuccess from "../pages/BillingSuccess";
import BillingError from "../pages/BillingError";
const AppRoutes = () => {
  const { userInfo } = useContext(AuthContext);
  const location = useLocation();
  return (
    <Routes>
      {userInfo && (
        <>
          <Route
            element={
              <ProtectedRoute
                isAllowed={userInfo?.token ? true : false}
                redirectPath={
                  location.pathname.includes("signin/sso")
                    ? "/signin/sso"
                    : "/signin"
                }
              />
            }>
            {/* <Dashboard /> */}
            {["/selectconnection"].map((path, index) => {
              return (
                <Route path={path} element={<SelectConnection />} key={index} />
              );
            })}
            {[
              "/selectconnection/connectaccounts/SF/",
              "/selectconnection/connectaccounts/SF/:step/:dwacc"
            ].map((path, index) => {
              return (
                <Route path={path} element={<ConnectAccounts />} key={index} />
              );
            })}
          </Route>
          <Route
            element={
              <ProtectedRoute
                isAllowed={userInfo?.token ? true : false}
                redirectPath={
                  userInfo?.token &&
                  userInfo?.info?.num_data_wh_acc.toString() === "0"
                    ? "/selectconnection"
                    : "/signin"
                }
              />
            }>
            <Route path="/dashboard/*" index element={<Dashboard />} />
          </Route>
        </>
      )}
      <Route path="/billing/success" element={<BillingSuccess />} />
      <Route path="/billing/fail" element={<BillingError />} />
      <Route path="/onboard/:status?" element={<SignUpOnBoard />} />
      <Route path="/signin/sso" element={<SignIn />} />
      <Route path="/signin" element={<SignIn />} />
      <Route path="/signup" element={<SignUp />} />
      <Route path="signup/:id" element={<SignUp />} />
      <Route path="/forgotpassword" element={<ForgotPassword />} />
      <Route path="/resetpassword" element={<ResetPassword />} />
    </Routes>
  );
};
export default AppRoutes;
