import React, { useEffect } from "react";
import "./billingerror.scss";
// import { useAuth } from "../../utils/auth-helper";
// import { roundToSigDigits } from "../../utils/common-helper";

const BillingError = () => {

  useEffect(() => {
    const timer = setTimeout(() => {
      window.location.href = "/dashboard/settings/billing";
    }, 5 * 1000);

    return () => {
      clearTimeout(timer);
    }
  }, []);

  return (
    <>
      <div className="billing-error-container">
        <span className="red-header">Failed to update your billing information. Please try again.</span>
        <span>
          You will be redirected back to the dashboard in 5 seconds. If you are not redirected, please click <a href="/dashboard/settings/billing">here</a>.
        </span>
      </div>
    </>
  );
};
BillingError.propTypes = {};
export default BillingError;
