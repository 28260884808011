import React from "react";
import "./signupsigninflow.scss";
import CG_logo from "../../assets/images/common/CG_logo_imgonly.svg";
import check_circle from "../../assets/images/common/check_circle.svg";
import PropTypes from "prop-types";
import feature_list from "./feature-list-helper";
const SignUpSignInFlow = ({ welcome }) => {
  const orgs_view_list = feature_list?.trusted_orgs.map((org, index) => {
    return (
      <div className="org-container" key={index}>
        <img src={org.image} style={org.style ? org.style : {}} />
      </div>
    );
  });
  return (
    <div className="feature-container">
      <div className="block">
        <div className="feature-parent">
          <div className="logo-container">
            <img src={CG_logo}></img>
            <span>Chaos Genius</span>
          </div>
          <div className="feature-space-1"></div>
          <div className="feature-list-container">
            <div className="heading">
              <span>
                {welcome
                  ? "Welcome Back!"
                  : "Maximize your Snowflake ROI with Chaos Genius"}
              </span>
            </div>
            <ul className="feature-list">
              {feature_list?.featureList?.map((item, index) => {
                return (
                  <li key={index} className="feature-item">
                    <div className="check">
                      <img src={check_circle} />
                    </div>
                    <span>{item.label}</span>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="feature-space-2"></div>
          <div className="trusted-container">
            <div className="heading">
              <span>Trusted by Data Teams worldwide 🌏</span>
            </div>
            <div className="trusted-orgs-overflow">
              <div className="trusted-orgs-container">
                {orgs_view_list}
                {orgs_view_list}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
SignUpSignInFlow.propTypes = {
  welcome: PropTypes.bool
};
export default SignUpSignInFlow;
