import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import { BrowserRouter } from "react-router-dom";
import "./assets/fonts/Inter-Regular.ttf";
import { check_valid_sentry_url, getWebClientID } from "./utils/auth-helper";
import { env } from "./env";
import * as Sentry from "@sentry/react";
Amplify.configure({
  ...awsExports,
  aws_user_pools_web_client_id: getWebClientID(
    awsExports?.aws_user_pools_web_client_id
  )
});
let sentryEnabled = false;
if (
  env?.REACT_APP_SENTRY_DSN &&
  check_valid_sentry_url(env?.REACT_APP_SENTRY_DSN) &&
  env?.REACT_APP_ENV.toString() === "prod"
) {
  sentryEnabled = true;
  Sentry.init({
    dsn: env?.REACT_APP_SENTRY_DSN,
    release: "cloud-optimizer-frontend@" + env?.npm_package_version,
    environment: env?.REACT_APP_ENV,
    integrations: [new Sentry.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}
export default sentryEnabled;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
