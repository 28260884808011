import React from "react";
import "./signinheader.scss";
import CG_logo from "../../assets/images/common/CG_logo_imgonly.svg";

const SignInHeader = () => {
  return (
    <div className="signin-header">
      <div className="logo-container">
        <img src={CG_logo}></img>
        <span>Chaos Genius</span>
      </div>
    </div>
  );
}

export default SignInHeader;