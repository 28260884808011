import React from "react";
import upgrade__now from "../assets/images/dashboard/upgrade__now.svg";

const CustomNoRowsOverlay = (changeActiveModule) => {
  return (
    <div className={`nocomp__div`}>
      <img src={upgrade__now} />
      <p>To access full features upgrade your plan</p>
      <button
        onClick={() => {
          changeActiveModule("Settings", "", "Billing", undefined);
        }}
        className="btn btn-green-variant no-rows-btn">
        Upgrade now
      </button>
    </div>
  );
};
export default CustomNoRowsOverlay;
