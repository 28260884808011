import React, { useState } from "react";
//import AppHeader from "../../components/AppHeader";
import "./datasourceselectconn.scss";
import { getCards } from "../../utils/cards-helper";
import ConnectionCard from "../../components/ConnectionCard";
import { Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";

const DataSourceSelectConn = () => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState("SF");
  const navigateTo = (path) => {
    navigate(path);
  };
  const changeSelected = (s) => {
    if (s.available) {
      setSelected(s.id);
      navigateTo(`/selectconnection/connectaccounts/${s.id}`);
    }
  };
  const linkStyle = {
    textDecoration: "none",
    color: "#0597F2",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "18px"
  };
  const [breadcrumbs] = useState(true);
  const cardList = getCards();
  const cards =
    cardList &&
    cardList.length &&
    cardList.map((item) => {
      return (
        <ConnectionCard
          item={item}
          selected={selected}
          key={item.id}
          changeSelected={changeSelected}
        />
      );
    });
  return (
    <div className="view-ctrl-container">
      <div className="viewctrl-header-container">
        <div className="header-block">
          {breadcrumbs ? (
            <div className="breadcrumbs-container">
              <Link to={`../datasource`} style={linkStyle}>
                Data Source
              </Link>
              <span>{` / ${`Add Data Source`}`}</span>
            </div>
          ) : null}
          <span>{"Add Data Source"}</span>
        </div>
      </div>
      <div className="ctrl-main-block">
        <div className="dashboardconnection-container">
          <div className="connection-header">
            <span className="header">Connect Accounts</span>
            <span className="sub-header">
              Connect accounts that you need to optimize for cost & performance.
            </span>
          </div>
          <div className="cards-parent dashboard-cards">{cards}</div>
        </div>
      </div>
    </div>
  );
};
DataSourceSelectConn.propTypes = {
  changeModuleNameText: PropTypes.func
};
export default DataSourceSelectConn;
