import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Auth, Hub } from "aws-amplify";
import { createContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteToken, getToken, saveToken } from "./token-helper";
import * as Sentry from "@sentry/react";
import {
  appSignup,
  getAppUserInfo,
  getMyInfo,
  SignOutUser
} from "./user-helper";
import {
  setSessinStorage,
  getSessionStorage,
  removeSSItem,
  clearStorage
} from "./storage-helper";
import { env } from "../env";
import { getAccessToken, getCurrentAppVersion } from "./auth-helper";
import posthog from "posthog-js";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "./toast-helper";
import sentryEnabled from "../index";
export const AuthContext = createContext(null);
const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [userInfo, setUserinfo] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [origin, setOrigin] = useState(null);
  const location = useLocation();
  const toast = useToast();
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  useEffect(() => {
    Hub.listen("auth", ({ payload }) => {
      const { event } = payload;
      switch (event) {
        case "signIn": {
          console.log("usersignedin: ");
          break;
        }
        case "cognitoHostedUI": {
          //getUser().then((userData) => setUser(userData));
          break;
        }
        case "signOut": {
          //setUser(null);
          break;
        }
        case "autoSignIn": {
          //console.log("autoSignin");
          break;
        }
        case "autoSignIn_failure": {
          // redirect to sign in page
          break;
        }
        case "signIn_failure": {
          break;
        }
        case "cognitoHostedUI_failure": {
          // console.log("Sign in failure", data);
          break;
        }
        case "tokenRefresh": {
          console.log("New token has been processed");
          break;
        }
        case "tokenRefresh_failure": {
          SignOutUser(handleLogout);
          //console.log("Failed to retrieve new token");
          break;
        }
      }
    });
    getAccessToken()
      .then((session) => {
        if (session) {
          setUpUserInfo(session);
        } else {
          SignOutUser(handleLogout);
        }
      })
      .catch((err) => {
        console.error(err);
        sentryEnabled && Sentry.captureException(err);
        SignOutUser(handleLogout);
      });
  }, []);

  const setUpUserInfo = async (session) => {
    try {
      const [info, usr] = await Promise.all([
        getAppUserInfo(customToast),
        getMyInfo(customToast)
      ]);
      const { plan_name } = info;
      if (plan_name) {
        setSessinStorage("plan_name", plan_name);
        setUserinfo({
          ...userInfo,
          info: {
            ...userInfo?.info,
            plan_name: plan_name
          }
        });
      }
      if (usr) {
        setSessinStorage("me", JSON.stringify(usr));
        const { role } = usr;
        if (role) {
          setUserinfo({
            ...userInfo,
            info: {
              ...userInfo?.info,
              role: role
            }
          });
        }
      }
      const version = getSessionStorage("version");
      if (!version) {
        await getCurrentAppVersion();
      }
      const token = session.getAccessToken().getJwtToken();
      const account_id = getToken("account_id");
      const num_data_wh_acc = getSessionStorage("num_data_wh_acc");
      const given_name = getSessionStorage("given_name");
      const acc_name = getSessionStorage("acc_name");
      const redirect_to_billing = getSessionStorage("redirect_to_billing");
      const orgonboarded = getSessionStorage("orgonboarded");
      let me = JSON.parse(getSessionStorage("me"));
      if (
        token &&
        num_data_wh_acc &&
        plan_name &&
        given_name &&
        account_id &&
        redirect_to_billing &&
        orgonboarded &&
        orgonboarded.toString() !== "undefined" &&
        orgonboarded.toString() !== "null" &&
        me
      ) {
        setToken(token);
        saveToken("accessToken", token);
        setUserinfo({
          token: token,
          info: {
            num_data_wh_acc,
            plan_name,
            given_name: me?.first_name + me?.last_name,
            account_id,
            acc_name: acc_name,
            email: me.email,
            redirect_to_billing,
            onboarded: me?.onboarded,
            role: me?.role
          }
        });
        if (me?.onboarded?.toString() === "true") {
          if (orgonboarded?.toString() === "true") {
            if (redirect_to_billing.toString() === "false") {
              if (num_data_wh_acc?.toString() === "0") {
                setOrigin("selectconnection");
              } else {
                let origin = "";
                if (plan_name?.toString() === "free") {
                  if (location.pathname === "" || location.pathname === "/") {
                    origin = "dashboard/connectionfallback";
                  } else {
                    origin =
                      location?.pathname ||
                      location?.state?.from?.pathname ||
                      "dashboard/connectionfallback";
                  }
                } else {
                  if (location.pathname === "" || location.pathname === "/") {
                    origin = "dashboard";
                  } else {
                    origin =
                      location?.pathname ||
                      location.state?.from?.pathname ||
                      "dashboard";
                  }
                }
                console.log("userinfoorigin: ", origin);
                if (!me) {
                  me = await getMyInfo(customToast);
                  setSessinStorage("me", JSON.stringify(me));
                }
                if (env?.REACT_APP_ENV?.toString() === "prod") {
                  console.log("PHEnabled");
                  initPH(session?.idToken?.payload?.sub, {
                    name: `${me.first_name} ${me?.last_name}`, // also sets the display name in the PostHog UI
                    email: me?.email
                  });
                }
                setOrigin(origin);
              }
            } else {
              if (!me) {
                me = await getMyInfo(customToast);
                setSessinStorage("me", JSON.stringify(me));
                if (me?.onboarded?.toString === "true") {
                  setOrigin("onboard/invite");
                } else {
                  setOrigin("/onboard");
                }
              } else {
                if (env?.REACT_APP_ENV?.toString() === "prod") {
                  console.log("PHEnabled");
                  initPH(session?.idToken?.payload?.sub, {
                    name: `${me.first_name} ${me?.last_name}`, // also sets the display name in the PostHog UI
                    email: me?.email
                  });
                }
                setOrigin("dashboard/settings/billing");
              }
            }
          } else {
            if (!me) {
              me = await getMyInfo(customToast);
              setSessinStorage("me", JSON.stringify(me));
              if (me?.onboarded?.toString === "true") {
                setOrigin("onboard/invite");
              } else {
                setOrigin("/onboard");
              }
            } else {
              if (env?.REACT_APP_ENV?.toString() === "prod") {
                console.log("PHEnabled");
                initPH(session?.idToken?.payload?.sub, {
                  name: `${me.first_name} ${me?.last_name}`, // also sets the display name in the PostHog UI
                  email: me?.email
                });
              }
              setOrigin("onboard/invite");
            }
          }
        } else {
          if (!me) {
            me = await getMyInfo(customToast);
            setSessinStorage("me", JSON.stringify(me));
            if (me?.onboarded?.toString === "true") {
              setOrigin("onboard/invite");
            } else {
              setOrigin("/onboard");
            }
          } else {
            if (env?.REACT_APP_ENV?.toString() === "prod") {
              console.log("PHEnabled");
              initPH(session?.idToken?.payload?.sub, {
                name: `${me.first_name} ${me?.last_name}`, // also sets the display name in the PostHog UI
                email: me?.email
              });
            }
            setOrigin("/onboard");
          }
        }
      } else {
        getAuthenticatedUser();
      }
    } catch (er) {
      console.error(er);
      sentryEnabled && Sentry.captureException(er);
      getAuthenticatedUser();
    }
  };
  const initPH = (token = "", attributes = {}) => {
    posthog.init("phc_gsQctxIYZGbnRi7a7T7bGfoZnLlmsgL5vgCmaBx9xHk", {
      api_host: "https://app.posthog.com",
      loaded: function (posthog) {
        posthog.identify(token, { ...attributes });
      }
    });
  };
  const getAuthenticatedUser = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const { signInUserSession, attributes } = user;
      const { accessToken } = signInUserSession;
      const { jwtToken } = accessToken;
      if (jwtToken) {
        setToken(jwtToken);
        saveToken("accessToken", jwtToken);
      } else {
        setToken(null);
        deleteToken("accessToken");
      }
      await getCurrentAppVersion();
      const info = await getAppUserInfo(customToast);
      const me = await getMyInfo(customToast);
      if (info) {
        let origin = "";
        const {
          num_data_wh_acc,
          plan_name,
          account_id,
          name,
          redirect_to_billing,
          onboarded
        } = info;
        setSessinStorage("num_data_wh_acc", num_data_wh_acc);
        setSessinStorage("plan_name", plan_name);

        saveToken("account_id", account_id);
        setSessinStorage("acc_name", name);
        setSessinStorage("redirect_to_billing", redirect_to_billing);
        setSessinStorage(
          "orgonboarded",
          onboarded &&
            onboarded.toString() !== "null" &&
            onboarded.toString() !== "undefined"
            ? onboarded
            : false
        );
        if (me) {
          setSessinStorage("me", JSON.stringify(me));
          setSessinStorage("given_name", me?.first_name + me?.last_name);
        }
        setUserinfo({
          token: jwtToken,
          info: {
            num_data_wh_acc,
            plan_name,
            given_name: attributes.given_name,
            account_id,
            acc_name: name,
            email: me.email,
            redirect_to_billing,
            onboarded: me?.onboarded,
            role: me?.role
          }
        });
        if (me?.onboarded?.toString() === "true") {
          if (onboarded?.toString() === "true") {
            if (redirect_to_billing.toString() === "false") {
              if (num_data_wh_acc?.toString() === "0") {
                origin = "selectconnection";
              } else if (num_data_wh_acc?.toString() !== "0") {
                if (plan_name?.toString() === "free") {
                  origin =
                    location?.state?.from?.pathname ||
                    "dashboard/connectionfallback";
                } else {
                  origin = location.state?.from?.pathname || "dashboard";
                }
              }
              setOrigin(origin);
              if (env?.REACT_APP_ENV?.toString() === "prod") {
                console.log("PHEnabled");
                initPH(user?.attributes?.sub, {
                  name: `${me?.first_name} ${me?.last_name}`, // also sets the display name in the PostHog UI
                  email: me?.email,
                  organization: me?.organization,
                  user_id: me?.user_id,
                  role: me?.role,
                  account_id: me?.account_id
                });
              }
            } else {
              setOrigin("dashboard/settings/billing");
              if (env?.REACT_APP_ENV?.toString() === "prod") {
                console.log("PHEnabled");
                initPH(user?.attributes?.sub, {
                  name: `${me?.first_name} ${me?.last_name}`, // also sets the display name in the PostHog UI
                  email: me?.email,
                  organization: me?.organization,
                  user_id: me?.user_id,
                  role: me?.role,
                  account_id: me?.account_id
                });
              }
            }
          } else {
            setOrigin("onboard/invite");
            if (env?.REACT_APP_ENV?.toString() === "prod") {
              console.log("PHEnabled");
              initPH(user?.attributes?.sub, {
                name: `${me?.first_name} ${me?.last_name}`, // also sets the display name in the PostHog UI
                email: me?.email,
                organization: me?.organization,
                user_id: me?.user_id,
                role: me?.role,
                account_id: me?.account_id
              });
            }
          }
        } else {
          setOrigin("/onboard");
          if (env?.REACT_APP_ENV?.toString() === "prod") {
            console.log("PHEnabled");
            initPH(user?.attributes?.sub, {
              name: `${me?.first_name} ${me?.last_name}`, // also sets the display name in the PostHog UI
              email: me?.email,
              organization: me?.organization,
              user_id: me?.user_id,
              role: me?.role,
              account_id: me?.account_id
            });
          }
        }
        // posthog.capture("my event", { property: "value" });
      } else {
        setToken(null);
        setUserinfo(null);
        setOrigin(null);
        deleteToken("accessToken");
        deleteToken("account_id");
        removeSSItem("num_data_wh_acc");
        removeSSItem("plan_name");
        removeSSItem("given_name");
        removeSSItem("me");
        removeSSItem("acc_name");
        removeSSItem("dw_acc_id");
        removeSSItem("redirect_to_billing");
        removeSSItem("orgonboarded");
        clearStorage();
        //SignOutUser(handleLogout);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      sentryEnabled && Sentry.captureException(err);
      setIsLoading(false);
      // handleLogout();
    }
  };

  const handleLogin = async (obj) => {
    setIsLoading(true);
    if (obj) {
      try {
        const acc = await appSignup(obj, customToast);
        if (acc) {
          getAuthenticatedUser();
        } else {
          setIsLoading(false);
          SignOutUser(handleLogout);
        }
      } catch (e) {
        sentryEnabled && Sentry.captureException(e);
        console.error(e);
      }
    } else {
      getAuthenticatedUser();
    }
  };

  const handleLogout = () => {
    setToken(null);
    setUserinfo(null);
    setOrigin(null);
    deleteToken("accessToken");
    deleteToken("account_id");
    removeSSItem("num_data_wh_acc");
    removeSSItem("plan_name");
    removeSSItem("given_name");
    removeSSItem("me");
    removeSSItem("acc_name");
    removeSSItem("dw_acc_id");
    removeSSItem("redirect_to_billing");
    removeSSItem("orgonboarded");
    clearStorage();
    if (location?.pathname === "/signup" || location?.pathname === "signup") {
      navigate("signup");
    } else {
      if (location?.pathname.includes("/signin/sso")) {
        navigate("/signin/sso");
      } else {
        navigate("/signin");
      }
    }
    if (posthog) {
      posthog.reset();
    }
  };

  useEffect(() => {
    if (origin !== null) {
      navigate(origin);
    }
  }, [origin]);

  const value = {
    setUserinfo,
    token,
    userInfo,
    setToken,
    onLogin: handleLogin,
    onLogout: handleLogout
  };

  return (
    <AuthContext.Provider value={value}>
      {isLoading && (
        <div className="load">
          <div className="preload"></div>
        </div>
      )}
      {children}
    </AuthContext.Provider>
  );
};
AuthProvider.propTypes = {
  children: PropTypes.any
};

export default AuthProvider;
