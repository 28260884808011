import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import TopUsers from "./components/TopUsers";
import DonutCharts from "./components/DonutChart";
import { removeByAttr, roundToSigDigits } from "../../utils/common-helper";
import DataCards from "./components/DataCards";
import LineChart from "./components/LineChart";
import { components } from "react-select";
import chev_down from "../../assets/images/common/chev_down.svg";
import {
  getAccountSummaryComputeCostAPI,
  getAccountSummaryKPICardsAPI,
  getAccountSummaryStorageCostAPI,
  getAccountSummaryTotalCostAPI,
  getDonutData,
  getSplineChartData,
  getTopUserDataAPI,
  getTopWHDataAPI,
  handleDonutFilter
} from "../../utils/organization-helper";
import { getCardFilter } from "../../utils/cards-helper";

function CostOverview({
  acc,
  dateRange,
  isPageLoading,
  dateRangeOptions,
  dateRangeArr,
  activeTab,
  setIsPageLoading
}) {
  const [metricData, setMetricData] = useState(null);
  const [donutData, setDonutData] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [topWhData, setTopWhData] = useState(null);
  const [topUserData, setTopUserData] = useState(null);

  const getPageData = async (dateRangeArr, dateRange, acc) => {
    setIsPageLoading(true);
    try {
      const [
        accountSummaryCards,
        accSummaryTotalCost,
        accSummaryComputeCost,
        accSummaryStorageCost,
        topWh,
        topUser
      ] = await Promise.all([
        getAccountSummaryKPICardsAPI(dateRange, acc),
        getAccountSummaryTotalCostAPI(dateRange, acc),
        getAccountSummaryComputeCostAPI(dateRange, acc),
        getAccountSummaryStorageCostAPI(dateRange, acc),
        getTopWHDataAPI(dateRange, acc),
        getTopUserDataAPI(dateRange, acc)
      ]);
      if (
        accSummaryTotalCost &&
        accSummaryComputeCost &&
        accSummaryStorageCost &&
        accountSummaryCards?.kpi_cards
      ) {
        if (topWh && topUser) {
          setTopWhData(topWh);
          setTopUserData(topUser);
        }

        populateData(
          dateRange,
          accSummaryTotalCost,
          accSummaryStorageCost,
          accSummaryComputeCost,
          dateRangeArr,
          accountSummaryCards?.kpi_cards
        );
      }
      setIsPageLoading(false);
    } catch (er) {
      console.log(er);
      clearData();
      setIsPageLoading(false);
    }
  };

  const populateData = (
    dateRange,
    accSummaryTotalCost,
    accSummaryStorageCost,
    accSummaryComputeCost,
    dateRangeArr,
    cards
  ) => {
    handleFilter(
      dateRange,
      accSummaryTotalCost,
      accSummaryStorageCost,
      accSummaryComputeCost,
      dateRangeArr,
      cards
    );

    const computeLineData = getSplineChartData(accSummaryComputeCost);
    const storageLineData = getSplineChartData(accSummaryStorageCost);
    setLineChartData([computeLineData, storageLineData]);
  };

  const handleFilter = (
    e,
    totalCost,
    totalStorage,
    totalCompute,
    dateRangeArr,
    kpi_cards
  ) => {
    const totalCostData = handleDonutFilter(totalCost, e, dateRangeArr);
    const totalCostFormattedData = getDonutData(totalCostData, "");
    const computeData = handleDonutFilter(totalCompute, e, dateRangeArr);
    const totalComputeFormattedData = getDonutData(computeData, "pie");
    const storageData = handleDonutFilter(totalStorage, e, dateRangeArr);
    const totalFormattedStorageData = getDonutData(storageData, "pie");
    const cloudServicesFilter = getCardFilter(
      totalCostData?.data,
      "Cloud Services"
    );
    const dataTransferFilter = getCardFilter(
      totalCostData?.data,
      "Data Transfer"
    );
    const foreCastObj = kpi_cards.find((value) => {
      return value.metric === "forecast";
    });
    const filterOutforecast = removeByAttr(kpi_cards, "metric", "forecast");

    const extraCardsArray = [
      cloudServicesFilter && cloudServicesFilter?.length
        ? {
            metric: "cost",
            metric_name: "Total Cloud Services Cost",
            value: cloudServicesFilter?.reduce(
              (prev, sum) => prev + sum.cost,
              0
            ),
            unit_prefix: "$",
            unit_suffix: null
          }
        : {
            metric: "cost",
            metric_name: "Total Cloud Services Cost",
            value: 0,
            unit_prefix: "$",
            unit_suffix: null
          },
      dataTransferFilter && dataTransferFilter?.length
        ? {
            metric: "cost",
            metric_name: "Total Data Transfer Cost",
            value: dataTransferFilter?.reduce(
              (prev, sum) => prev + sum.cost,
              0
            ),
            unit_prefix: "$",
            unit_suffix: null
          }
        : {
            metric: "cost",
            metric_name: "Total Data Transfer Cost",
            value: 0,
            unit_prefix: "$",
            unit_suffix: null
          }
    ];
    const allCardsView = [
      ...filterOutforecast,
      {
        metric: "cost",
        metric_name: totalCostData?.meta_data?.kpi_title,
        value: totalCostData?.data.reduce((prev, sum) => prev + sum.cost, 0),
        unit_prefix: "$",
        unit_suffix: null
      },
      {
        metric: "cost",
        metric_name: computeData?.meta_data?.kpi_title,
        value: computeData?.data?.reduce((prev, sum) => prev + sum.cost, 0),
        unit_prefix: "$",
        unit_suffix: null
      },
      {
        metric: "cost",
        metric_name: storageData?.meta_data?.kpi_title,
        value: storageData?.data?.reduce((prev, sum) => prev + sum.cost, 0),
        unit_prefix: "$",
        unit_suffix: null
      },
      ...extraCardsArray,
      foreCastObj ? foreCastObj : null
    ];
    setMetricData(allCardsView);
    setDonutData([
      totalCostFormattedData,
      totalComputeFormattedData,
      totalFormattedStorageData
    ]);
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  DropdownIndicator.propTypes = {
    isDisabled: PropTypes.bool
  };
  const clearData = () => {
    setMetricData(null);
    setDonutData(null);
    setLineChartData(null);
    setTopWhData(null);
    setTopUserData(null);
  };
  useEffect(() => {
    setIsPageLoading(true);
    if (dateRangeArr?.length && dateRange && acc) {
      setTimeout(() => {
        getPageData(dateRangeArr, dateRange, acc);
      },0);
    }
  }, [ dateRange, activeTab]);
  return (
    <div className="sub-container ">
      {!isPageLoading ? (
        <>
          <div className="summary-container">
            {metricData && metricData?.length
              ? metricData?.map((item, index) => {
                  if (item) {
                    return (
                      <DataCards
                        key={index}
                        data={item}
                        informationsText={false}
                      />
                    );
                  } else {
                    return null;
                  }
                })
              : null}
          </div>
          <div className="donut-main-container">
            {donutData && donutData?.length
              ? donutData.map((donut, index) => (
                  <DonutCharts
                    chartData={donut}
                    key={index}
                    additionalStyle={{
                      size: "100%",
                      dataLabels: { fontSize: "8px", distance: "5%" },
                      title: {
                        text: `$${roundToSigDigits(
                          donut?.total,
                          1,
                          false,
                          1
                        ).toLocaleString("en-US")}`
                      }
                    }}
                  />
                ))
              : null}
          </div>
          <div className="donut-main-container">
            {lineChartData && lineChartData?.length
              ? lineChartData.map((chartData, index) => (
                  <LineChart
                    key={index}
                    lineChartData={chartData}
                    dateRange={dateRange}
                    dateRangeOptions={dateRangeOptions}
                    DropdownIndicator={DropdownIndicator}
                  />
                ))
              : null}
          </div>
          <div className="donut-main-container tp-user-container ">
            <div className="tp-sub-container">
              {topWhData ? (
                <TopUsers tableData={topWhData} title={false} />
              ) : null}
            </div>
            <div className="tp-sub-container">
              {topUserData ? (
                <TopUsers tableData={topUserData} title={true} />
              ) : null}
            </div>
          </div>
        </>
      ) : null}
      {isPageLoading && (
        <div className="load">
          <div className="preload"></div>
        </div>
      )}
    </div>
  );
}
CostOverview.propTypes = {
  isPageLoading: PropTypes.bool,
  acc: PropTypes.object,
  dateRange: PropTypes.object,
  dateRangeOptions: PropTypes.array,
  activeTab: PropTypes.string,
  dateRangeArr: PropTypes.array,
  setIsPageLoading: PropTypes.func
};
export default CostOverview;
