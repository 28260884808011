import React, { useEffect } from "react";
import { getContractUtilizationData } from "../../utils/organization-helper";
import DataCards from "./components/DataCards";
import PropTypes from "prop-types";
import DataNotFound from "../DataNotFound/DataNotFound";

function Contracts({
  acc,
  isPageLoading,
  setIsPageLoading,
  dateRange,
  contractData,
  setContractData
}) {
  const getContractData = async () => {
    if (dateRange) {
      try {
        const response = await getContractUtilizationData(dateRange, acc);
        if (response) {
          setContractData(response);
          setIsPageLoading(false);
        }
      } catch (er) {
        console.log(er);
        setContractData(er);
        setIsPageLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsPageLoading(true);
    getContractData();
  }, [acc]);
  return !isPageLoading ? (
    <>
      <div className="summary-container">
        {contractData &&
        contractData?.metric_list?.length &&
        contractData.contract_exists ? (
          contractData?.metric_list?.map((item, index) => {
            if (item) {
              return (
                <DataCards key={index} data={item} informationsText={true} />
              );
            } else {
              return null;
            }
          })
        ) : (
          <div className="not-found-container">
            <DataNotFound />
          </div>
        )}
      </div>
    </>
  ) : (
    <div className="load">
      <div className="preload"></div>
    </div>
  );
}
Contracts.propTypes = {
  isPageLoading: PropTypes.bool,
  acc: PropTypes.object,
  setIsPageLoading: PropTypes.func,
  dateRange: PropTypes.object,
  contractData: PropTypes.object,
  setContractData: PropTypes.func
};
export default Contracts;
