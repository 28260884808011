import React, { useContext, useState } from "react";
import { useAuth } from "../../utils/auth-helper";
import "./appheader.scss";
import CG_logo_img from "../../assets/images/common/CG_logo_imgonly.svg";
import DropDown from "../DropDown";
import { useNavigate } from "react-router-dom";
import chev_down from "../../assets/images/common/chev_down.svg";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import logout from "../../assets/images/common/logout.svg";
import gear_settings from "../../assets/images/common/gear_settings.svg";
import documentation_img from "../../assets/images/common/documentation.svg";
import faq_img from "../../assets/images/common/faq.svg";
import { SignOutUser } from "../../utils/user-helper";
import {
  getSessionStorage,
  setSessinStorage
} from "../../utils/storage-helper";
import { AuthContext } from "../../utils/authprovider";
const AppHeader = ({ changeActiveModule, acc, dwAccOptions, changeAcc }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const navigate = useNavigate();
  const { onLogout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const given_name = getSessionStorage("given_name");
  const onSignOut = () => {
    setIsLoading(true);
    signOut();
  };
  const { userInfo } = useContext(AuthContext);
  const handleModal = (e) => {
    e?.stopPropagation();
    setShowModal(!showModal);
  };

  const onOrgSettings = () => {
    if (changeActiveModule) {
      changeActiveModule("Settings", "", "Organization Settings");
    } else {
      navigate("/dashboard/settings/billing");
    }
  };
  const signOut = async () => {
    try {
      await SignOutUser(onLogout);
      setIsLoading(false);
    } catch (error) {
      console.log("error signing out: ", error);
      setIsLoading(false);
    }
  };
  const goHome = () => {
    navigate("/dashboard", { replace: true });
    window.location.reload();
  };

  const dropdown_options = [
    {
      id: "2",
      name: "Documentation",
      label: "Documentation",
      img: documentation_img,
      handler: () => {
        window.open(
          "https://documentation.chaosgenius.io/",
          "_blank",
          "rel=noopener noreferrer"
        );
        handleModal();
      }
    },
    {
      id: "3",
      name: "Support",
      label: "Support",
      img: faq_img,
      handler: () => {
        window.open(
          "https://join.slack.com/t/chaosgenius/shared_invite/zt-140042uac-rrm~xbx9o_aydi6PTmp_Mg",
          "_blank",
          "rel=noopener noreferrer"
        );
        handleModal();
      }
    },
    {
      id: "4",
      name: "Logout",
      label: "Logout",
      img: logout,
      handler: onSignOut
    }
  ];
  return (
    <>
      <div className="app-header">
        <div className="logo-container" onClick={goHome}>
          <img src={CG_logo_img}></img>
          <span>Chaos Genius</span>
        </div>
        <div className="user-parent">
          {acc ? (
            <div className="acc-select-container">
              <Select
                value={acc}
                options={dwAccOptions}
                styles={customStyles}
                classNamePrefix="selectcategory"
                placeholder="select"
                isSearchable={true}
                components={{ DropdownIndicator }}
                onChange={(e) => {
                  changeAcc(e);
                  setSessinStorage("dw_acc_id", e?.id);
                }}
              />
            </div>
          ) : null}
          {userInfo?.info?.role === "admin" ? (
            <div className="settings-control" onClick={onOrgSettings}>
              <img src={gear_settings} alt="" />
              <p className="custom__tooltip">Settings</p>
            </div>
          ) : null}
          <div className="user" onClick={handleModal}>
            <div className="user-details">
              <div className="user-panel">{given_name?.substring(0, 1)}</div>
              <div className="profile-dropdown">
                <img src={chev_down} />
              </div>
              {showModal && (
                <DropDown
                  options={dropdown_options}
                  align="left"
                  close={handleModal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="load">
          <div className="preload"></div>
        </div>
      )}
    </>
  );
};
AppHeader.propTypes = {
  headerMenu: PropTypes.array,
  activeModule: PropTypes.string,
  activePage: PropTypes.string,
  changeActiveModule: PropTypes.func,
  changeActivePage: PropTypes.func,
  isMenuLoading: PropTypes.bool,
  acc: PropTypes.object,
  dwAccOptions: PropTypes.array,
  changeAcc: PropTypes.func
};
export default AppHeader;
