import React from "react";
import PropTypes from "prop-types";
import "./sidebar.scss";
import { getIconFromModule } from "../../utils/dashboard-helper";
import { getSessionStorage } from "../../utils/storage-helper";
const Sidebar = ({
  activeModule,
  changeActiveModule,
  headerMenu,
  isMenuLoading,
  userInfo,
  visible
}) => {
  const versionInfo = getSessionStorage("version");
  const { version } = versionInfo ? JSON.parse(versionInfo) : { version: "" };
  const menuView = (
    <div className="item-block">
      {headerMenu &&
        headerMenu?.map((module, index) => {
          const img = getIconFromModule(module?.page_display_name);
          return (
            <div
              onClick={() => {
                if (
                  userInfo?.info?.redirect_to_billing?.toString() === "false"
                ) {
                  changeActiveModule(
                    module?.page_display_name,
                    module?.noUrl ? "" : module?.page_url,
                    module?.children && !module?.dontDisplayChildren
                      ? module?.children[0]?.page_display_name
                      : undefined
                  );
                } else {
                  changeActiveModule("Settings", "", "Billing");
                }
              }}
              className={`item ${
                module?.page_display_name === "Data Source"
                  ? "mar-top-auto"
                  : ""
              } ${activeModule === module?.page_display_name ? "active" : ""}`}
              key={index}>
              <img
                src={activeModule === img?.module ? img?.active : img?.inactive}
              />
              <span>{module?.page_display_name}</span>
            </div>
          );
        })}
    </div>
  );

  return visible ? (
    <div className="sidebar-container">
      {!isMenuLoading
        ? headerMenu && headerMenu.length
          ? menuView
          : null
        : null}
      {version && <span className="app-version">Version {version}</span>}
    </div>
  ) : null;
};
Sidebar.propTypes = {
  activeModule: PropTypes.string,
  changeActiveModule: PropTypes.func,
  headerMenu: PropTypes.array,
  isMenuLoading: PropTypes.bool,
  userInfo: PropTypes.object,
  visible: PropTypes.bool
};
export default Sidebar;
