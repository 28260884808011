import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import PropTypes from "prop-types";

function StackGraph({ options }) {
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}
StackGraph.propTypes = {
  options: PropTypes.object
};

export default StackGraph;
