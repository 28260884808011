import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import "./whrecommendations.scss";
import chev_down from "../../assets/images/common/chev_down.svg";
import {
  PrettyFormat,
  createColDefs,
  getValueObj,
  handleGridResolution
} from "../../utils/common-helper";
import GridComponent from "../../components/GridComponent";
import Select, { components } from "react-select";
import { getWHRecommendationProps } from "./whrecommendationscoldefs";
import {
  getRecUpdateDate,
  getWHRecommendationDateRange,
  getWHRecommendations
} from "../../utils/warehouse-helper";
import { formatDateTime, getTimezone } from "../../utils/date-helper";
import { getException } from "../../utils/exceptiondictionary";
import { CustomTooltip } from "../../utils/tooltip-helper";
import apply_rec from "../../assets/images/common/apply_rec.svg";
import copy from "../../assets/images/connection/copy1.svg";
import { getBasicToastSettings } from "../../utils/toast-helper";
import { useToast } from "react-toast-wnm";
import { getSessionStorage } from "../../utils/storage-helper";
import Button from "../../components/customElements/Button";
import ExportCSV from "../../assets/images/grid/exportcsv.svg";
import CustomFilter from "../../components/CustomFilter/CustomFilter";
import FilterIcon from "../../assets/images/grid/Filter.svg";
import {
  WHRecColumns,
  applyFilterToGrid,
  clearAllFilters,
  filterTypes,
  postFilter
} from "../../utils/filter-helper";

const WHRecommendations = ({ acc, changeActiveModule }) => {
  const [hasTrialFallback] = useState(
    getSessionStorage("plan_name").toLowerCase() === "trial" ? true : false
  );
  const toast = useToast();
  const testRef = useRef(null);
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };

  const [customProps] = useState({
    ...getWHRecommendationProps(hasTrialFallback),
    warehouse_name: {
      width: 230,
      filter: false,
      cellRenderer: (params) => {
        return hasTrialFallback && params?.rowIndex > 1
          ? params?.value
          : CustomTooltip(
              params.value,
              params.value,
              10,
              undefined,
              undefined,
              <button
                className="qry-detail-btn"
                onClick={
                  hasTrialFallback && params?.rowIndex > 1
                    ? () => {
                        return null;
                      }
                    : () => {
                        //gotoQryDetail(params, dateRange, acc);
                        setIsRecModalOpen(true);
                        setWHName(params?.value);
                        setWHRecs(
                          JSON.parse(params?.data?.recommendations_json)
                        );
                      }
                }>
                <div className="img-container">
                  <img src={apply_rec} />
                </div>
                Apply Recommendations
              </button>
            );
      },
      cellClass: "grid-cell-left-aligned",
      suppressSizeToFit: true
    }
  });
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  const univerSalStyle = {
    cellStyle: (params) => {
      if (hasTrialFallback && params.rowIndex > 1) {
        return {
          borderRight: "1px solid #eaeaeb",
          userSelect: "none",
          "-webkit-user-select": "none"
        };
      }
      return { borderRight: "1px solid #eaeaeb" };
    }
  };

  const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [gridFallback, setGridFallback] = useState(false);
  const [timeStamp, setTimeStamp] = useState("");
  const [isRecModalOpen, setIsRecModalOpen] = useState(false);
  const [wHName, setWHName] = useState(null);
  const [wHRecs, setWHRecs] = useState(null);
  const [noRowsText, setNoRowsText] = useState(
    "Warehouse recommendations for the selected period or account are currently unavailable."
  );

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([]);
  const [gridResolution, setGridResolution] = useState(true);
  const [gridData, setGridData] = useState(null);
  const [downloadCSV, setDownloadCSV] = useState(false);
  const [showFilters, setShowFilters] = useState(false);
  const [filterApplied, setFilterApplied] = useState(false);
  const [applyFilter, setApplyFilter] = useState([
    {
      column: WHRecColumns?.[0],
      condition: filterTypes?.[0],
      value: [],
      dropDown: false,
      previuosValue: [],
      previousMenuList: []
    }
  ]);

  const getTableData = async (dateRange) => {
    try {
      if (
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        acc &&
        Object.keys(acc).length > 0
      ) {
        const data = await getWHRecommendations({
          data_wh_id: acc?.id,
          date_range: dateRange?.label
        });
        if (data && data?.errorstatus) {
          clearData();
          setNoRowsText(data?.text);
          setGridLoading(false);
          setIsPageLoading(false);
          if (gridFallback) {
            setGridFallback(false);
          }
        } else {
          const columns = [
            "warehouse_name",
            "warehouse_config",
            "observations",
            "warehouse_recommendations",
            "credits_used",
            "warehouse_cost",
            "wh_utilization",
            "warehouse_idle_creds",
            "failed_queries_cost",
            "avg_daily_bytes_spilled_remote_gb",
            "avg_queued_overload_time"
          ];
          const colDefsArr = createColDefs(
            columns,
            customProps,
            univerSalStyle,
            data?.column_display_name_mappping
          );
          if (colDefsArr && data?.data) {
            const newData = colDefsArr.map((colDef) => ({
              ...colDef,
              unSortIcon: true,
              sortable: true,
              filter: false
            }));
            setColumnDefs(newData);
            setRowData(data?.data);
            setGridData(data?.data);
            setGridLoading(false);
            setIsPageLoading(false);
            if (gridFallback) {
              setGridFallback(false);
            }
            let screenInnerWidth = window.innerWidth;
            let gridWidth = Object.values(newData).reduce((sum, prop) => {
              if (typeof prop.width === "number" && !isNaN(prop.width)) {
                return sum + prop.width;
              }
              return sum;
            }, 0);
            setGridResolution(
              handleGridResolution(gridWidth, screenInnerWidth)
            );
          }
        }
      }
    } catch (err) {
      console.log(err);
      setNoRowsText(
        "Warehouse recommendations for the selected period or account are currently unavailable."
      );
      setGridLoading(false);
      setIsPageLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
    }
  };

  const copyInstruction = () => {
    if (testRef && testRef?.current) {
      navigator.clipboard.writeText(
        testRef?.current?.innerText && testRef?.current?.innerText
      );
      customToast({ type: "success", ...getException("QueryCopySuccessful") });
    }
  };

  const getLastUpdate = async (dateRange, data_wh_id) => {
    try {
      const UpdateDate = await getRecUpdateDate({
        date_range: dateRange?.label,
        data_wh_id
      });
      if (UpdateDate && UpdateDate?.last_updated) {
        setTimeStamp(
          `${formatDateTime(
            UpdateDate?.last_updated.toString(),
            true,
            false,
            false
          )} (${getTimezone()})`
        );
      } else {
        setTimeStamp("");
      }
    } catch (err) {
      setTimeStamp("");
      console.log(err);
    }
  };

  const clearData = () => {
    setRowData([]);
    setGridData([]);
  };

  // Filter Functionalities Start

  const handleExport = () => {
    if (!hasTrialFallback) {
      setDownloadCSV(true);
      setTimeout(() => {
        setDownloadCSV(false);
      }, 3000);
    }
  };
  const handlePopover = (e) => {
    if (!hasTrialFallback) {
      e?.stopPropagation();
      setShowFilters(!showFilters);
    }
  };

  const clearGridFilter = () => {
    setGridData(rowData);
    setFilterApplied(false);
    const filterData = clearAllFilters(applyFilter);
    setApplyFilter(filterData);
  };
  // Filter Function
  const handleGridFilter = (applyifilters) => {
    setGridLoading(true);
    const filteredData = applyFilterToGrid(applyifilters, rowData);
    setGridData(filteredData);
    const handleFilterValue = postFilter(applyifilters);
    setApplyFilter(handleFilterValue);
    setFilterApplied(true);

    setTimeout(() => {
      setGridLoading(false);
    }, 1500);
  };

  const getData = async () => {
    let dateRangeArr = [];
    try {
      const dateRangeObj = await getWHRecommendationDateRange(
        acc ? acc?.id : ""
      );
      if (dateRangeObj?.values) {
        dateRangeArr = getValueObj(dateRangeObj?.values);
        setDateRangeOptions(dateRangeArr);
        setDateRange(dateRangeArr[0]);
      }
      if (dateRangeArr && acc) {
        getLastUpdate(dateRangeArr[0], acc?.id);
      }
      if (dateRangeArr && acc) {
        getTableData(dateRangeArr[0]);
      } else {
        clearData();
        setGridLoading(false);
        setIsPageLoading(false);
        if (gridFallback) {
          setGridFallback(false);
        }
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      const message = getException(err?.response?.data?.errorKey);
      setNoRowsText(message?.description);
      if (!gridFallback) {
        setGridFallback(true);
      }
    }
  };

  useEffect(() => {
    setIsPageLoading(true);
  }, []);

  useEffect(() => {
    const fetchData = () => {
      setGridLoading(true);
      if (acc) {
        getData();
      }
    };
    fetchData();
  }, [acc]);

  // Example using Grid's API
  // const buttonListener = useCallback((e) => {
  //   gridRef.current.api.deselectAll();
  // }, []);

  return (
    <>
      <div
        className={`plans-modal-wrapper  ${
          isRecModalOpen ? "show" : ""
        }`}></div>
      <div
        className={`modal-container recs-modal ${
          isRecModalOpen ? "show" : ""
        }`}>
        <div
          className={`voucher-modal-dialog  ${isRecModalOpen ? "show" : ""}`}>
          <div className="modal-content">
            <div className="modal-header">
              <span className="modal-title small-font">
                Recommendations for {wHName} warehouse
              </span>
              <button
                className="close"
                onClick={() => {
                  setIsRecModalOpen(!isRecModalOpen);
                }}>
                <span>&#xD7;</span>
              </button>
            </div>
            <div className="modal-body">
              {wHRecs && wHRecs?.length ? (
                <div className="recs__container">
                  <div className="qry__container">
                    <div
                      className="copy-image"
                      onClick={() => {
                        copyInstruction();
                      }}>
                      {<img className="copy" src={copy} />}
                    </div>
                    <div className="raw-div" ref={testRef}>
                      {wHRecs?.map((value, index) => {
                        return (
                          <div className="rec__parent" key={index}>
                            {value?.rec ? (
                              <p className="rec_comment">{`-- Recommendation ${
                                index + 1
                              }. ${value?.rec}`}</p>
                            ) : null}
                            {value?.application_statement
                              ? PrettyFormat(
                                  `${value?.application_statement};`,
                                  "sql"
                                )
                              : null}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="whrec-container overflow-auto">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                <div className="header">
                  <span>Warehouse Recommendations</span>
                </div>
                {timeStamp ? (
                  <div className="timestamp-container">
                    <span>
                      Last Updated:{" "}
                      <span className="subheader-bold">{timeStamp}</span>
                    </span>
                  </div>
                ) : null}
              </div>
            </div>
            <div className="whgrid-section">
              <div className="whgrid-filter-container">
                <div className="filter-section">
                  <Button
                    onClick={handlePopover}
                    disabled={hasTrialFallback}
                    className={
                      showFilters
                        ? "qg-filter-button-active "
                        : filterApplied
                        ? "qg-filter-active"
                        : "qg-filter-button"
                    }>
                    <img src={FilterIcon} alt="" />
                    <span>Filter</span>
                  </Button>
                  {showFilters && !hasTrialFallback && (
                    <CustomFilter
                      column={WHRecColumns}
                      gridData={rowData}
                      applyFilter={applyFilter}
                      setApplyFilter={setApplyFilter}
                      close={handlePopover}
                      handleFilter={handleGridFilter}
                      clearFilter={clearGridFilter}
                      isMenuOpen={showFilters}
                    />
                  )}
                </div>
                <div className="export-section ">
                  <div className="select-container">
                    <Select
                      value={dateRange}
                      options={dateRangeOptions}
                      styles={customStyles}
                      classNamePrefix="selectcategory"
                      placeholder="select"
                      isSearchable={false}
                      components={{ DropdownIndicator }}
                      onChange={(e) => {
                        setGridLoading(true);
                        setDateRange(e);
                        getLastUpdate(e, acc?.id);

                        getTableData(e);
                      }}
                    />
                  </div>
                  <div className="export-container">
                    <Button
                      disabled={hasTrialFallback}
                      onClick={handleExport}
                      className={"qg-filter-button"}>
                      <img src={ExportCSV} alt="" />
                      <span>Export</span>
                    </Button>
                  </div>
                </div>
              </div>

              <div className="grid-block">
                <GridComponent
                  columnDefs={columnDefs}
                  rowData={gridData}
                  gridFallback={gridFallback}
                  gridStyle={{
                    height: 500,
                    avoidAutoHeightOverflow: true,
                    trialFallbackClass: "custom__65__top"
                  }}
                  downloadCSV={downloadCSV}
                  gridLoading={gridLoading}
                  noRowsText={noRowsText}
                  tableName={"Warehouse Recommendations"}
                  hasTrialFallback={hasTrialFallback}
                  changeActiveModule={changeActiveModule}
                  suppressColumnsSize={gridResolution}
                />
              </div>
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
WHRecommendations.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object,
  changeActiveModule: PropTypes.func
};
export default WHRecommendations;
