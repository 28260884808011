import React from "react";
import PropTypes from "prop-types";
import { roundToSigDigits } from "../../../utils/common-helper";
import infoIcon from "../../../assets/images/grid/information.svg";
import { CustomTooltip } from "../../../utils/tooltip-helper";
import { formatDate } from "../../../utils/date-helper";
import { costFormatter } from "../../../utils/organization-helper";

function DataCards({ data, informationsText }) {
  const icon = <img src={infoIcon} alt={""} />;
  return (
    <div className="summary-cards-container">
      <p className="title">{data?.metric_name}</p>
      {!informationsText ? (
        <p className="summary">
          {`${data?.unit_prefix ? data?.unit_prefix : ""}${roundToSigDigits(
            data?.value,
            2,
            false,
            2
          ).toLocaleString("en-US")} ${
            data?.unit_suffix ? data?.unit_suffix : ""
          }`}
        </p>
      ) : (
        <>
          {data?.metric_type === "date" ? (
            <p className="summary">
              {data?.value ? formatDate(data.value) : "-"}
            </p>
          ) : data?.metric_type === "cost" ? (
            <p className="summary">
              {data?.value ? `${costFormatter(data.value)}` : "-"}
            </p>
          ) : (
            data?.metric_type === "percentage" && (
              <p className="summary">
                {data?.value
                  ? `${roundToSigDigits(
                      data?.value * 100,
                      2,
                      false,
                      2
                    ).toLocaleString("en-US")}%`
                  : "-"}
              </p>
            )
          )}
        </>
      )}
      {informationsText && (
        <div className="info-icon">
          {CustomTooltip(data.info, icon, 0, "info-tooltip")}
        </div>
      )}
    </div>
  );
}

DataCards.propTypes = {
  data: PropTypes.object,
  informationsText: PropTypes.bool
};
export default DataCards;
