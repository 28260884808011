import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./qtop.scss";
import { CustomTooltip } from "../../utils/tooltip-helper";
import drpdwn from "../../assets/images/common/drpdwn_indicator.svg";
import {
  createColDefs,
  getValueObj,
  PrettyFormat,
  roundToSigDigits
} from "../../utils/common-helper";
import GridComponent from "../../components/GridComponent";
import Select, { components } from "react-select";
import { getQueryByCost } from "../../utils/user-helper";
import { getTop50QueriesDateRange } from "../../utils/query-helper";

const QTop = ({ acc }) => {
  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
      //width: 180
    })
  };

  const customProps = {
    index: {
      type: "rightAligned",
      width: 75
    },
    query_text_regex: {
      width: 350,
      filter: true,
      cellRenderer: (params) => {
        return CustomTooltip(PrettyFormat(params.value), params.value, 35);
      },
      cellClass: "grid-cell-left-aligned"
    },
    warehouses: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    users: {
      width: 150,
      cellClass: "grid-cell-left-aligned",
      cellRenderer: (params) => {
        return CustomTooltip(params.value, params.value, 10);
      }
    },
    count: {
      type: "rightAligned",
      width: 100,
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_queued_overload_time: {
      width: 183,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_execution_time: {
      width: 150,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_execution_time: {
      width: 150,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_queued_overload_time: {
      width: 183,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_bytes_spilled_to_remote_storage: {
      width: 166,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_query_cost: {
      width: 100,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_query_cost: {
      width: 165,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    sum_gb_spilled_to_remote_storage: {
      width: 166,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    },
    avg_gb_spilled_to_remote_storage: {
      width: 166,
      type: "rightAligned",
      cellRenderer: (params) => {
        return roundToSigDigits(params?.value, 2, false);
      }
    }
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={drpdwn} />
      </components.DropdownIndicator>
    );
  };
  const univerSalStyle = {
    cellStyle: { borderRight: "1px solid #eaeaeb" }
  };

  const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [gridLoading, setGridLoading] = useState(true);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [gridFallback, setGridFallback] = useState(false);

  // Each Column Definition results in one Column.
  const [columnDefs, setColumnDefs] = useState([]);

  const getTableData = async (dateRange) => {
    try {
      if (
        dateRange &&
        Object.keys(dateRange).length > 0 &&
        acc &&
        Object.keys(acc).length > 0
      ) {
        const data = await getQueryByCost({
          data_wh_id: acc?.id,
          date_range: dateRange?.label
        });
        if (data) {
          const colDefsArr = createColDefs(
            data?.columns,
            customProps,
            univerSalStyle,
            data?.column_display_name_mappping
          );
          if (colDefsArr && data?.data) {
            setColumnDefs(colDefsArr);
            setRowData(data?.data);
          }
        } else {
          clearData();
        }
        setGridLoading(false);
        if (gridFallback) {
          setGridFallback(false);
        }
      }
    } catch (err) {
      console.log(err);
      setGridLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
    }
  };

  const clearData = () => {
    setRowData([]);
  };

  const getData = async () => {
    let dateRangeArr = [];
    try {
      const dateRangeObj = await getTop50QueriesDateRange(acc ? acc.id : "");
      if (dateRangeObj?.values) {
        dateRangeArr = getValueObj(dateRangeObj?.values);
        setDateRangeOptions(dateRangeArr);
        setDateRange(dateRangeArr[0]);
      }
      if (dateRangeArr && acc) {
        getTableData(dateRangeArr[0]);
      } else {
        clearData();
      }
      setGridLoading(false);
      setIsPageLoading(false);
      if (gridFallback) {
        setGridFallback(false);
      }
    } catch (err) {
      clearData();
      setGridLoading(false);
      setIsPageLoading(false);
      if (!gridFallback) {
        setGridFallback(true);
      }
      console.log(err);
    }
  };
  const [breadCrumbs] = useState(false);
  useEffect(() => {
    setIsPageLoading(true);
    setGridLoading(true);
    getData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let dateRangeArr = [];
      setGridLoading(true);
      if (acc) {
        const dateRangeObj = await getTop50QueriesDateRange(acc ? acc?.id : "");
        if (dateRangeObj?.values) {
          dateRangeArr = getValueObj(dateRangeObj?.values);
          setDateRangeOptions(dateRangeArr);
          setDateRange(dateRangeArr[0]);
        }
        if (dateRangeArr && dateRangeArr?.length) {
          getTableData(dateRangeArr[0]);
        }
      }
    };
    fetchData();
  }, [acc]);

  // Example using Grid's API
  // const buttonListener = useCallback((e) => {
  //   gridRef.current.api.deselectAll();
  // }, []);

  return (
    <>
      <div className="qtop-container">
        {!isPageLoading ? (
          <>
            <div className="header-container">
              <div className="header-block">
                {breadCrumbs ? (
                  <div className="breadcrumbs-container"></div>
                ) : null}
                <div className="header">
                  <span>Top 50 Queries By Cost</span>
                </div>
              </div>
              <div className="top-filter">
                <div className="select-container">
                  <label>Date Range</label>
                  <Select
                    value={dateRange}
                    options={dateRangeOptions}
                    styles={customStyles}
                    classNamePrefix="selectcategory"
                    placeholder="select"
                    isSearchable={false}
                    components={{ DropdownIndicator }}
                    onChange={(e) => {
                      setDateRange(e);
                      setGridLoading(true);
                      getTableData(e);
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="grid-block">
              <GridComponent
                columnDefs={columnDefs}
                rowData={rowData}
                gridFallback={gridFallback}
                gridStyle={{ height: 500 }}
                gridLoading={gridLoading}
                noRowsText={
                  "Top 50 for the selected period or account are currently unavailable. Coming soon..."
                }
              />
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
QTop.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object
};
export default QTop;
