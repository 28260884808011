import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "./iframehandler.scss";
import { TOKEN_INTERVAL } from "../../utils/constants";
import { setTokenCookie } from "../../utils/user-helper";
import { handlePostMessage } from "../../utils/streamlit-util";
const IframeHandler = ({ url, acc }) => {
  const [cookiesSet, setCookiesSet] = useState(false);
  const getTokenforIframe = async () => {
    try {
      const data = await setTokenCookie();
      if (data && data?.message === "Cookies Set") {
        setCookiesSet(true);
      }
    } catch (err) {
      console.log(err);
      setCookiesSet(false);
    }
  };
  useEffect(() => {
    const handler = (ev) => {
      handlePostMessage(ev, url);
    };
    window.addEventListener("message", handler);
    getTokenforIframe();
    const intervalID = setInterval(() => {
      getTokenforIframe();
    }, TOKEN_INTERVAL);
    return () => {
      window.removeEventListener("message", handler);
      clearInterval(intervalID);
    };
  }, []);

  const finalUrlStr = url + (acc ? `&sf_account_id=${acc?.id}` : "");
  return cookiesSet ? (
    <div className="iframe-container">
      {finalUrlStr && (
        <iframe
          className="cg-frame"
          width={"100%"}
          height={"100%"}
          src={finalUrlStr}
        />
      )}
    </div>
  ) : null;
};

IframeHandler.propTypes = {
  url: PropTypes.string,
  acc: PropTypes.object
};

export default IframeHandler;
