export const handlePostMessage = (ev, url) => {
  const obj = new URL(url);
  if (ev?.origin === obj?.origin) {
    if (ev) {
      switch (ev?.data?.message_id) {
        case "TOKEN_EXPIRED": {
          window?.location?.reload(false);
          break;
        }
        case "DATE_FILTER_CHANGE": {
          window?.location?.reload(false);
          break;
        }
        default: {
          return;
        }
      }
    }
  }
};
