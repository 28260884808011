import React, { useEffect, useState } from "react";
import "./costreporting.scss";
import Select, { components } from "react-select";
import { getValueObj } from "../../utils/common-helper";
import PropTypes from "prop-types";
import chev_down from "../../assets/images/common/chev_down.svg";
import StackGraphContainer from "../../components/CostReporting/StackGraphContainer";
import GridComponent from "../../components/GridComponent";
import Graph_icon_active from "../../assets/images/grid/Graph_icon_active.svg";
import Graph_icon_default from "../../assets/images/grid/Graph_icon_default.svg";
import Bar_icon_active from "../../assets/images/grid/Bar_icon_active.svg";
import Bar_Icon_default from "../../assets/images/grid/Bar_Icon_default.svg";
import {
  createGraphData,
  createTableColumn,
  formatPageData,
  getCRGraphData,
  getCRdateRangeDropdowns
} from "../../utils/cost-reporting-helper";
import { getcostcentres } from "../../utils/chargeback-helper";
import EmptyState from "../../components/Chargeback/EmptyState";
import { useNavigate } from "react-router-dom";

function CostReporting({ acc }) {
  const navigate = useNavigate();
  const [dateRange, setDateRange] = useState(null);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);

  const [isPageLoading, setIsPageLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("1");

  const [rawData, setRawData] = useState(null);
  const [pageData, setPageData] = useState(null);
  const [graphData, setGraphData] = useState(null);

  const [colDefs, setColdefs] = useState(null);

  const handleModal = () => {
    navigate(`/dashboard/chargeback/costcenter?modal=${true}`);
  }

  const tabs = [
    {
      id: "1",
      label: (
        <img
          src={activeTab === "1" ? Bar_icon_active : Bar_Icon_default}
          alt=""
        />
      )
    },
    {
      id: "2",
      label: (
        <img
          src={activeTab === "2" ? Graph_icon_active : Graph_icon_default}
          alt=""
        />
      )
    }
  ];
  const tabView =
    tabs &&
    tabs.map((tab) => {
      return (
        <div
          className={`tab ${tab?.id === activeTab ? "active" : "normal-btn"}`}
          key={tab?.id}
          onClick={() => {
            setActiveTab(tab?.id);
          }}>
          <span className="title">{tab?.label}</span>
        </div>
      );
    });

  const getAllcostcenters = async (costCenters) => {
    try {
      const response = await getcostcentres();
      if (response) {
        const final_data = formatPageData(response, costCenters);
        setPageData(final_data);
        const getGraphData = createGraphData(final_data);
        setGraphData(getGraphData);
        setIsPageLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsPageLoading(false);
    }
  };

  const getAPIs = async (acc) => {
    if (acc) {
      try {
        let dateRangeOption = await getCRdateRangeDropdowns(acc?.id);
        if (dateRangeOption?.values) {
          const options = getValueObj(dateRangeOption?.values);
          setDateRangeOptions(options);
          setDateRange(options[0]);
          try {
            const costCenters = await getCRGraphData(acc?.id, options[0].value);
            if (costCenters) {
              const colsdeff = createTableColumn(costCenters);
              setColdefs(colsdeff);
              await getAllcostcenters(costCenters);
              setRawData(costCenters);
              setIsPageLoading(false);
            }
          } catch (error) {
            console.log(error);
            setIsPageLoading(false);
            resetData();
          }
        }
      } catch (er) {
        console.log(er);
        setIsPageLoading(false);
        resetData();
      }
    }
  };

  const resetData = () => {
    setRawData([]);
    setPageData([]);
    setGraphData([]);
  };
  useEffect(() => {
    setIsPageLoading(true);
    getAPIs(acc);
  }, [acc]);

  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
    })
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={chev_down} />
      </components.DropdownIndicator>
    );
  };
  DropdownIndicator.propTypes = {
    isDisabled: PropTypes.bool
  };
  return !isPageLoading ? (
    <div className="cr-main-wrapper">
      <div className="cr-container">
        <div className="cr-header">
          <p>Overall Cost Trend</p>
          <div className="date-tab-container">
            <div className="tabs-container ">{tabView}</div>
            <div className="select-container">
              <Select
                value={dateRange}
                options={dateRangeOptions}
                styles={customStyles}
                classNamePrefix="selectcategory"
                placeholder="select"
                isSearchable={false}
                components={{ DropdownIndicator }}
                onChange={(e) => {
                  setIsPageLoading(true);
                  setDateRange(e);
                  setIsPageLoading(false);
                }}
              />
            </div>
          </div>
        </div>
        {graphData?.length > 0 && (
          <div className="stack-graph-container">
            <StackGraphContainer
              activeTab={activeTab}
              rawData={rawData}
              graphData={graphData}
            />
          </div>
        )}
        {pageData.length > 0 ? (
          <div className="grid-container">
            <GridComponent
              columnDefs={colDefs}
              rowData={pageData}
              gridLoading={false}
              noRowsText={"No data for the selected period."}
              suppressColumnsSize={true}
            />
          </div>
        ) : (
          <div className="grid-container">
            <EmptyState handleModal={handleModal} />
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className="load">
      <div className="preload"></div>
    </div>
  );
}
CostReporting.propTypes = {
  acc: PropTypes.object
};
export default CostReporting;
