import React from "react";
import { attachParams } from "./url-helper";
import { getRequest, postRequest } from "./http-helper";
import download_icon from "../assets/images/common/download.svg";
import { useToast } from "react-toast-wnm";
import { getBasicToastSettings } from "./toast-helper";
import PropTypes from "prop-types";

export const getQGDateRange = async (wh_id) => {
  const params = { data_wh_id: wh_id ? wh_id : "" };
  const URL = attachParams(
    `/v1/analytics/queries/query_groups/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getTop50QueriesDateRange = async (wh_id) => {
  const params = { data_wh_id: wh_id ? wh_id : "" };
  const URL = attachParams(
    `/v1/analytics/queries/query_by_cost/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const requestRec = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range
  };
  const URL = attachParams(
    `v1/analytics/queries/query_recommendations/request`,
    params
  );
  const { data, error } = await postRequest({
    url: URL,
    //data: JSON.stringify(params),
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getFailedQueriesDateRange = async (wh_id) => {
  const params = { data_wh_id: wh_id ? wh_id : "" };
  const URL = attachParams(
    `/v1/analytics/queries/failed_queries/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getQueryRecommendationsDateRange = async (wh_id) => {
  const params = { data_wh_id: wh_id ? wh_id : "" };
  const URL = attachParams(
    `/v1/analytics/queries/query_recommendations/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getQueryDetailsInfo = async (date_range, wh_id, regex_id) => {
  const params = {
    data_wh_id: wh_id ? wh_id : "",
    date_range: date_range,
    regex_id: regex_id
  };
  const URL = attachParams(`/v1/analytics/queries/query_group/info`, params);
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getQueryDetailsStats = async (date_range, wh_id, regex_id) => {
  const params = {
    data_wh_id: wh_id ? wh_id : "",
    date_range: date_range,
    regex_id: regex_id
  };
  const URL = attachParams(
    `/v1/analytics/queries/query_group/daily_stats`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getQueryDetailsHistogram = async (date_range, wh_id, regex_id) => {
  const params = {
    data_wh_id: wh_id ? wh_id : "",
    date_range: date_range,
    regex_id: regex_id
  };
  const URL = attachParams(
    `/v1/analytics/queries/query_group/histogram`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getQueryProfile = async (date_range, wh_id, regex_id) => {
  const params = {
    data_wh_id: wh_id ? wh_id : "",
    date_range: date_range,
    regex_id: regex_id
  };
  const URL = attachParams(
    `/v1/analytics/queries/query_group/profile/render`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};
export const getQueryProfileText = async (date_range, wh_id, regex_id) => {
  const params = {
    data_wh_id: wh_id ? wh_id : "",
    date_range: date_range,
    regex_id: regex_id
  };
  const URL = attachParams(`/v1/analytics/queries/query_group/text`, params);
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const downloadSample = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range,
    regex_id: fields?.regex_id,
    filter_type: fields?.filter_type
  };
  const URL = attachParams(
    `/v1/analytics/queries/query_group/query_sample`,
    params
  );
  const res = await getRequest({
    url: URL,
    responseType: "blob"
  });
  const { data, status, headers, error } = res;
  if (error) {
    return {
      status,
      error
    };
  } else if (data) {
    return {
      data,
      status,
      headers
    };
  }
};

export const PieDistributionAPI = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range,
    dimension: fields?.dimension
  };
  const URL = attachParams(
    `/v1/analytics/queries/failed_queries/pie_chart_distribution`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const DownloadLinkButton = (
  { data_wh_id, date_range, regex_id, filter_type },
  hasTrial
) => {
  const [loading, setLoading] = React.useState(false);
  const toast = useToast();
  const customToast = (data) => {
    const { type, header, description } = data;
    const sttngs = getBasicToastSettings(type, header, description);
    toast(sttngs);
  };
  const download = async () => {
    try {
      setLoading(true);
      const res = await downloadSample({
        data_wh_id,
        date_range,
        regex_id,
        filter_type
      });
      if (res != null) {
        if (res?.status === 404) {
          customToast({
            type: "error",
            header: "No data found",
            description: "No data found for the selected query."
          });
        } else if (res?.status === 200) {
          const { data, headers } = res;
          // Download as excel
          let filename =
            headers["content-disposition"]?.split("filename=")?.[1] ||
            "sample.xlsx";
          filename = filename.replace(/"/g, "");
          const url = window.URL.createObjectURL(data);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", filename);
          link.click();
          window.URL.revokeObjectURL(url);
        } else {
          throw new Error("Failed to download file");
        }
      } else {
        throw new Error("Failed to download file");
      }
    } catch (err) {
      customToast({
        type: "error",
        header: "Failed to download file",
        description: "Something went wrong. Please try after some time."
      });
    } finally {
      setLoading(false);
    }
  };

  const proxyDownload = () => {
    return null;
  };

  return (
    <div
      className="download-link"
      onClick={hasTrial ? proxyDownload : download}>
      {loading ? (
        <div className="download-loader"></div>
      ) : (
        <img src={download_icon} alt="download" />
      )}
    </div>
  );
};

DownloadLinkButton.propTypes = {
  data_wh_id: PropTypes.string,
  date_range: PropTypes.string,
  regex_id: PropTypes.string,
  filter_type: PropTypes.string
};
