import React from "react";
import PropTypes from "prop-types";
import warning_delete from "../../assets/images/dashboard/warning_delete.svg";

function DeleteForm({ isModalOpen, closeModal, handleDeleteClick, selected }) {
  return (
    <>
      <div className="modal-container add-user-modal"></div>
      <div className="form-container add-user-container">
        {isModalOpen && (
          <div className="form-parent">
            <div className="form-header no-border">
              <div className="header">
                <span></span>
              </div>
              <div
                className="close-img padding-right-16"
                onClick={() => {
                  closeModal(true);
                }}>
                <span>✕</span>
              </div>
            </div>
            <div className="delete-field-container">
              <div className="img-container">
                <img src={warning_delete} />
              </div>
              <div className="success-header-container">
                <div className="success-header">
                  <span>{`Do you want to remove permanently?`} </span>
                </div>
              </div>
            </div>
            <div className="button-container center-align button-delete">
              <button
                className="btn btn btn-dark medium-style"
                onClick={() => {
                  handleDeleteClick(selected);
                }}>
                Remove
              </button>
              <button
                onClick={() => {
                  closeModal();
                }}
                className="btn btn-outline-dark">
                Cancel
              </button>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
DeleteForm.propTypes = {
  isModalOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  handleDeleteClick: PropTypes.func,
  selected: PropTypes.object
};
export default DeleteForm;
