import { attachParams } from "./url-helper";
import { getRequest, patchRequest, postRequest } from "./http-helper";
import { Auth } from "aws-amplify";
import { getException } from "./exceptiondictionary";
import { getLocalStorage, getSessionStorage } from "./storage-helper";
export const getAppUserInfo = async (customToast) => {
  const params = {};
  const URL = attachParams(`/v1/account`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorKey,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else if (data) {
    return data;
  }
};

export const doOnboardAPI = async (fields) => {
  const params = {};
  const URL = attachParams(`/v1/user`, params);
  const { data, error } = await patchRequest({
    url: URL,
    data: {
      first_name: fields?.fname,
      last_name: fields?.lname,
      org_role: fields?.role
    }
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const accountSkipAPI = async () => {
  const params = {};
  const URL = attachParams(`/v1/account`, params);
  const { data, error } = await patchRequest({
    url: URL,
    data: {
      onboarded: true
    }
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const doInviteAPI = async (emails) => {
  const params = {
    users: emails?.map((email) => {
      return {
        email: email?.email?.trim()?.toLowerCase(),
        role: email?.role?.toLowerCase()
      };
    })
  };
  const URL = attachParams(`/v1/user/bulk_invite`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(params)
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const setTokenCookie = async () => {
  const URL = attachParams(`/v1/cookies/set-token`);
  const { data, error } = await postRequest({
    url: URL,
    withCredentials: true
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getMyInfo = async (customToast) => {
  const params = {};
  const URL = attachParams(`/v1/user/me`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorKey,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else if (data) {
    return data;
  }
};

export const setUpDW = async (fields, customToast) => {
  const params = {
    display_name: fields?.conname,
    host: fields?.accname,
    role: fields?.role,
    user: fields?.username,
    warehouse: fields?.warehouse,
    password: fields?.pword,
    database: fields?.database
  };
  const URL = attachParams(`/v1/data_warehouse_info`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(params)
  });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorType,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else if (data) {
    return data;
  }
};

export const appSignup = async (fields, customToast) => {
  const params = {
    email: fields?.email,
    account_name: fields?.account_name
  };
  const URL = attachParams(`/v1/signup`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(params)
  });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorType,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else if (data) {
    return data;
  }
};

export const completeDWConnection = async (dwID, fields, customToast) => {
  const params = {
    compute_rate: fields?.computeCost,
    storage_rate: fields?.storageCost,
    edition: fields?.sfVersion
  };
  const URL = attachParams(`/v1/data_warehouse_info/${dwID}/contract`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(params)
  });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorType,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else if (data) {
    return data;
  }
};

// export const deleteUserAcc = async (id) => {
//   //const params = { data_wh_id: id };
//   const { data, error } = await deleteRequest({
//     url: `http://temp-backend-api-2.us-west-2.elasticbeanstalk.com/v1/data_warehouse_info/${id}`,
//   });
//   if (error) {
//     return null;
//   } else if (data) {
//     return data;
//   }
// };

export const createOrg = async (params) => {
  const URL = attachParams(`/v1/signup`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(params)
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getAuthConfig = async (email) => {
  const URL = attachParams(`/v1/sso/login-options`, { email: email });
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const SignoutAPI = async (params) => {
  const URL = attachParams(`/v1/signout`);
  const { data, error } = await postRequest({
    url: URL,
    data: JSON.stringify(params)
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const SignOutUser = async (fn) => {
  try {
    const refresh_token = getSessionStorage("refreshToken");
    const client_id = getLocalStorage("webclientID");
    if (refresh_token && client_id) {
      const data = await SignoutAPI({
        refresh_token,
        client_id
      });
      console.log(data);
    }
    await Auth.signOut();
    if (fn) {
      fn();
    }
  } catch (error) {
    console.log("error signing out: ", error);
  }
};

export const getDWAcc = async () => {
  const URL = attachParams(`/v1/data_warehouse_info`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const setQM = async (params, customToast) => {
  const { status, data_wh_id } = params;
  const URL = attachParams(
    `/v1/data_warehouse_info/${data_wh_id}/monitoring_info/${status}`
  );
  const { data, error } = await postRequest({
    url: URL
  });
  if (error) {
    if (customToast) {
      customToast({
        type: "error",
        ...getException(
          error?.response?.data?.errorType,
          error?.response?.data?.message
        )
      });
    }
    return null;
  } else if (data) {
    return data;
  }
};

export const getRateSheet = async (dwId) => {
  const URL = attachParams(`/v1/data_warehouse_info/rate_sheet_info/${dwId}`);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getDateRange = async (accID) => {
  const params = {
    data_wh_id: accID
  };
  const URL = attachParams(
    `/v1/analytics/warehouses/overview/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getSortingOptions = async () => {
  const URL = attachParams(
    `/v1/analytics/queries/query_groups/sorting_options`
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};
export const getQueryGroupsData = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range,
    sorted_by: fields?.sorted_by
  };
  const URL = attachParams(`/v1/analytics/queries/query_groups`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getQueryByCost = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range
  };
  const URL = attachParams(`/v1/analytics/queries/query_by_cost`, params);
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getFailedQueriesSortingOptions = async () => {
  const URL = attachParams(
    `/v1/analytics/queries/failed_queries/queries/sorting_options`
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getFailedQueriesDistributionOptions = async () => {
  const URL = attachParams(
    `/v1/analytics/queries/failed_queries/distribution/options`
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getFQDistribution = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range,
    dimension: fields?.dimension
  };
  const URL = attachParams(
    `/v1/analytics/queries/failed_queries/distribution`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getFailedQueries = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range,
    sorted_by: fields?.sorted_by
  };
  const URL = attachParams(
    `/v1/analytics/queries/failed_queries/queries`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getQueryRecommendations = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range
  };
  const URL = attachParams(
    `/v1/analytics/queries/query_recommendations`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    throw error;
  } else if (data) {
    return data;
  }
};

export const getTotalQueries = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range
  };
  const URL = attachParams(
    `/v1/analytics/queries/failed_queries/totals`,
    params
  );
  const { data, error } = await getRequest({
    url: URL
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};
