import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import React from "react";
import PropTypes from "prop-types";

function Donut({ options }) {
  return <HighchartsReact highcharts={Highcharts} options={options} />;
}

Donut.propTypes = {
  options: PropTypes.object
};

export default Donut;
