import _ from "lodash";

export const filterTypes = [
  // { label: "Contains", value: "Contains" },
  // { label: "Not contains", value: "Not contains" },
  { label: "Equals", value: "Equals" }
  // { label: "Not equals", value: "Not equals" },
  // { label: "Starts with", value: "Starts with" },
  // { label: "Ends with", value: "Ends with" },
  // { label: "Blank", value: "Blank" },
  // { label: "Not blank", value: "Not blank" }
];
export const TGFilterColumns = [
  { label: "Table Name", value: "table_name" },
  { label: "Schema", value: "table_schema" },
  { label: "Database", value: "table_catalog" },
  { label: "Table Owner (Role)", value: "table_owner" }
];

export const CGWHFilterColumns = [
  { label: "Event Type", value: "Event Type" },
  { label: "Owner", value: "Owner (Role)" },
  { label: "User Name", value: "User Name" },
  { label: "Warehouse Name", value: "Warehouse Name" }
];
export const CGURFilterColumns = [
  { label: "Event Type", value: "Event Type" },
  { label: "Owner", value: "Owner (Role)" }
];
export const QGFilterColumns = [
  { label: "Warehouses", value: "warehouses" },
  { label: "Users", value: "users" }
];
export const FQFilterColumns = [
  { label: "Users", value: "users" },
  { label: "Warehouses", value: "warehouses" }
];
export const distributionFilterColumns = [
  { label: "Users", value: "user_name" }
];

export const WHRecColumns = [
  { label: "Warehouse Name", value: "warehouse_name" }
];

export const groupTableData = (data, value) => {
  const initailGrouping = _.groupBy(data, value);
  const FormatData = Object.keys(initailGrouping).map((item) => {
    const uniqData = item.trim().split(",");
    const splittedData = uniqData.map((val) => ({
      label: val,
      value: val
    }));
    let options = [].concat(...splittedData);
    return options;
  });
  const finalData = [].concat(...FormatData);
  let uniqueArray = _.uniqBy(finalData, "value");

  return uniqueArray;
};

export const checkValue = (filter) => {
  let flag = false;
  for (let i = 0; i < filter.length; i++) {
    if (filter[i].value.length <= 0) {
      flag = true;
      break;
    }
  }
  return flag;
};

export const applyFilterToGrid = (applyifilters, gridData) => {
  const filteredData = applyifilters.map((filterItem) => {
    const filterVal = filterItem.value.map((val) => {
      const deepfilter = gridData.filter((item) => {
        if (!val.value || val?.value?.toString() === "null") {
          return item[filterItem.column.value] === null;
        } else {
          if (item[filterItem.column.value] !== null) {
            return item[filterItem.column.value].includes(val.value);
          }
        }
      });
      return deepfilter;
    });
    const flattenedFilterVal = _.flattenDeep(filterVal);
    return flattenedFilterVal;
  });

  const mergedFilteredData = _.uniqWith(_.flattenDeep(filteredData), _.isEqual);
  return mergedFilteredData;
};

export const clearAllFilters = (applyFilter) => {
  const defaultState = [];
  let updatedArr = applyFilter?.filter((item, index) => index === 0);
  let new_obj = { ...updatedArr[0] };
  new_obj = { ...new_obj, value: [], previuosValue: [], previousMenuList: [] };
  defaultState.push(new_obj);

  return defaultState;
};

export const exportCSVcolumns = (columns) => {
  const new_arr = columns.map((val) =>
    val.field !== "Query Ids" && val.field !== "regex_id" ? val.field : null
  );
  return new_arr;
};

export const postFilter = (applyFilter) => {
  const newArr = applyFilter.map((item) => ({
    ...item,
    previuosValue: item.value,
    previousMenuList: item.defaultMenuList
  }));

  return newArr;
};
