// URL CONSTANTS
import { env } from "../env";
export const BASE_URL = env.REACT_APP_BASE_URL || "";

export const attachParams = (relativeUrl, params) => {
  let baseUrl = BASE_URL;

  if (baseUrl === "") {
    baseUrl = window.location.origin;
  }
  const url = new URL(relativeUrl, baseUrl);
  url.search = new URLSearchParams(params).toString();
  return url;
};
