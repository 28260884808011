import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import Donut from "../../ChartComponent/Donut";
import { roundToSigDigits } from "../../../utils/common-helper";
import { color__pallete } from "../../ChartComponent/chartConstants";

const DonutCharts = ({ chartData, additionalStyle }) => {
  const cardRef = useRef(null);
  const customColors = color__pallete?.neutral;
  const [calHeight, setCalHeight] = useState();

  useEffect(() => {
    if (cardRef.current) {
      const cardHeight = cardRef.current.offsetHeight;
      const percentage = 0.7 * cardHeight;
      setCalHeight(percentage);
    }
  }, []);
  const options = {
    chart: {
      type: "pie",
      animation:
        additionalStyle?.animation?.toString() === "false" ? false : true,
      backgroundColor: "transparent"
    },
    title: additionalStyle?.title?.text
      ? {
          text: `${additionalStyle?.title?.text}`,
          align: "center",
          verticalAlign: "middle",
          style: {
            color: "#222",
            fontWeight: "bold",
            fontSize: "14px"
          }
          //y: "70%"
        }
      : null,
    tooltip: {
      backgroundColor: "black",
      borderRadius: 5,
      formatter: function () {
        return `<div style="color:white;"><br/>
        <p>Name -  <b>${this.point.name}</b></p> <br/>
        <p>Percentage - <b>${roundToSigDigits(
          this.point.percentage,
          1,
          true
        )}%</b></p> <br/>
        <p>Total Amount - <b>$${roundToSigDigits(
          this.point.totalcost,
          1,
          true
        )}</b></p> <br/>
      </div>`;
      }
    },
    plotOptions: {
      pie: {
        innerSize: "50%",
        animation:
          additionalStyle?.animation?.toString() === "false" ? false : true,
        size: additionalStyle?.size ? additionalStyle?.size : calHeight,

        dataLabels: {
          enabled: true,
          // useHTML: true,
          //format: "<p class='truncate'>{y}%</p>",
          formatter: function () {
            return `${this.point.name} <b>(${roundToSigDigits(
              this.point.y,
              1,
              false,
              2
            )}%)</b>`;
          },
          padding: 4,
          distance: additionalStyle?.dataLabels?.distance
            ? additionalStyle?.dataLabels?.distance
            : "20%",
          color: "#222",
          style: {
            textOutline: 0,
            fontSize: "8px",
            textOverflow: "clip",
            fontWeight: "normal"
          }
        },
        // Remove arc shadow on hover
        states: {
          hover: {
            halo: {
              size: 0
            }
          }
        },
        // Move the legend to the right side
        center: window?.innerWidth > 1440 ? ["50%", "50%"] : ["50%", "50%"],
        showInLegend: true
      }
    },
    legend: {
      align: "right",
      verticalAlign: "top",
      layout: "vertical",
      itemMarginTop: 8,
      itemMarginBottom: 8,
      symbolWidth: 10,
      symbolHeight: 10,
      symbolRadius: 2,
      enabled: false,
      itemStyle: {
        width: 70,
        textOverflow: "ellipsis",
        fontSize: "10px"
      }
    },
    series: [
      {
        name: "Data",
        data: chartData,
        colors: customColors,
        startAngle: 340,
        animation:
          additionalStyle?.animation?.toString() === "false" ? false : true
      }
    ]
  };

  return (
    <div className="donut-chart-container">
      <div className="title-container">
        <h5>{chartData?.title}</h5>
        <p>{chartData?.subTitle}</p>
      </div>
      <div ref={cardRef} className="donut-chart">
        <Donut options={options} />
      </div>
    </div>
  );
};
DonutCharts.propTypes = {
  chartData: PropTypes.array,
  additionalStyle: PropTypes.object
};
export default DonutCharts;
