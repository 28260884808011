import React from "react";
import PropTypes from "prop-types";

import Drawer from "@mui/material/Drawer";

function Drawerwrapper(props) {
  const { open, children, handleClose, anchor, className } = props;
  return (
    <Drawer open={open} onClose={handleClose} anchor={anchor}>
      <div className={className}>
        {children}
      </div>
    </Drawer>
  );
}

Drawerwrapper.propTypes = {
  props: PropTypes.object,
  open: PropTypes.bool,
  children: PropTypes.array,
  handleClose: PropTypes.func,
  anchor: PropTypes.string,
  className: PropTypes.string,
};

export default Drawerwrapper;
