import trustedImgs from "../../assets/images/trusted/index";
const featureList = [
  { label: "Achieve full cost transparency and visibility" },
  { label: "Realise effortless 10-30% savings instantly" },
  { label: "Recommendations on warehouse right-sizing & workload optimization" },
  { label: "Anomaly detection & daily alerts" }
];
const trusted_orgs = [
  { image: trustedImgs.Accenture, style: { paddingBottom: 8 } },
  { image: trustedImgs.Hunters },
  { image: trustedImgs.Meriters },
  { image: trustedImgs.Aura },
  { image: trustedImgs.CoinSwitch },
  { image: trustedImgs.Blend360 },
];

export default { featureList, trusted_orgs };
