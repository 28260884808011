import { attachParams } from "./url-helper";
import { getRequest } from "./http-helper";
export const getTGSortingOptions = async () => {
  const URL = attachParams(`/v1/analytics/tables/table_groups/sorting_options`);
  const { data, error } = await getRequest({
    url: URL,

  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};

export const getTGDatabaseName = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range
  };
  const URL = attachParams(
    `/v1/analytics/tables/table_groups/filters/database_names`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};
export const getTGOwners = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range
  };
  const URL = attachParams(
    `/v1/analytics/tables/table_groups/filters/owners`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};
export const getTableGroupsData = async (fields) => {
  const params = {
    data_wh_id: fields?.data_wh_id,
    date_range: fields?.date_range,
    sorted_by: fields?.sorted_by
  };
  let URL = attachParams(`/v1/analytics/tables/table_groups`, params);
  if (fields?.database_name && fields?.database_name.length > 0) {
    fields?.database_name.forEach((element) => {
      URL += `&database_name=${element?.value}`;
    });
  }
  if (fields?.owner && fields?.owner.length > 0) {
    fields?.owner.forEach((element) => {
      URL += `&owner=${element?.value}`;
    });
  }
  const { data, error } = await getRequest({
    url: URL,
  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};
export const getTGDateRange = async (wh_id) => {
  const params = { data_wh_id: wh_id ? wh_id : "" };
  const URL = attachParams(
    `/v1/analytics/tables/table_groups/date_ranges`,
    params
  );
  const { data, error } = await getRequest({
    url: URL,

  });
  if (error) {
    return null;
  } else if (data) {
    return data;
  }
};
