import React, { useEffect, useState } from "react";
import "./organization.scss";
import PropTypes from "prop-types";
import Select, { components } from "react-select";
import chev_down from "../../assets/images/common/chev_down.svg";
import chev_down_disabled from "../../assets/images/common/chev_down_disabled.svg";
import { summaryTabs } from "../../utils/organization-helper";
import { getValueObj } from "../../utils/common-helper";
import { getHealthDashboardDateRangeAPI } from "../../utils/dashboard-helper";
import Contracts from "../../components/OrganizationSummary/Contracts";
import { formatDate } from "../../utils/date-helper";
import CostOverview from "../../components/OrganizationSummary/CostOverview";

function OrganizationSummary({ acc }) {
  const [dateRange, setDateRange] = useState(null);
  const [dateRangeOptions, setDateRangeOptions] = useState([]);

  const [isPageLoading, setIsPageLoading] = useState(true);

  const [activeTab, setActiveTab] = useState(null);
  const [contractData, setContractData] = useState(null);

  const tabView =
    summaryTabs &&
    summaryTabs.map((tab) => {
      return (
        <div
          className={`tab ${tab?.id === activeTab ? "active" : "normal-btn"}`}
          key={tab?.id}
          onClick={() => {
            setIsPageLoading(true);
            setActiveTab(tab?.id);
            setDateRange(dateRangeOptions[0]);
            setIsPageLoading(false);
          }}>
          <span className="title">{tab?.label}</span>
        </div>
      );
    });

  const customStyles = {
    control: (base) => ({
      ...base,
      background: "#FFFFFF",
      borderColor: "#E3E3E8"
    }),
    container: (provided) => ({
      ...provided
    })
  };

  const getAPIs = async (acc) => {
    if (acc) {
      try {
        let dateRangeArr = [];
        let dateRangeObj = await getHealthDashboardDateRangeAPI(acc?.id);

        if (dateRangeObj?.values) {
          dateRangeArr = getValueObj(dateRangeObj?.values);
          setDateRangeOptions(dateRangeArr);
          if (dateRange) {
            const dtObj = dateRangeArr.find((date) => {
              return date?.label === dateRange?.label;
            });
            if (dtObj && Object.keys(dtObj).length) {
              setDateRange(dtObj);
            } else {
              setDateRange(dateRangeArr[0]);
            }
          } else {
            setDateRange(dateRangeArr[0]);
          }
        }
        setActiveTab(activeTab??"1");
      } catch (er) {
        console.log(er);
        setIsPageLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsPageLoading(true);
    getAPIs(acc);
  }, [acc]);

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={props.isDisabled ? chev_down_disabled : chev_down} />
      </components.DropdownIndicator>
    );
  };
  DropdownIndicator.propTypes = {
    isDisabled: PropTypes.bool
  };

  return (
    activeTab && (
      <div className="summary-main-container">
        <div className="summary-header-container">
          <div className="header-block">
            <span>Organization Summary</span>
          </div>
          <div className="header-sub-title-container">
            <div className="header">
              <div className="tabs__container">{tabView}</div>
            </div>
            <div
              className={
                activeTab === "1"
                  ? "select-container"
                  : "contract-container select-container-disabled"
              }>
              <Select
                value={dateRange}
                options={dateRangeOptions}
                styles={customStyles}
                classNamePrefix="selectcategory"
                placeholder="select"
                isSearchable={false}
                isDisabled={activeTab === "1" ? false : true}
                components={{ DropdownIndicator }}
                onChange={(e) => {
                  setIsPageLoading(true);
                  setDateRange(e);
                }}
              />
            </div>
          </div>
          {activeTab === "2" && contractData?.contract_exists && (
            <div className="header-block">
              <p>
                Contract duration:{" "}
                <span>{formatDate(contractData?.contract_start_date)}</span> to{" "}
                <span>{formatDate(contractData?.contract_end_date)}</span>
              </p>
            </div>
          )}
        </div>
        {activeTab === "1" ? (
          <CostOverview
            acc={acc}
            isPageLoading={isPageLoading}
            setIsPageLoading={setIsPageLoading}
            dateRange={dateRange}
            activeTab={activeTab}
            dateRangeArr={dateRangeOptions}
          />
        ) : (
          <div className="sub-container contract-container">
            <Contracts
              acc={acc}
              isPageLoading={isPageLoading}
              setIsPageLoading={setIsPageLoading}
              dateRange={dateRange}
              contractData={contractData}
              setContractData={setContractData}
            />
          </div>
        )}
      </div>
    )
  );
}

OrganizationSummary.propTypes = {
  acc: PropTypes.object
};

export default OrganizationSummary;
