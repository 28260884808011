// import React, { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import "./warehouseoverview.scss";
// import drpdwn from "../../assets/images/common/drpdwn_indicator.svg";
// import {
//   createColDefs,
//   getValueObj,
//   roundToSigDigits
// } from "../../utils/common-helper";
// import GridComponent from "../../components/GridComponent";
// import Select, { components } from "react-select";
// import { getDateRange } from "../../utils/user-helper";
// import {
//   getWHOverview,
//   getWHOverviewTotals
// } from "../../utils/warehouse-helper";
// import { getWHOverviewProps } from "./whoverviewcoldefs";

// const WarehouseOverview = ({ acc }) => {
//   const customStyles = {
//     control: (base) => ({
//       ...base,
//       background: "#FFFFFF",
//       borderColor: "#E3E3E8"
//     }),
//     container: (provided) => ({
//       ...provided
//       //width: 180
//     })
//   };
//   const DropdownIndicator = (props) => {
//     return (
//       <components.DropdownIndicator {...props}>
//         <img src={drpdwn} />
//       </components.DropdownIndicator>
//     );
//   };
//   const univerSalStyle = {
//     cellStyle: { borderRight: "1px solid #eaeaeb" }
//   };
//   const customProps = getWHOverviewProps();

//   const [rowData, setRowData] = useState(null); // Set rowData to Array of Objects, one Object per Row
//   const [isPageLoading, setIsPageLoading] = useState(true);
//   const [gridLoading, setGridLoading] = useState(true);
//   const [dateRangeOptions, setDateRangeOptions] = useState([]);
//   const [dateRange, setDateRange] = useState(null);
//   const [gridFallback, setGridFallback] = useState(false);
//   const [total, setTotal] = useState(null);

//   // Each Column Definition results in one Column.
//   const [columnDefs, setColumnDefs] = useState([]);

//   const getTotals = async (dateRange) => {
//     try {
//       if (
//         dateRange &&
//         Object.keys(dateRange).length > 0 &&
//         Object.keys(acc).length > 0
//       ) {
//         const totalObj = await getWHOverviewTotals({
//           data_wh_id: acc?.id,
//           date_range: dateRange?.label
//         });
//         if (totalObj?.values) {
//           setTotal(totalObj?.values);
//         }
//       }
//     } catch (err) {
//       console.log(err);
//     }
//   };

//   const getTableData = async (dateRange) => {
//     try {
//       if (
//         dateRange &&
//         Object.keys(dateRange).length > 0 &&
//         acc &&
//         Object.keys(acc).length > 0
//       ) {
//         const data = await getWHOverview({
//           data_wh_id: acc?.id,
//           date_range: dateRange?.label
//         });
//         if (data) {
//           const colDefsArr = createColDefs(
//             data?.columns,
//             customProps,
//             univerSalStyle,
//             data?.column_display_name_mappping
//           );
//           if (colDefsArr && data?.data) {
//             setColumnDefs(colDefsArr);
//             setRowData(data?.data);
//           }
//         } else {
//           clearData();
//         }
//         setGridLoading(false);
//         if (gridFallback) {
//           setGridFallback(false);
//         }
//       }
//     } catch (err) {
//       console.log(err);
//       setGridLoading(false);
//       if (!gridFallback) {
//         setGridFallback(true);
//       }
//     }
//   };

//   const clearData = () => {
//     setRowData(null);
//   };

//   const getData = async () => {
//     let dateRangeArr = [];
//     try {
//       const dateRangeObj = await getDateRange();
//       if (dateRangeObj?.values) {
//         dateRangeArr = getValueObj(dateRangeObj?.values);
//         setDateRangeOptions(dateRangeArr);
//         setDateRange(dateRangeArr[0]);
//       }
//       if (dateRangeArr && acc) {
//         getTableData(dateRangeArr[0]);
//       } else {
//         clearData();
//       }
//       if (dateRangeArr && acc) {
//         getTotals(dateRangeArr[0]);
//       } else {
//         clearData();
//       }
//       setGridLoading(false);
//       setIsPageLoading(false);
//       if (gridFallback) {
//         setGridFallback(false);
//       }
//     } catch (err) {
//       clearData();
//       setGridLoading(false);
//       setIsPageLoading(false);
//       if (!gridFallback) {
//         setGridFallback(true);
//       }
//       console.log(err);
//     }
//   };
//   const [breadCrumbs] = useState(false);
//   useEffect(() => {
//     setIsPageLoading(true);
//     setGridLoading(true);
//     getData();
//   }, []);

//   useEffect(() => {
//     setGridLoading(true);
//     if (dateRange && acc) {
//       getTableData(dateRange);
//     }
//     if (dateRange) {
//       getTotals(dateRange);
//     }
//   }, [acc]);

//   // Example using Grid's API
//   // const buttonListener = useCallback((e) => {
//   //   gridRef.current.api.deselectAll();
//   // }, []);

//   return (
//     <>
//       <div className="whoverview-container">
//         {!isPageLoading ? (
//           <>
//             <div className="header-container">
//               <div className="header-block">
//                 {breadCrumbs ? (
//                   <div className="breadcrumbs-container"></div>
//                 ) : null}
//                 <div className="header">
//                   <span>Warehouses</span>
//                 </div>
//                 <div className="subheader-container">
//                   {total && total?.sum_creds && (
//                     <div className="sub-header">
//                       <span>
//                         Total Number of Credits Consumed {"- "}
//                         {
//                           //TODO Change the hardcode
//                           <span className="subheader-bold">
//                             {roundToSigDigits(total?.sum_creds, 2, false)}
//                           </span>
//                         }
//                       </span>
//                     </div>
//                   )}
//                   {total && total?.sum_cost && (
//                     <div className="sub-header">
//                       <span>
//                         Total Cost of Credits Consumed {"- "}
//                         <span className="subheader-bold">
//                           ${roundToSigDigits(total?.sum_cost, 2, false)}{" "}
//                         </span>{" "}
//                       </span>
//                     </div>
//                   )}
//                 </div>
//               </div>
//               <div className="top-filter">
//                 <div className="select-container">
//                   <label>Date Range</label>
//                   <Select
//                     value={dateRange}
//                     options={dateRangeOptions}
//                     styles={customStyles}
//                     classNamePrefix="selectcategory"
//                     placeholder="select"
//                     isSearchable={false}
//                     components={{ DropdownIndicator }}
//                     onChange={(e) => {
//                       setGridLoading(true);
//                       setDateRange(e);
//                       getTableData(e);
//                       getTotals(e);
//                     }}
//                   />
//                 </div>
//               </div>
//             </div>
//             <div className="grid-block">
//               <div className="grid-header-container">
//                 <div className="grid-header-block">
//                   <div className="grid-header">
//                     <span>Inventory</span>
//                   </div>
//                 </div>
//               </div>

//               <GridComponent
//                 columnDefs={columnDefs}
//                 rowData={rowData}
//                 gridFallback={gridFallback}
//                 gridStyle={{ height: 500 }}
//                 gridLoading={gridLoading}
//                 noRowsText={
//                   "Warehouse overview for the selected period or account are currently unavailable. Coming soon..."
//                 }
//               />
//             </div>
//           </>
//         ) : null}
//         {isPageLoading && (
//           <div className="load">
//             <div className="preload"></div>
//           </div>
//         )}
//       </div>
//     </>
//   );
// };
// WarehouseOverview.propTypes = {
//   url: PropTypes.string,
//   token: PropTypes.string,
//   acc: PropTypes.object
// };
// export default WarehouseOverview;

import React from "react";
import IframeHandler from "../../components/IframeHandler";
import "./warehouseoverview.scss";
import PropTypes from "prop-types";
const WarehouseOverview = ({ url, token, acc }) => {
  return (
    <>
      {token && url && token !== "" && url !== "" && acc ? (
        <IframeHandler token={token} url={url} acc={acc} />
      ) : (
        <div>
          <span>Search Optimisation</span>
        </div>
      )}
    </>
  );
};
WarehouseOverview.propTypes = {
  url: PropTypes.string,
  token: PropTypes.string,
  acc: PropTypes.object
};
export default WarehouseOverview;
