import React, { useState } from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import dropdown_arrow from "../../assets/images/common/dropdown_arrow.svg";
import warning from "../../assets/images/common/warning.svg";
import { FormHelperText } from "@mui/material";

const OnBoard = (props) => {
  const { doOnBoard, changeIsLoading } = props;
  const [infoError, setInfoError] = useState({
    fname: "",
    lname: "",
    role: ""
  });
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [role, setRole] = useState({ value: "", label: "" });
  const roles = [
    { value: "Data Engineer", label: "Data Engineer" },
    { value: "Machine Learning Engineer", label: "Machine Learning Engineer" },
    { value: "Business Executive", label: "Business Executive" },
    { value: "Consultant", label: "Consultant" },
    { value: "BI/Analyst", label: "BI/Analyst" },
    { value: "Data Scientist", label: "Data Scientist" },
    { value: "Product Manager", label: "Product Manager" },
    { value: "Marketing", label: "Marketing" },
    { value: "Security", label: "Security" },
    { value: "Startup Founder", label: "Startup Founder" },
    { value: "Student", label: "Student" },
    { value: "Other", label: "Other" }
  ];
  const roleOptions = roles.map((obj, index) => {
    return (
      <MenuItem key={index} value={obj.value}>
        {obj.label}
      </MenuItem>
    );
  });
  const handleSubmit = (e) => {
    e.preventDefault();
    let errObj = { ...infoError };
    if (fname.trim() === "") {
      errObj["fname"] = "Please add first name";
    }
    if (lname.trim() === "") {
      errObj["lname"] = "Please add last name";
    }
    if (role?.value?.trim() === "") {
      errObj["role"] = "Please add a role";
    }
    setInfoError(errObj);
    if (
      errObj["fname"] === "" &&
      errObj["lname"] === "" &&
      errObj["role"] === ""
    ) {
      changeIsLoading(true);
      doOnBoard({ fname, lname, role: role?.value });
    }
  };
  return (
    <div className="signboard-board-parent">
      <div className="heading">
        <span>
          Welcome to Chaos Genius!
          <span className="signboard-hi"> 👋</span>
        </span>
      </div>
      <div className="signboard-question">
        <span>Tell us about yourself</span>
      </div>
      <form className="signboard-list" onSubmit={handleSubmit}>
        <div className="signboard-textboxes">
          <div className="signboard-namebox">
            <TextField
              label="First Name"
              variant="outlined"
              className="signboard-textbox"
              fullWidth
              autoComplete="given-name"
              error={infoError.fname !== ""}
              name="firstName"
              FormHelperTextProps={{
                className: "email-error",
                sx: {
                  mt: infoError.fname !== "" ? "6px" : "0px",
                  ml: 0
                }
              }}
              value={fname}
              onChange={(e) => {
                if (infoError["fname"] !== "") {
                  setInfoError({ ...infoError, fname: "" });
                }
                setFname(e.target.value);
              }}
              helperText={
                <>
                  {infoError.fname !== "" && <img src={warning} />}
                  <span> {infoError.fname}</span>
                </>
              }
            />
            <TextField
              label="Last Name"
              variant="outlined"
              className="signboard-textbox"
              fullWidth
              value={lname}
              autoComplete="family-name"
              name="lastName"
              FormHelperTextProps={{
                className: "email-error",
                sx: {
                  mt: infoError.lname !== "" ? "6px" : "0px",
                  ml: 0
                }
              }}
              error={infoError.lname !== ""}
              onChange={(e) => {
                if (infoError["lname"] !== "") {
                  setInfoError({ ...infoError, lname: "" });
                }
                setLname(e.target.value);
              }}
              helperText={
                <>
                  {infoError.lname !== "" && <img src={warning} />}
                  <span> {infoError.lname}</span>
                </>
              }
            />
          </div>
          <FormControl fullWidth className="signboard-role-dropdown">
            <InputLabel>Current Role</InputLabel>
            <Select
              label="Current Role"
              value={role?.value}
              onChange={(e) => {
                if (infoError["role"] !== "") {
                  setInfoError({ ...infoError, role: "" });
                }
                setRole(e.target);
              }}
              name="Role"
              error={infoError.role !== ""}
              IconComponent={() => (
                <span className="signboard-dropdown-arrow">
                  <img src={dropdown_arrow} />
                </span>
              )}>
              {roleOptions}
            </Select>
            <FormHelperText
              className="email-error"
              error={infoError.role !== ""}
              sx={{ mt: infoError.role !== "" ? "6px" : "0px", ml: 0 }}>
              {infoError.role !== "" && (
                <img src={warning} className="signboard-helper-warning-img" />
              )}
              <span> {infoError.role}</span>
            </FormHelperText>
          </FormControl>
        </div>
        <input
          type="submit"
          value="Next"
          className="btn btn-green-variant__login w-100 login-btn"
        />
      </form>
    </div>
  );
};
OnBoard.propTypes = {
  doOnBoard: PropTypes.func.isRequired,
  changeIsLoading: PropTypes.func
};

export default OnBoard;
