import React, { useEffect, useState } from "react";
import "./querystats.scss";
import PropTypes from "prop-types";
import ChartComponent from "../../components/ChartComponent";
import { formatDateTime } from "../../utils/date-helper";
import {
  pretty_print_number_with_suffix,
  roundToSigDigits
} from "../../utils/common-helper";
import Highcharts from "highcharts";

const QueryStats = ({ queryDetailsInfoData, histogramDataInfo, visible }) => {
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [queryStatsInfo, setQueryStatsInfo] = useState(null);
  const [lineChartOptions, setLineChartOptions] = useState(null);
  const [histogramOptions, setHistogramOptions] = useState(null);
  const [histogramData, setHistogramData] = useState(null);
  const createLineChartOptions = (data) => {
    if (data) {
      const options = {
        chart: {
          height: 500,
          margin: [60, 50, 90, 50]
        },
        title: {
          text: data?.title
        },
        xAxis: {
          type: "datetime",
          tickWidth: 0,
          title: {
            text: data.x_label,
            offset: 40,
            style: {
              color: "#7a7a7a",
              fontSize: "12px"
            }
          },
          labels: {
            formatter: function () {
              return Highcharts.dateFormat("%d %b", this.value);
            },
            style: {
              color: "#7a7a7a",
              fontSize: "12px"
            }
          }
        },
        tooltip: {
          formatter: function () {
            const tip = [...this.points].reverse()?.map((point) => {
              return (
                "<b>" +
                point?.series?.name +
                ": </b>" +
                "" +
                roundToSigDigits(point.y, 2, false) +
                "" +
                `<br><b>${data?.x_label}: </b>` +
                formatDateTime(this.x, true, true).replace("00:00", "") +
                ""
              );
            });
            return tip;
          },
          split: true
        },
        yAxis: [
          {
            title: {
              text: data?.y_label[0],
              offset: 60,
              style: {
                color: "#7a7a7a",
                fontSize: "12px"
              }
            },
            lineWidth: 0,
            //gridLineDashStyle: "longdash",
            tickAmount: 7,
            labels: {
              formatter: function () {
                return pretty_print_number_with_suffix(Number(this.value));
              },
              style: {
                fontSize: "12px"
              }
            }
          },
          {
            title: {
              text: data?.y_label[1],
              offset: 60,
              style: {
                color: "#7a7a7a",
                fontSize: "12px"
              }
            },
            opposite: true,
            offset: 0,
            lineWidth: 0,
            //gridLineDashStyle: "longdash",
            tickAmount: 7,
            labels: {
              formatter: function () {
                return pretty_print_number_with_suffix(Number(this.value));
              },
              style: {
                fontSize: "12px"
              }
            }
          }
        ],
        series: [
          {
            type: "spline",
            name: data?.y_label[0],
            color: "#60CA9A",
            marker: {
              symbol: "circle",
              fillColor: "#60CA9A",
              lineColor: null,
              enabled: false
            }, // inherit from series },
            data: data?.y_data[0],
            hover: {
              enabled: false
            },
            yAxis: 0,
            states: {
              inactive: {
                opacity: 1
              }
            }
          },
          {
            type: "spline",
            name: data?.y_label[1],
            color: "#0597f2",
            marker: {
              enabled: false,
              symbol: "circle",
              fillColor: "#0597f2",
              lineColor: null // inherit from series
            },
            hover: {
              enabled: false
            },
            data: data?.y_data[1],
            yAxis: 1,
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        ]
      };
      setLineChartOptions(options);
    } else {
      setLineChartOptions(null);
    }
  };

  const createHistogramChartOptions = (data) => {
    if (data) {
      const options = {
        chart: {
          margin: [60, 50, 90, 50]
        },
        title: {
          text: data?.title
        },
        xAxis: {
          tickWidth: 0,
          title: {
            text: data.x_label,
            offset: 40,
            style: {
              color: "#7a7a7a",
              fontSize: "12px"
            }
          },
          min: data.x_limits[0],
          max: data.x_limits[1],
          crosshair: {
            dashStyle: "longdot"
          },
          labels: {
            style: {
              color: "#7a7a7a",
              fontSize: "12px"
            }
          }
        },
        tooltip: {
          formatter: function () {
            const tip = [...this.points].reverse()?.map((point) => {
              return (
                "<b>" +
                point?.series?.name +
                ": </b>" +
                "" +
                roundToSigDigits(point.y, 2, false) +
                "" +
                `<br><b>${data?.x_label}: </b>` +
                roundToSigDigits(this.x, 2) +
                ""
              );
            });
            return tip;
          },
          split: true
        },
        legend: {
          symbolRadius: 0
        },
        yAxis: [
          {
            title: {
              text: data?.y_label[0],
              offset: 60,
              style: {
                color: "#7a7a7a",
                fontSize: "12px"
              }
            },
            height: "45%",
            lineWidth: 0,
            min: 0,
            tickAmount: 5,
            labels: {
              formatter: function () {
                return pretty_print_number_with_suffix(Number(this.value));
              },
              style: {
                color: "#7a7a7a",
                fontSize: "12px"
              }
            }
            //gridLineDashStyle: "longdash"
          },
          {
            title: {
              text: data?.y_label[1],
              offset: 60,
              style: {
                color: "#7a7a7a",
                fontSize: "12px"
              }
            },
            top: "55%",
            height: "45%",
            tickAmount: 5,
            offset: 0,
            lineWidth: 0,
            min: 0,
            //gridLineDashStyle: "longdash",
            labels: {
              formatter: function () {
                return pretty_print_number_with_suffix(Number(this.value));
              },
              style: {
                color: "#7a7a7a",
                fontSize: "12px"
              }
            }
          }
        ],
        series: [
          {
            type: "column",
            name: data?.y_label[0],
            color: "#60CA9A",
            marker: { symbol: "circle", fillColor: "#60CA9A", lineColor: null }, // inherit from series },
            data: data?.y_data[0],
            hover: {
              enabled: false
            },
            states: {
              inactive: {
                opacity: 1
              }
            }
          },
          {
            type: "column",
            name: data?.y_label[1],
            color: "#0597f2",
            marker: {
              symbol: "circle",
              fillColor: "#0597f2",
              lineColor: null // inherit from series
            },
            hover: {
              enabled: false
            },
            data: data?.y_data[1],
            yAxis: 1,
            states: {
              inactive: {
                opacity: 1
              }
            }
          }
        ]
      };
      setHistogramOptions(options);
    } else {
      setHistogramOptions(null);
    }
  };
  const getData = async () => {
    try {
      if (queryDetailsInfoData && histogramDataInfo) {
        setQueryStatsInfo(queryDetailsInfoData);
        setHistogramData(histogramDataInfo);
        createLineChartOptions(queryDetailsInfoData);
        createHistogramChartOptions(histogramDataInfo);
        setIsPageLoading(false);
      } else {
        setQueryStatsInfo(null);
        setHistogramData(null);
        setIsPageLoading(false);
      }
    } catch (err) {
      //clearData();
      setIsPageLoading(false);
      setQueryStatsInfo(null);
      setHistogramData(null);
      console.log(err);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div
        className={`qry-stats-container ${visible ? "" : "hidden"} ${
          isPageLoading ? "pad-0" : ""
        }`}>
        {!isPageLoading && queryStatsInfo && histogramData ? (
          <>
            <div className="header-block">
              <span>Query Stats</span>
            </div>
            <div className="charts-block">
              <ChartComponent options={lineChartOptions} type={`Line`} />
              <ChartComponent options={histogramOptions} type={`Histogram`} />
            </div>
          </>
        ) : null}
        {isPageLoading && (
          <div className="load">
            <div className="preload"></div>
          </div>
        )}
      </div>
    </>
  );
};
QueryStats.propTypes = {
  queryDetailsInfoData: PropTypes.object,
  histogramDataInfo: PropTypes.object,
  visible: PropTypes.bool
};
export default QueryStats;
