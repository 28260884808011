/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

import { env } from "./env";

const awsCognitoProd = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id: "",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_qloEmwm69",
  aws_user_pools_web_client_id: "5t26a873rr51othh2l2h7ctknr",
  oauth: {
    domain: "temp-cloud-monitoring-user-pool.auth.us-west-2.amazoncognito.com",
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: "https://app.chaosgenius.io/",
    redirectSignOut: "https://app.chaosgenius.io/signin/",
    responseType: "token"
  },
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ["EMAIL"]
};
const awsCognitoStage = {
  aws_project_region: "us-west-2",
  aws_cognito_identity_pool_id: "",
  aws_cognito_region: "us-west-2",
  aws_user_pools_id: "us-west-2_VuLVZZE8V",
  aws_user_pools_web_client_id: "4f5576it5qlucs9cgubc0f4cul",
  oauth: {
    domain:
      "staging-cloud-monitoring-user-pool.auth.us-west-2.amazoncognito.com",
    scope: ["email", "profile", "openid", "aws.cognito.signin.user.admin"],
    redirectSignIn: "https://stagingapp.chaosgenius.io/",
    redirectSignOut: "https://stagingapp.chaosgenius.io/signin/",
    responseType: "token"
  },
  aws_cognito_username_attributes: ["EMAIL"],
  aws_cognito_social_providers: [],
  aws_cognito_signup_attributes: ["EMAIL"],
  aws_cognito_mfa_configuration: "OFF",
  aws_cognito_password_protection_settings: {
    passwordPolicyMinLength: 8,
    passwordPolicyCharacters: []
  },
  aws_cognito_verification_mechanisms: ["EMAIL"]
};
const awsCognitoConfig =
  env?.REACT_APP_ENV?.toString() === "prod" ? awsCognitoProd : awsCognitoStage;
export const accessControlVar =
  env?.REACT_APP_ENV?.toString() === "prod" ||
  env?.REACT_APP_ENV?.toString() === "testenv"
    ? window?.location?.origin
    : "*";
export default awsCognitoConfig;
