import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Drawerwrapper from "../customElements/Drawerwrapper";

import closeIcon from "../../assets/images/grid/closeIcon.svg";
import Button from "../customElements/Button";
import CreateResource from "./CreateResource";
import {
  checkResources,
  getAccountDropdowns,
  postNewCostCentre,
  removeDropdown
} from "../../utils/chargeback-helper";
const initialState = {
  account: [],
  wearehouse: [],
  user: [],
  accountDropdown: false,
  wearehouseDropdown: false,
  userDropdown: false
};
function CreateModal({ open, close, acc }) {
  const [resources, setResources] = useState([]);
  const [costCenterName, setCostCenterName] = useState("");
  const [ownerName, setOwnerName] = useState("");
  const [error, setError] = useState({ owner: null, CCname: null });

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [accountDropdowns, setAccountDropdowns] = useState(null);

  const handleChange = (value, key, index) => {
    if (key === "account") {
      let updatedValue = resources.map((item, ind) =>
        ind === index
          ? { ...item, [key]: value, wearehouse: [], user: [] }
          : item
      );
      setResources(updatedValue);
    } else {
      let updatedValue = resources.map((item, ind) =>
        ind === index ? { ...item, [key]: value } : item
      );
      setResources(updatedValue);
    }
  };

  const fetchAccountDropdowns = async () => {
    try {
      const response = await getAccountDropdowns(acc.id);
      if (response) {
        setSelectedAccount(response);
        setAccountDropdowns(response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleSwitch = (ind, key) => {
    const updatedValue = resources.map((item, index) =>
      index === ind ? { ...item, [key]: !item[key] } : item
    );
    setResources(updatedValue);
  };

  const deleteResource = (ind) => {
    if (resources.length > 1) {
      const updatedValue = resources.filter((item, index) => index !== ind);
      setResources(updatedValue);
    }
  };

  const handleResources = () => {
    const account_dropdowns = removeDropdown(accountDropdowns, resources);
    setSelectedAccount(account_dropdowns);
    setResources([
      ...resources,
      { ...initialState, account: account_dropdowns?.[0] }
    ]);
  };

  const resetData = () => {
    setResources([{ ...initialState, account: acc }]);
    setCostCenterName("");
    setOwnerName("");
    setError({ owner: null, CCname: null });
  };

  const createCostCenterResource = async () => {
    let CCerror = "";
    let OWerror = "";

    if (costCenterName.trim() === "") {
      CCerror = "This field cannot be empty";
    }
    if (ownerName.trim() === "") {
      OWerror = "This field cannot be empty";
    }
    if (ownerName && costCenterName) {
      const accountType = await checkResources(resources);
      const payload = {
        name: costCenterName,
        owner: ownerName,
        resources: accountType
      };
      try {
        const response = await postNewCostCentre(payload);
        if (!response) {
          resetData();
          close();
        }
      } catch (error) {
        console.log(error);
        resetData();
        close();
      }
    } else {
      setError({ owner: OWerror, CCname: CCerror });
    }
  };

  useEffect(() => {
    fetchAccountDropdowns();
    setResources([{ ...initialState, account: acc }]);
  }, []);
  return (
    <Drawerwrapper
      open={open}
      handleClose={close}
      anchor={"right"}
      className={"cost-center-modal-container"}>
      <div className="drawer-title ">
        <p>Create Cost Center</p>
        <img onClick={close} src={closeIcon} alt="" />
      </div>
      <div className="step-one-container">
        <div className="title-container">
          <p className="title">Step 1: Update Details</p>
          <p className="description">Fill cost center name and owner name</p>
        </div>
        <div className="inputs-container">
          <div>
            <label>Cost Center Name</label>
            <input
              placeholder=" Enter cost center name"
              value={costCenterName}
              onChange={(e) => {
                setCostCenterName(e.target.value);
                setError({ ...error, CCname: null });
              }}
              type="text"
            />
            {error.CCname && <span className="error-msg">{error.CCname}</span>}
          </div>
          <div>
            <label>Owner Name</label>
            <input
              placeholder=" Enter owner name"
              value={ownerName}
              onChange={(e) => {
                setOwnerName(e.target.value);
                setError({ ...error, owner: null });
              }}
              type="text"
            />
            {error?.owner && <span className="error-msg">{error.owner}</span>}
          </div>
        </div>
      </div>

      <div className="divider-container">
        <div className="divider"></div>
      </div>

      <div className="step-one-container">
        <div className="title-container">
          <p className="title">Step 2: Select Resources</p>
          <p className="description">
            An resources once used in a cost center definition cannot be reused.
            This is to prevent double-counting of the cost resources
          </p>
        </div>
      </div>
      <div className="cost-resource-main-container">
        {resources?.length &&
          resources?.map((item, ind) => (
            <CreateResource
              key={ind}
              acc={acc}
              dwAccOptions={selectedAccount}
              column={item}
              index={ind}
              setResources={setResources}
              handleSwitch={handleSwitch}
              deleteResource={deleteResource}
              handleChange={handleChange}
            />
          ))}
      </div>
      <div className="add-condition-container">
        <p onClick={handleResources}>+ Add Condition</p>
      </div>

      <div
        className={
          resources.length > 1
            ? "drawer-footer position-sticky"
            : "drawer-footer"
        }>
        <div>
          <Button
            onClick={createCostCenterResource}
            className={"create-button"}>
            {"Create"}
          </Button>
        </div>
      </div>
    </Drawerwrapper>
  );
}
CreateModal.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  acc: PropTypes.object,
  dwAccOptions: PropTypes.array
};
export default CreateModal;
